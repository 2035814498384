import {create} from 'zustand'

export const useGlobalStore = create((set, get) => ({
  lang: 'en',
  selectedMatchday: {},
  selectedLiveData: {},
  apiSetup: {},
  timelineAutomationSetup: {},
  globalMenuCollapse: false,
  checkToken: false,
  setApiSetup: (apiSetup) => set({apiSetup}),
  setTimelineAutomationSetup: (timelineAutomationSetup) => set({timelineAutomationSetup}),
  setLang: (lang) => set({lang}),
  setSelectedMatchday: (selectedMatchday) => set({selectedMatchday}),
  setSelectedLiveData: (selectedLiveData) => set({selectedLiveData}),
  setGlobalMenuCollapse: (globalMenuCollapse) => set({globalMenuCollapse}),
  setCheckToken: (checkToken) => set({checkToken})
}));


import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import getContentById from "../../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";

import {engine, fadeIn, slide} from "../../../../../animations/index"
import animationScript, {animationCleanUp} from "../../../../../animations/adler/startingsix"
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";
import { usePlayoffsVideo } from "../../../../shared/hooks/usePlayoffsVideo";


import assets from './assets';
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import frontendCfg from "../../../../../config/frontend";
import outroVideo16x9 from "../../../assets/outros/2023/outro_playoffs_16x9.webm";
import outroVideo4x5 from "../../../assets/outros/2023/outro_playoffs_4x5.webm";
import outroVideo9x16 from "../../../assets/outros/2023/outro_playoffs_9x16.webm";

require('./style/style.scss');
require('./style/animation.scss');

const Index = (props) => {
  const refs = {
    player: React.createRef(), team: React.createRef()
  };

  const {animation, data, images: Images, apiSetup, mode, format, isTimeline} = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  // handle outro video BEGIN
  // usePlayoffsVideo({animation, mode, isTimeline, id})
  // handle outro video END

  useEffect( () => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({refs, data, animation, mode, id, format})
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`).get(0)?.addEventListener("loadedmetadata", function () {
        this.currentTime = 5;
        this.pause();
      }, false);

      $(`#${id} .background-ribbons video.template-background`).get(0)?.addEventListener("loadedmetadata", function () {
        this.currentTime = 5;
        this.pause();
      }, false);
    }

    if(isTimeline) {
      const $videoBg = $(`#${id} video.template-background`).get(0);
      // console.log("isTImeline", isTimeline);
      // $(document).on("TIMELINE_START_VIDEOBG", function () {
      //   console.log("START VIDEO BG");
      //   $videoBg.play();
      // });

      $videoBg.addEventListener("loadedmetadata", function () {
        this.pause();
      }, false);
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);

  const templateData = _.get(data, 'data');

  const {options, fields, dynamic} = templateData;
  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);

  const statsSide = getContentById('statsSide', fields);

  let teamHome = _.get(dynamic, "assets.teamHome");
  let teamAway = _.get(dynamic, "assets.teamAway");
  const goalsHome = _.get(dynamic, "goals.home");
  const goalsAway = _.get(dynamic, "goals.visitor");
  const eventData = _.get(dynamic, "game");

  const isAdlerTeamSide = _.get(eventData, `${statsSide}.id`) == 2 ? true : false;

  const teamSide = statsSide;

  const startingSixDataHome = _.get(dynamic, "startingSixHome");
  const startingSixDataAway = _.get(dynamic, "startingSixAway");

  let startingSixData = null;

  if (teamSide === "home") {
    startingSixData = startingSixDataHome;
  } else {
    startingSixData = startingSixDataAway;
  }

  // if (format === '1920x1080') {
  //   if (statsSide === 'away') {
  //     teamHome = teamAway
  //   }
  // }
  //
  // if (isAdlerTeamSide && teamSide === 'away') {
  //   teamHome = _.get(dynamic, "assets.teamAway");
  //   teamAway = _.get(dynamic, "assets.teamHome");
  //   // console.log('teamHome', teamHome);
  //
  // }


  if (data?.event?.eventId == 2372) {
    startingSixData.map((pl, plIndex) => {
      if (pl.playerId == 143) {
        pl.position = 'DE';
      }
      return pl;
    })
  }

  const apiAssets = _.get(dynamic, "assets");
  const referees = _.get(dynamic, "game.referees");
  // const teamDataStyles = _.get(teamData, "styles", {});
  const playerData = getContentById('player', fields, "self");

  const backgroundImage = getMainBackground(format, teamSide);
  const customBackground = getContentById('background', fields);
  // const backgroundMask = getContentById('defaultBackgroundMask', options);

  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;

  const translatePostition = (pos) => {
    if (pos === 'rw' || pos === 'lw' || pos === 'ce') {
      return 'fo';
    }

    if (pos === 'ld' || pos === 'rd') {
      return 'de';
    }
    return pos;
  };

  const render1920Players = (onlyLast = false) => {

    return <>
      <div className={"players-container-splitted line-fo"}>
        <div className={"player-group-line"}><div>S</div></div>

        <div className={"players"}>
          {_.map(startingSixData.filter((p) => translatePostition(p?.position.toLowerCase()) === 'fo'), (player, playerIndex) => {
          if (!player) {
            return null;
          }
          const predefinedPlayer = _.get(apiAssets, `p_${player.playerId}`);

          const feedFirstname = _.get(player, "name", "");
          const firstnameSplitted = feedFirstname.split(' ');
          const firstname = firstnameSplitted[0] ? firstnameSplitted[0] : feedFirstname;

          return <div key={`player-id-${player.playerId}`}
                      className={`player-container player-id-${player.playerId}`}>
            <div className={"player-label"}>
              <div className={"player-info"}>
                {!onlyLast && <div className={"firstname"}>{_.get(predefinedPlayer, "data.firstname", firstname)}</div>}
                <div className={"lastname"}>{_.get(predefinedPlayer, "data.lastname", _.get(player, "surname"))}</div>
                <div className={"player-bottom-gradient"}></div>
              </div>
            </div>

            <div className={"jersey"}>{player.jersey}</div>

            <div className={"player-image-container"}>
              <div className={"player-image"}>
                <img src={parseMediaUrl(_.get(predefinedPlayer, "url"))}/>
              </div>
            </div>

          </div>
        })}
        </div>
      </div>
      <div className={"players-container-splitted line-de"}>
        <div className={"player-group-line"}><div>V</div></div>

        <div className={"players"}>
        {_.map(startingSixData.filter((p) => translatePostition(p?.position.toLowerCase()) === 'de'), (player, playerIndex) => {
          if (!player) {
            return null;
          }
          const predefinedPlayer = _.get(apiAssets, `p_${player.playerId}`);

          const feedFirstname = _.get(player, "name", "");
          const firstnameSplitted = feedFirstname.split(' ');
          const firstname = firstnameSplitted[0] ? firstnameSplitted[0] : feedFirstname;

          return <div key={`player-id-${player.playerId}`}
                      className={`player-container player-id-${player.playerId}`}>
            <div className={"player-label"}>

              <div className={"player-info"}>

                {!onlyLast && <div className={"firstname"}>{_.get(predefinedPlayer, "data.firstname", firstname)}</div>}
                <div className={"lastname"}>{_.get(predefinedPlayer, "data.lastname", _.get(player, "surname"))}</div>
                <div className={"player-bottom-gradient"}></div>
              </div>
            </div>

            <div className={"jersey"}>{player.jersey}</div>

            <div className={"player-image-container"}>
              <div className={"player-image"}>
                <img src={parseMediaUrl(_.get(predefinedPlayer, "url"))}/>
              </div>
            </div>

          </div>
        })}
        </div>
      </div>
      <div className={"players-container-splitted line-gk"}>
        <div className={"player-group-line"}><div>T</div></div>

        <div className={"players"}>
        {_.map(startingSixData.filter((p) => p?.position.toLowerCase() === 'gk'), (player, playerIndex) => {
          if (!player) {
            return null;
          }
          const predefinedPlayer = _.get(apiAssets, `p_${player.playerId}`);

          const feedFirstname = _.get(player, "name", "");
          const firstnameSplitted = feedFirstname.split(' ');
          const firstname = firstnameSplitted[0] ? firstnameSplitted[0] : feedFirstname;

          return <div key={`player-id-${player.playerId}`}
                      className={`player-container player-id-${player.playerId}`}>
            <div className={"player-label"}>

              <div className={"player-info"}>

                {!onlyLast && <div className={"firstname"}>{_.get(predefinedPlayer, "data.firstname", firstname)}</div>}
                <div className={"lastname"}>{_.get(predefinedPlayer, "data.lastname", _.get(player, "surname"))}</div>
                <div className={"player-bottom-gradient"}></div>
              </div>
            </div>

            <div className={"jersey"}>{player.jersey}</div>

            <div className={"player-image-container"}>
              <div className={"player-image"}>
                <img src={parseMediaUrl(_.get(predefinedPlayer, "url"))}/>
              </div>
            </div>

          </div>
        })}
        </div>
      </div>
    </>
  };

  return (<div id={id}
               className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''} side-${teamSide} ${isAdlerTeamSide ? "adler-stats" : "other-stats"}`}>

    <TemplateBlock>
      <Backgrounds bg1={backgroundImage}/>


      {/*handle outro video BEGIN*/}
      {format === "1920x1080" && (
        <video className={"outro-optional-video"} controls={false} autoPlay={false} muted={true} loop={false}>
          <source src={outroVideo16x9}/>
        </video>
      )}

      {format === "1200x1500" && (
        <video className={"outro-optional-video"} controls={false} autoPlay={false} muted={true} loop={false}>
          <source src={outroVideo4x5}/>
        </video>
      )}

      {format === "1080x1920" && (
        <video className={"outro-optional-video"} controls={false} autoPlay={false} muted={true} loop={false}>
          <source src={outroVideo9x16}/>
        </video>
      )}
      {/*handle outro video END*/}

      {/*<img className={"sponsors sponsor-sap"} src={assets.mannheimer_presents}/>*/}
      <img className={"sponsors sponsor-del"} src={assets.penny_del}/>

      <div className={"teams-block"}>
        <div className={"team-logo-container home"}>
          <img src={parseMediaUrl(_.get(teamHome, 'url', null))}/>
        </div>

        <div className={"team-logo-container away"}>
          <img src={parseMediaUrl(_.get(teamAway, 'url', null))}/>
        </div>

        {(format === "1080x1920" || format === "1200x1500") && (<div>
          <div className={"team-vs"}>vs</div>
        </div>)}

      </div>

      {(format === "1920x1080" || format === "1200x1500") ? <div className={"headline"}>
        <div className={"main-line"}>STARTING SIX</div>
        <div className={"ghost-line"}>STARTING SIX</div>
      </div> : <div className={"headline"}>
        <div className={"main-line"}>STARTING<br/>SIX</div>
        <div className={"ghost-line"}>STARTING<br/>SIX</div>
      </div>}

      <div>
        <div className={"starting-six-container"}>
          {format === "1200x1500" && render1920Players(true)}
          {format === "1920x1080" && render1920Players()}
          {format === "1080x1920" && render1920Players(true)}
        </div>
      </div>


    </TemplateBlock>

  </div>);
};

const getMainBackground = (format, side) => {
  if (format === "1920x1080") {
    return _.get(assets, `mainbackground_1920_${side}`);
  } else if (format === "1200x1500") {
    return _.get(assets, `mainbackground_1200_${side}`);
  } else if (format === "1080x1920") {
    return _.get(assets, `mainbackground_1080_${side}`);
  }

  return null;
};


const getPosition = (pos) => {
  if (pos.toLowerCase() === "fo") {
    return "Sturm";
  } else if (pos.toLowerCase() === "de") {
    return "Verteidigung";
  } else if (pos.toLowerCase() === "gk") {
    return "Tor";
  }
};

export default Index;

import {engine as anime} from "./index";


export const fadeIn = (props) => {
  const {slide, duration} = props;
  let slideType = ".active-slide";

  if (slide === "next") {
    slideType = ".next-slide";
  }

  const timeline = anime.timeline();

  anime({
    targets: [`.template-container${slideType} .comp-template-block`],
    duration: duration,
    easing: "easeInOutSine",
    opacity: [0, 1]
  });

  return timeline;

};

export const fadeOut = (props) => {
  const {slide, duration} = props;
  let slideType = ".active-slide";

  if (slide === "next") {
    slideType = ".next-slide";
  }

  const timeline = anime.timeline();

  anime({
    targets: [`.template-container${slideType} .comp-template-block`],
    duration: duration,
    easing: "easeInOutSine",
    opacity: [1, 0]
  });

  return timeline;

};

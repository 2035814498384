import {axios} from "./axios";
import _ from "lodash";

// const API_URL = "http://localhost:3000/";

const getImages = (params) => {
  let group = _.get(params, "group", null);

  if (group === "_default") {
    group = undefined;
  }

  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      const imagesData = require("../testData/api/mediaLibraryImages.json");
      resolve(imagesData);
    })
  }

  return axios({
    url: '/upload',
    method: 'get',
    params: {
      filter: {
        "content_type": "image",
        "group": group
      }
    },
  })


};

const getAudios = (params) => {
  let group = _.get(params, "group", null);

  if (group === "_default") {
    group = undefined;
  }

  // if(process.env.MOCK_API) {
  //   return new Promise((resolve)=> {
  //     const imagesData = require("../testData/api/mediaLibraryVideos.json");
  //     resolve(imagesData);
  //   })
  // }

  return axios({
    url: '/upload',
    method: 'get',
    params: {
      filter: {
        "content_type": "audio",
        "group": group
      }
    },
  })

};

const getVideos = (params) => {
  let group = _.get(params, "group", null);

  if (group === "_default") {
    group = undefined;
  }

  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      const imagesData = require("../testData/api/mediaLibraryVideos.json");
      resolve(imagesData);
    })
  }

  return axios({
    url: '/upload',
    method: 'get',
    params: {
      filter: {
        "content_type": "video",
        "group": group
      }
    },
  })
};

const getAll = () => {
  // return axios.get(API_URL + "templates/all");
};

export {
  getImages,
  getAudios,
  getVideos,
  getAll,
};

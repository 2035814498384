const main = {
  apiUrl: process.env.API_URL || "http://localhost:3000",
  frontendUrl: process.env.FRONTEND_URL || "http://localhost:3001/frontend",
  videoUrl: process.env.VIDEO_URL || "http://localhost:8100",
  client: "womansworldcup_live",
};

if(process.env.ENV_TYPE === 'localdocker') {
  main.apiUrl = "http://172.100.0.1:3000"
  main.frontendUrl = "http://172.100.0.1:3001/frontend"
  main.videoUrl = "http://172.100.0.1:8100"
}

const config = {
  ...main,
  testMode: true,
  appName: "Spo|Vizz",
  screenshotsUrl: `${main.apiUrl}/screenshots/[[TEMPLATE]]/[[UUID]]/[[TYPE]].jpg`,
  chatbox: {
    active: false
  },
  refreshVideoInterval: 10000,
  dashboardTemplatesRefreshInterval: 5000,
  // clientBodyClass: "app-adler"
  clientBodyClass: "app-opta"
};


export default config;

import React from "react";
import {useSortable} from "@dnd-kit/sortable";
import {useDraggable} from '@dnd-kit/core';
import {CSS} from "@dnd-kit/utilities";
import _ from "lodash";

import Image from "../../../../shared/components/Image";
import backendCfg from "../../../../../config/backend";

export function Item(props) {
  const {id, index, item, isDragging, isSorting} = props;

  return <div id={id} className={"template-block"}>
    {props?.item?.homeName && props?.item?.awayName ? <div className={"template-helper-text"}>
      {props?.item?.homeName} vs {props?.item?.awayName}
    </div> : null}
    {index > -1 ? <div className={"timeline-element-index"}>{index + 1}</div> :
      <div className={"timeline-element-index"}>-</div>}
    <div className={"template-image"}>

      {item && <Image src={
        `${backendCfg.screenshotsUrl
          .replace("[[TEMPLATE]]", item.template)
          .replace("[[TYPE]]", "preview")
          .replace("[[UUID]]", item.templateId)
        }`
      }/>}
    </div>
    <div
      className={"template-label"}> {item.name}
    </div>
  </div>
}

export function SortableItem(props) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Item item={props.item} id={props.id} index={props.index} isDragging={isDragging} isSorting={isSorting}/>
    </div>
  );
}

export function DraggableItem(props) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useDraggable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Item item={props.item} id={props.id} index={props.index} isDragging={isDragging} isSorting={isSorting}/>
    </div>
  );
}

import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();

  $(`#${id} .qatar-logo, #${id} .main-headline, #${id} .competition-logo-container, #${id} .player`).css("opacity", 0);

  anime({
    targets: [`#${id} .competition-logo-container`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-1000, 0],
    opacity: [1, 1],
    delay: 200
  });

  // anime({
  //   targets: [`#${id} .group-label`],
  //   duration: 800,
  //   easing: "easeOutQuint",
  //   translateY: [-1000, 0],
  //   opacity: [1, 1],
  //   delay: 400
  // });

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [1000, 0],
    opacity: [1, 1],
    delay: 400
  });

  anime({
    targets: [`#${id} .group-content > div`],
    duration: 800,
    easing: "easeOutQuart",
    // scale: [0, 1],
    opacity: [1, 1],
    translateX: [-3000, 0],
    delay: anime.stagger(80)
  });

  return {
    timeline: [],
    timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import _ from "lodash";

import {DndContext, MouseSensor, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';

import {CSS} from '@dnd-kit/utilities';

import {Form, Select, Button, Radio, Input, Popconfirm, Table} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import {InputNumber, Typography} from 'antd';

import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {getStatsTypeLabel, getTemplateLabel} from "../../../../utils/setup";
import {useGlobalStore} from "../../../../../../stores/globalBackendStore";

const getUUID = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

const SortableRow = (props) => {
  const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    cursor: 'move',
    ...(isDragging
      ? {
        position: 'relative',
        zIndex: 9999,
      }
      : {}),
  };
  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const EditableCell = ({
                        editing,
                        dataIndex,
                        title,
                        inputType,
                        record,
                        index,
                        children,
                        options,
                        parentId,
                        ...restProps
                      }) => {

  const form = Form.useFormInstance();

  const optionsByDataIndex = {
    'name': options,
    'statsSide': [{
      value: 'home',
      label: 'home'
    }, {
      value: 'away',
      label: 'away'
    }],
    'type': [
      {
        value: 'distanceTravelled',
        label: 'Distance Travelled'
      },
      {
        value: 'fastestScater',
        label: 'Fastest Scater'
      },
      {
        value: 'shotSpeed',
        label: 'Shot Speed'
      }
    ]
  }

  const parentIdParsed = _.isArray(parentId) ? [...parentId] : [parentId]

  const dataIndexToNameTranslate = {
    'name': [...parentIdParsed, index, 'name'],
    'statsSide': [...parentIdParsed, index, 'fields', 'statsSide'],
    'keyword': [...parentIdParsed, index, 'keyword'],
    'type': [...parentIdParsed, index, 'type'],
  }

  const formItemName = dataIndexToNameTranslate?.[dataIndex];

  const currentRecord = form.getFieldValue([...parentIdParsed, index])

  // console.log('###currentRecord', currentRecord);
  // console.log('###dataIndex', dataIndex);

  const statsSideAvailableTemplates = ['adlerlineup', 'adlerteamstats']
  const typeAvailableTemplates = ['adlermatchrankwise']
  const keywordStatsAvailableTemplates = ['staticmedia']

  const statsSideAvailable = statsSideAvailableTemplates.includes(currentRecord?.name);
  const typeStatsAvailable = typeAvailableTemplates.includes(currentRecord?.name);
  const keywordStatsAvailable = keywordStatsAvailableTemplates.includes(currentRecord?.name);

  const isCellEditingDisabled = (dataIndex === 'statsSide' && !statsSideAvailable) || (dataIndex === 'type' && !typeStatsAvailable) || (dataIndex === 'keyword' && !keywordStatsAvailable)

  const InputNode = inputType === 'select' ? <Select
    style={{
      width: '100%',
    }}
    placeholder="Please select"
    options={optionsByDataIndex?.[dataIndex] || []}
    disabled={isCellEditingDisabled}
    onChange={(v, c)=> {
      if(dataIndex === 'name') {
        const newVal = {
          name: v
        };
        form.setFieldValue([...parentIdParsed, index], newVal)
      }
    }}
  /> : <Input
    disabled={isCellEditingDisabled}
  />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={formItemName}
          style={{
            margin: 0,
          }}
          rules={[]}
          // initialValue={record?.[dataIndex] || record?.fields?.[dataIndex]}
        >
          {InputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const FormTable = (props) => {

};

const {Option} = Select;

export const InputTable = (props) => {
  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onBlur() {
    console.log('blur');
  }

  function onFocus() {
    // console.log('focus');
  }

  function onSearch(val) {
    // console.log('search:', val);
  }

  const form = Form.useFormInstance();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.rowKey === editingKey;

  const apiSetup = useGlobalStore(state => state.apiSetup);

  // useEffect(() => {
  //   if (data.length === 0) {
  //     const newData = form.getFieldValue(props.id).map((i, index) => {
  //       return {
  //         ...i,
  //         rowKey: props.tablePrefix + index
  //       }
  //     })
  //     setData(newData)
  //   }
  // }, [data])

  useEffect(() => {
    console.log("props.id:", props.id);
    console.log("Field value:", form.getFieldValue(props.id));

    const fieldValues = form.getFieldValue(props.id) || [];
    const newData = fieldValues.map((i, index) => ({
      ...i,
      rowKey: props.tablePrefix + index,
    }));
    setData(newData);
  }, [props.id, form]);

  const edit = (record) => {
    let formData = form.getFieldValue(props.id);
    const index = formData.findIndex((item) => record.rowKey === item.rowKey);

    form.setFieldValue([...props.id, index], {
      ...data[index],
      rowKey: props.tablePrefix + index
    });
    setEditingKey(record.rowKey);
  };
  const cancel = (record) => {
    let formData = form.getFieldValue(props.id);
    const index = formData.findIndex((item) => record.rowKey === item.rowKey);
    form.setFieldValue([...props.id, index], {
      ...data[index],
      rowKey: props.tablePrefix + index
    });
    setEditingKey('');
  };

  const handleAdd = () => {
    const newData = {
      rowKey: props.tablePrefix + (data?.length + 1),
      name: `adlergameday`
    };

    const combined = [...data, newData];

    setData(combined);
    form.setFieldValue(props.id, combined);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      let newData = form.getFieldValue(props.id);
      let combinedData = [...data];

      const index = data.findIndex((item) => key === item.rowKey);

      if (index > -1) {
        const item = newData[index];
        combinedData[index] = {
          ...item,
          rowKey: props.tablePrefix + index
        };

        setData(combinedData);
        form.setFieldValue(props.id, combinedData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(combinedData);
        form.setFieldValue(props.id, combinedData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleDelete = (record) => {
    const newData = data.filter((item) => item.rowKey !== record?.rowKey);
    setData(newData);
    form.setFieldValue(props.id, newData);
  };

  const columns = [
    {
      title: 'name',
      dataIndex: 'name',
      // width: '25%',
      editable: true,
      render: (_, record) => {
        return <>{getTemplateLabel(apiSetup?.templates, record.name)}</>
      }
    },
    {
      title: 'side',
      dataIndex: 'statsSide',
      // width: '15%',
      editable: true,
      render: (_, record) => {
        return <>{record?.fields?.statsSide || '-'}</>
      }
    },
    {
      title: 'keyword',
      dataIndex: 'keyword',
      editable: true,
      render: (_, record) => {
        return <>{record?.keyword || '-'}</>
      }
    },
    {
      title: 'type',
      dataIndex: 'type',
      editable: true,
      render: (_, record) => {
        return <>{record?.type ? getStatsTypeLabel(record?.type) : '-'}</>
      }
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return <>{editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.rowKey)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <a onClick={() => cancel(record)}>Cancel</a>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        )}{" "}
          {!editable && <Typography.Link onClick={() => handleDelete(record)}>
            Delete
          </Typography.Link>}
        </>


      },
    },
  ];

  const mergedColumns = columns.map((col, index) => {
    if (!col.editable) {
      return col;
    }

    const targerInputType = ['name', 'statsSide', 'type'].includes(col.dataIndex) ? 'select' : 'text'

    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        inputType: targerInputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options: props.options,
        index: rowIndex,
        parentId: props.id,
      }),
    };
  });

  const onDragEnd = ({active, over}) => {
    if (active && over && active.id !== over.id) {
      setData((prev) => {
        const activeIndex = prev.findIndex((i) => i.rowKey === active.id);
        const overIndex = prev.findIndex((i) => i.rowKey === over?.id);
        const output = arrayMove(prev, activeIndex, overIndex);
        form.setFieldValue(props.id, output);
        return output;
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 50, // minimal delay
        tolerance: 10,
      }
    })
  )

  return (<div>
      <Button
        onClick={handleAdd}
        type="secondary"
        block
        style={{
          marginBottom: 15,
          width: '150px'
        }}
      >
        Add a row
      </Button>

      {data && <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={data.map((i) => i.rowKey)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                cell: EditableCell,
                row: SortableRow,
              },
            }}
            rowKey={'rowKey'}
            bordered
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
            {...props}
          />

        </SortableContext>
      </DndContext>}
    </div>

  );
};

import React, {Component, useEffect, useState} from 'react';
import _get from 'lodash/get';
import {Redirect, Route, useHistory} from "react-router-dom";

import authService from "../../../../services/auth.service";
import {useGlobalStore} from "../../../../stores/globalBackendStore";

import {notification} from "antd";
import i18n from "../../../../language";


const CheckLogin = (props) => {
  const {location, t} = props;
  const [user, setUser] = useState();
  const history = useHistory();

  const checkToken = useGlobalStore(state => state.checkToken);
  const setCheckToken = useGlobalStore(state => state.setCheckToken);

  const proveToken = async () => {
    await authService.refreshToken({history: props.history}).catch((err) => {
      if (err.message === "Network Error") {
        authService.logout({history});
      } else if (err.response && err.response.status === 401) {
        notification["error"]({
          message: t("Unauthorized"),
          description: err.config.baseURL + err.config.url
        });
        authService.logout({history});
      }
    });
    if (checkToken) {
      setCheckToken(false);
    }
  };

  useEffect( () => {
    const checkTokenCall = async () => {
      if (!checkToken) {
        await proveToken();
      }
    };
    checkTokenCall()
  }, []);

  useEffect( () => {
    const checkTokenCall = async () => {
      if (checkToken) {
        await proveToken();
      }
    };
    checkTokenCall()
  }, [checkToken]);

  const loggedInUser = localStorage.getItem("user");
  if (!loggedInUser) {
    return <Redirect to={"/backend/login"}/>
  }

  return <>
    {props.children}
  </>;
};

export default CheckLogin;

import React, {useState, useEffect} from "react";
import _ from "lodash";

import {CheckOutlined, CloseOutlined, PlusCircleOutlined} from "@ant-design/icons";
import CreateTemplateDrawer from "../Dashboard/CreateTemplateDrawer";
import {useTranslation} from "react-i18next";

import Config from "../../../../config/frontend"
import parseMediaUrl from "../../../shared/functions/parseMediaUrl";

import SetupTimelineMusic from "./TimelineMusic";

import {
  Form,
  Select,
  InputNumber,
  Switch,
  Radio,
  Slider,
  Button,
  Upload,
  Rate,
  Checkbox,
  Row,
  Col, notification, Divider, Card, Popconfirm,
  Input
} from 'antd';
import {UploadOutlined, InboxOutlined} from '@ant-design/icons';
import {InputColor} from "../../../shared/components/Forms/Fields/types/_FormColor";
import {InputMedia} from "../../../shared/components/Forms/Fields/types/_FormMedia";
import {InputText} from "../../../shared/components/Forms/Fields/types/_FormInputs";
import {InputSelect} from "../../../shared/components/Forms/Fields/types/_FormSelect";
import {InputTable} from "../../../shared/components/Forms/Fields/types/_FormTable";
import {useGlobalStore} from "../../../../stores/globalBackendStore";
import {getApiSetup, setApiSetup, setTemplateById} from "../../../../services/backend/api.service";
import {useHistory} from "react-router-dom";
import BackendConfig from "../../../../config/backend";

const {Option} = Select;
export const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const normFile = (e) => {
  console.log('Upload event:', e);

  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};

const SetupFormDesign = () => {
  const {t} = useTranslation();
  const setApiSetupState = useGlobalStore(state => state.setApiSetup);
  const history = useHistory();

  const apiSetup = useGlobalStore(state => state.apiSetup);

  const {design} = apiSetup;

  const onFinish = async (values) => {
    const setupResponse = await setApiSetup({
      id: 1,
      data: {
        data: {
          design: values
        }
      }
    }).catch((err) => {
      notification["error"]({
        message: t('Setup saving error'),
        description: err.message,
        placement: "topRight"
      });
    });

    notification["success"]({
      message: t('Success'),
      description: t("Setup has been updated."),
      placement: "topRight"
    });

    setApiSetupState(setupResponse.data.data);
  };

  if (!design) {
    return null;
  }

  return (
    <Form
      name="setup_form"
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={{}}
    >

      <InputMedia item={{
        id: "backendBackground",
        label: "Dashboard background",
        allowedMediaTypes: ["image"],
        initialActiveGroup: "backgrounds",
        value: _.get(design, "backendBackground")
      }}/>

      <InputColor item={{id: "defaultColor1", label: "Color 1", value: _.get(design, "defaultColor1")}} tooltip={""}/>
      <InputColor item={{id: "defaultColor2", label: "Color 2", value: _.get(design, "defaultColor2")}}/>

      <InputMedia item={{
        id: "defaultBackground",
        label: "Basic background",
        initialActiveGroup: "backgrounds",
        allowedMediaTypes: ["image"],
        value: _.get(design, "defaultBackground")
      }}/>

      <InputMedia item={{
        id: "defaultBackgroundMask",
        label: "Overlay background (transparent)",
        initialActiveGroup: "backgrounds",
        allowedMediaTypes: ["image"],
        value: _.get(design, "defaultBackgroundMask")
      }}/>


      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 6,
        }}
      >
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

const SetupFormVideo = () => {
  const {t} = useTranslation();
  const apiSetup = useGlobalStore(state => state.apiSetup);
  const setApiSetupState = useGlobalStore(state => state.setApiSetup);
  const history = useHistory();

  const {video} = apiSetup;

  const onFinish = async (values) => {
    const setupResponse = await setApiSetup({
      id: 1,
      data: {
        data: {
          video: values
        }
      }
    }).catch((err) => {
      notification["error"]({
        message: t('Setup saving error'),
        description: err.message,
        placement: "topRight"
      });
    });

    notification["success"]({
      message: t('Success'),
      description: t("Setup has been updated."),
      placement: "topRight"
    });

    setApiSetupState(setupResponse.data.data);
  };

  if (!video) {
    return null;
  }

  return (
    <Form
      name="setup_form"
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={{
        ...video
      }}
    >

      <InputMedia item={{
        id: "templateaudio",
        label: "Template audio",
        allowedMediaTypes: ["audio"],
        initialActiveGroup: "",
        value: _.get(video, "templateaudio")
      }}/>

      <InputMedia item={{
        id: "timelineaudio",
        label: "Timeline audio",
        allowedMediaTypes: ["audio"],
        initialActiveGroup: "",
        value: _.get(video, "timelineaudio")
      }}/>

      <Form.Item
        name="addSoundToTemplate"
        label={t("Attach sound to single template?")}
        valuePropName="checked"
      >
        <Switch
          checkedChildren={<CheckOutlined/>}
          unCheckedChildren={<CloseOutlined/>}
        />
      </Form.Item>

      <Form.Item
        name="addSoundToTimeline"
        label={t("Attach sound to timeline?")}
        valuePropName="checked"
      >
        <Switch
          checkedChildren={<CheckOutlined/>}
          unCheckedChildren={<CloseOutlined/>}
        />
      </Form.Item>

      <InputText item={{
        id: "videoServer",
        label: "Video server URL",
        value: _.get(video, "defaultBackground"),
        tooltip: ""
      }}/>

      <Divider type="horizontal"/>

      <div style={{textAlign: 'center'}}><h3>Custom audio per template</h3></div>

      {_.map(_.filter(apiSetup.templates, item => item.active === true), (item, index) => {
        const elementId = "templateaudio_" + item.name;

        return <div key={elementId} className={"setup-custom-template-audio"}>
          <InputMedia item={{
            id: elementId,
            label: item.label,
            labelImage: `/images/preview/${item.name}.jpg`,
            allowedMediaTypes: ["audio"],
            initialActiveGroup: "",
            value: _.get(video, elementId)
          }}/>
        </div>
      })}


      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 6,
        }}
      >
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

const SetupTimeline = () => {
  const {t} = useTranslation();
  const apiSetup = useGlobalStore(state => state.apiSetup);
  const setApiSetupState = useGlobalStore(state => state.setApiSetup);
  const history = useHistory();

  const {timeline} = apiSetup;

  const onFinish = async (values) => {
    const setupResponse = await setApiSetup({
      id: 1,
      data: {
        data: {
          timeline: {
            settings: values
          }
        }
      }
    }).catch((err) => {
      notification["error"]({
        message: t('Setup saving error'),
        description: err.message,
        placement: "topRight"
      });
    });

    notification["success"]({
      message: t('Success'),
      description: t("Setup has been updated."),
      placement: "topRight"
    });

    setApiSetupState(setupResponse.data.data);
  };

  if (!timeline) {
    return null;
  }

  return (
    <Form
      name="setup_form"
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={{
        ...timeline.settings
      }}
    >

      <div style={{textAlign: 'left'}}><h2>General</h2></div>

      <InputSelect item={{
        id: "playMode",
        label: "Play mode",
        value: _.get(timeline, "settings.playMode"),
        tooltip: "auto / manual",
        selectData: [{
          label: "Automatic",
          value: "auto"
        }, {
          label: "Manual",
          value: "manual"
        }]
      }}/>

      <InputSelect item={{
        id: "timelineAutoLinkEnabled",
        label: "PRE / LIVE / MATCH Timeline Auto-Switch",
        value: _.get(timeline, "settings.timelineAutoLinkEnabled", false),
        tooltip: "Enable / Disable",
        selectData: [{
          label: "Enable",
          value: true
        }, {
          label: "Disable",
          value: false
        }]
      }}/>


      <InputText item={{
        id: "globalTimelineName",
        label: "Global timeline name",
        value: _.get(timeline, "settings.globalTimelineName"),
        tooltip: "",
        placeholder: "Global timeline name"
      }}/>

      <InputSelect item={{
        id: "globalOnPostmatch",
        label: "Attach Global timeline on POST-MATCH",
        value: _.get(timeline, "settings.globalOnPostmatch", false),
        selectData: [{
          label: "Enable",
          value: true
        }, {
          label: "Disable",
          value: false
        }],
        defaultValue: _.get(timeline, "settings.globalOnPostmatch", false)
      }}/>

      <InputSelect item={{
        id: "postmatchOnGlobal",
        label: "Attach POST-MATCH on Global timeline",
        value: _.get(timeline, "settings.postmatchOnGlobal", false),
        selectData: [{
          label: "Enable",
          value: true
        }, {
          label: "Disable",
          value: false
        }],
        defaultValue: _.get(timeline, "settings.postmatchOnGlobal", false)
      }}/>


      <div style={{textAlign: 'left'}}><h2>Advertising</h2></div>

      <InputMedia item={{
        id: "advertiseVideoDe",
        label: "Advertise video DE",
        initialActiveGroup: "ads",
        allowedMediaTypes: ["video"],
        value: _.get(timeline, "settings.advertiseVideoDe")
      }}/>

      <InputMedia item={{
        id: "advertiseVideoEn",
        label: "Advertise video EN",
        initialActiveGroup: "ads",
        allowedMediaTypes: ["video"],
        value: _.get(timeline, "settings.advertiseVideoEn")
      }}/>

      <InputSelect item={{
        id: "advertiseStatus",
        label: "Attach Video AD on PRE/LIVE/POST Timeline",
        value: _.get(timeline, "advertiseStatus", false),
        selectData: [{
          label: "Enable",
          value: true
        }, {
          label: "Disable",
          value: false
        }],
        defaultValue: _.get(timeline, "advertiseStatus", false)
      }}/>

      <InputText item={{
        id: "advertiseInterval",
        label: "Show video X times per X minutes",
        value: _.get(timeline, "advertiseInterval"),
        tooltip: "This will show video on timeline based on interval",
        placeholder: "4x60"
      }}/>

      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 6,
        }}
      >
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

const SetupTimelineAutomation = () => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const apiSetup = useGlobalStore(state => state.apiSetup);
  const timelineAutomationSetup = useGlobalStore(state => state.timelineAutomationSetup);
  const setTimelineAutomationSetup = useGlobalStore(state => state.setTimelineAutomationSetup);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true); // New loading state

  const availableTemplates = _.map(_.filter(apiSetup.templates, item => item.active === true), (item) => {
    return {
      label: item.label,
      value: item.name
    }
  });

  const onFinish = async (values) => {
    const setupResponse = await setApiSetup({
      id: 3,
      data: values
    }).catch((err) => {
      notification["error"]({
        message: t('Setup saving error'),
        description: err.message,
        placement: "topRight"
      });
    });

    notification["success"]({
      message: t('Success'),
      description: t("Setup has been updated."),
      placement: "topRight"
    });

    // setTimelineAutomationSetup(setupResponse.data);
  };

  if (_.isEmpty(timelineAutomationSetup)) {
    return null;
  }

  const timelineLabels = {
    preMatch: 'PRE-MATCH',
    liveMatch: 'LIVE-MATCH',
    postMatch: 'POST-MATCH',
    custom: {
      vip: 'VIP',
      pause: 'Pause',
      vorProgramm: 'Vorprogramm',
      s6Schiris: 'S6+Schiris'
    }
  };

  return (
    <Form form={form}
          className={'timeline-automation-form'}
          name="setup_form"
          layout={"vertical"}
          labelCol={null}
          wrapperCol={null}
          onFinish={onFinish}
          initialValues={{
            timelineLabels,
            preMatch: timelineAutomationSetup.preMatch,
            liveMatch: timelineAutomationSetup.liveMatch,
            postMatch: timelineAutomationSetup.postMatch,
            custom: timelineAutomationSetup.custom
          }
          }
          onValuesChange={(changedValues, allValues) => {
            console.log('FORM:changedValues', changedValues);
            console.log('FORM:allValues', allValues);
          }}
          onFieldsChange={(changedValues, allValues) => {
            // console.log('FORM:onFieldsChange', changedValues);
            // console.log('FORM:onFieldsChange', allValues);
          }}
    >

      <Form.Item
        wrapperCol={{
          span: 4,
          offset: 0,
        }}
      >
        <Button size={'large'} block type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>

      <Form.Item label={`Timeline: ${timelineLabels.preMatch}`} name={["preMatch", "templates"]}>
        <InputTable
          tablePrefix={"preMatch"}
          id={["preMatch", "templates"]}
          options={availableTemplates}
        />
      </Form.Item>

      <Form.Item label={`Timeline: ${timelineLabels.liveMatch}`} name={["liveMatch", "templates"]}>
        <InputTable
          tablePrefix={"liveMatch"}
          id={["liveMatch", "templates"]}
          options={availableTemplates}
        />
      </Form.Item>


      <Form.Item label={`Timeline: ${timelineLabels.postMatch}`} name={["postMatch", "templates"]}>
        <InputTable
          tablePrefix={"postMatch"}
          id={["postMatch", "templates"]}
          options={availableTemplates}
        />
      </Form.Item>


      <h2>Custom Timelines</h2>

      <Form.Item label={`Timeline: ${timelineLabels.custom.vip}`} name={['custom', 0]}>
        <InputTable
          tablePrefix={"custom0_"}
          id={['custom', 0, 'templates']}
          options={availableTemplates}
        />
      </Form.Item>

      <Form.Item label={`Timeline: ${timelineLabels.custom.pause}`} name={['custom', 1]}>
        <InputTable
          tablePrefix={"custom1_"}
          id={['custom', 1, 'templates']}
          options={availableTemplates}
        />
      </Form.Item>

      <Form.Item label={`Timeline: ${timelineLabels.custom.vorProgramm}`} name={['custom', 2]}>
        <InputTable
          tablePrefix={"custom2_"}
          id={['custom', 2, 'templates']}
          options={availableTemplates}
        />
      </Form.Item>

      <Form.Item label={`Timeline: ${timelineLabels.custom.s6Schiris}`} name={['custom', 3]}>
        <InputTable
          tablePrefix={"custom3_"}
          id={['custom', 3, 'templates']}
          options={availableTemplates}
        />
      </Form.Item>

      <Form.Item hidden name={'timelineLabels'}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item hidden name={["preMatch", "label"]}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item hidden name={["liveMatch", "label"]}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item hidden name={["postMatch", "label"]}>
        <Input type="hidden" />
      </Form.Item>
    </Form>
  );
};

const Page = (props) => {

  const {t} = useTranslation();
  const {title, type} = props;

  useEffect(() => {
    require("./style/main.scss");
  }, []);

  return (
    <div className="page-container setup-form-page">
      <header className="jumbotron main-page-header">
        <h2>{t("Setup")} {t(title)}</h2>
      </header>

      <div className={"setup-form"}>
        {type === "video" ? <SetupFormVideo/> : null}
        {/*{type === "general" ? <SetupFormGeneral/> : null}*/}
        {type === "design" ? <SetupFormDesign/> : null}

        {
          (type === "timeline") ? <SetupTimeline/> : null
        }

        {
          (type === "timelineAutomation") ? <SetupTimelineAutomation/> : null
        }

        {
          (type === "timelineMusic") ? <SetupTimelineMusic/> : null
        }

      </div>
    </div>
  );
};

export default Page;

import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();

  $(`#${id} .main-title, #${id} .sub-title`).css("opacity", 0);
  $(`#${id} .live-matchdays-container > div`).css("opacity", 0);

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200
  });

  anime({
    targets: [`#${id} .main-title`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 500
  });

  const timeout1 = setTimeout(() => {
    anime({
      targets: [`#${id} .live-matchdays-container > div`],
      duration: 1300,
      easing: "easeOutQuint",
      // height: [0, ($(`#${id} .live-matchdays-container`).height()) + "px"],
      translateX: [-2000, 0],
      // rotateZ: [22, 22],
      opacity: [1, 1],
      delay: anime.stagger(150)
    });
  }, 800);

  timeouts.push(timeout1);

  return {
    timeline: [],
    timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

import _ from "lodash";

const assets = {
  bg: "/images/preview/staticmedia.jpg",
  blue_bg: require("./images/blue_4x5.jpg"),
  red_bg:  require("./images/red_4x5.jpg"),
  distance_1920: require("./videos/distance_1920x1080.webm"),
  distance_1200: require("./videos/distance_1200x1500.webm"),
  fastest_scater_1920: require("./videos/fastest_scater_1920x1080.webm"),
  fastest_scater_1200: require("./videos/shot_speed_1200x1500.webm"),
  shot_speed_1920: require("./videos/shot_speed_1920x1080.webm"),
  shot_speed_1200: require("./videos/shot_speed_1200x1500.webm"),
  penny_del: require("../../../assets/logos/penny-del-logo.png"),
};

export default assets;

import React from "react";
import {DragOverlay, useDroppable} from "@dnd-kit/core";
import {
  rectSortingStrategy,
  SortableContext,
  verticalListSortingStrategy,
  horizontalListSortingStrategy
} from "@dnd-kit/sortable";

import {SortableItem, DraggableItem, Item} from "./SortableItem";
import {useTranslation} from "react-i18next";

export const ContainerTimeline = React.memo(function ContainerTimeline(props) {
  const {id, items} = props;

  const {t} = useTranslation();

  const {setNodeRef} = useDroppable({
    id
  });

  return (
    <SortableContext
      id={id}
      items={items.map(item => item.templateId)}
      strategy={horizontalListSortingStrategy}
    >
      <div ref={setNodeRef} className={"sortable-container"}>
        {items.map((item, index) => {
          return (
            <SortableItem item={item} key={`${index}${item.templateId}`} id={item.templateId} index={index}/>
          )
        })}
      </div>

      {items.length === 0 &&
        <div className={"empty-timeline-placeholder"}>[ {t("Drop templates here to create a timeline")} ]</div>}
    </SortableContext>
  );
});

export const ContainerPicker = React.memo(function ContainerPicker(props) {
  const {id, items} = props;

  const {setNodeRef} = useDroppable({
    id
  });

  return (
    <SortableContext
      key={items.length}
      id={id}
      items={items.map(item => item.templateId)}
      strategy={rectSortingStrategy}
    >
      <div ref={setNodeRef} className={"sortable-container"}>
        {items.map((item, index) => {
          return (
            <SortableItem item={item} key={`${index}${item.templateId}`} id={item.templateId}/>
          )
        })}
      </div>
    </SortableContext>
  );
});

export const statsPerTemplate = {
  playerCompare: {
    striker: [
      {
        title: "Tore",
        key: "goals",
      },
      {
        title: "Torschüsse",
        key: "totalScoringAtt",
      },
      {
        title: "Assists",
        key: "goalAssist",
      },
      {
        title: "Torschussvorlagen",
        key: "totalAttAssist",
      },
      {
        title: "Ballaktionen",
        key: "touches",
      },
      {
        title: "Pässe gesamt",
        key: "totalPass",
      },
      {
        title: "Passquote in %",
        key: "calcPassquote",
        deps: ["accuratePass", "totalPass"],
        calc: "##accuratePass##/(##totalPass##/100)",
      },
      {
        title: "Zweikampfquote in %",
        key: "calcZweikampfquote",
        deps: ["duelLost", "duelWon"],
        calc: "##duelWon##/((##duelLost##+##duelWon##)/100)",
      },
    ],
    midfielder: [
      {
        title: "Tore",
        key: "goals",
      },
      {
        title: "Torschüsse",
        key: "totalScoringAtt",
      },
      {
        title: "Assists",
        key: "goalAssist",
      },
      {
        title: "Torschussvorlagen",
        key: "totalAttAssist",
      },
      {
        title: "Ballaktionen",
        key: "touches",
      },
      {
        title: "Pässe gesamt",
        key: "totalPass",
      },
      {
        title: "Passquote in %",
        key: "calcPassquote",
        deps: ["accuratePass", "totalPass"],
        calc: "##accuratePass##/(##totalPass##/100)",
      },
      {
        title: "Zweikampfquote in %",
        key: "calcZweikampfquote",
        deps: ["duelLost", "duelWon"],
        calc: "##duelWon##/((##duelLost##+##duelWon##)/100)",
      },
    ],
    defender: [
      {
        title: "Pässe gesamt",
        key: "totalPass",
      },
      {
        title: "Präzise Pässe",
        key: "accuratePass",
      },
      {
        title: "Passquote in %",
        key: "calcPassquote",
        deps: ["accuratePass", "totalPass"],
        calc: "##accuratePass##/(##totalPass##/100)",
      },
      {
        title: "Ballaktionen",
        key: "touches",
      },
      {
        title: "Zweikämpfe",
        key: "calcTotalDuels",
        deps: ["duelLost", "duelWon"],
        calc: "##duelLost##+##duelWon##",
      },
      {
        title: "Zweikampfquote in %",
        key: "calcZweikampfquote",
        deps: ["duelLost", "duelWon"],
        calc: "##duelWon##/((##duelLost##+##duelWon##)/100)",
      },
      {
        title: "gew. Zweikämpfe",
        key: "duelWon",
      },
      {
        title: "Fouls",
        key: "fouls",
      },
    ],
    goalkeeper: [
      {
        title: "Ballaktionen",
        key: "touches",
      },
      {
        title: "Paraden",
        key: "saves",
      },
      {
        title: "Passquote in %",
        key: "calcPassquote",
        deps: ["accuratePass", "totalPass"],
        calc: "##accuratePass##/(##totalPass##/100)",
      },
      {
        title: "Pässe gesamt",
        key: "totalPass",
      },
      {
        title: "Gegentore",
        key: "goalsConceded",
      },
      {
        title: "Spielminuten",
        key: "minsPlayed",
      },
      {
        title: "Fouls",
        key: "fouls",
      },
    ],
  },
  playerCompareSeason: {
    midfielder: [
      {
        title: "Tore",
        key: "Goals",
      },
      {
        title: "Torschüsse",
        key: "Shots On Target ( inc goals )",
      },
      {
        title: "Assists",
        key: "Goal Assists",
      },
      {
        title: "Torschussvorlagen",
        key: "Second Goal Assists",
      },
      {
        title: "Ballaktionen",
        key: "Touches",
      },
      {
        title: "Pässe gesamt",
        key: "Total Passes",
      },
      {
        title: "Passquote in %",
        key: "calcPassquote",
        deps: ["Total Successful Passes ( Excl Crosses & Corners ) ", "Total Passes"],
        calc: "##Total Successful Passes ( Excl Crosses & Corners ) ##/(##Total Passes##/100)",
      },
      {
        title: "Zweikampfquote in %",
        key: "calcZweikampfquote",
        deps: ["Duels lost", "Duels won"],
        calc: "##Duels won##/((##Duels lost##+##Duels won##)/100)",
      },
    ],
    forward: [
      {
        title: "Tore",
        key: "Goals",
      },
      {
        title: "Torschüsse",
        key: "Shots On Target ( inc goals )",
      },
      {
        title: "Assists",
        key: "Goal Assists",
      },
      {
        title: "Torschussvorlagen",
        key: "Second Goal Assists",
      },
      {
        title: "Ballaktionen",
        key: "Touches",
      },
      {
        title: "Pässe gesamt",
        key: "Total Passes",
      },
      {
        title: "Passquote in %",
        key: "calcPassquote",
        deps: ["Total Successful Passes ( Excl Crosses & Corners ) ", "Total Passes"],
        calc: "##Total Successful Passes ( Excl Crosses & Corners ) ##/(##Total Passes##/100)",
      },
      {
        title: "Zweikampfquote in %",
        key: "calcZweikampfquote",
        deps: ["Duels lost", "Duels won"],
        calc: "##Duels won##/((##Duels lost##+##Duels won##)/100)",
      },
    ],
  },
  teamCompare: [
    {
      title: "Torschüsse",
      key: "totalScoringAtt",
    },
    {
      title: "Schüsse aufs Tor",
      key: "ontargetScoringAtt",
    },
    {
      title: "Ballbesitz in %",
      key: "possessionPercentage",
    },
    {
      title: "Pässe gesamt",
      key: "totalPass",
    },
    {
      title: "Passquote in %",
      key: "calcPassquote",
      deps: ["accuratePass", "totalPass"],
      calc: "##accuratePass##/(##totalPass##/100)",
    },
    {
      title: "Zweikampfquote in %",
      key: "calcZweikampfquote",
      deps: ["duelLost", "duelWon"],
      calc: "##duelWon##/((##duelLost##+##duelWon##)/100)",
    },
    {
      title: "Fouls",
      key: "fkFoulLost",
    },
    {
      title: "Ecken",
      key: "cornerTaken",
    },
  ],
  teamCompareSeason: [
    {
      title: "Tore",
      key: "Goals",
    },
    {
      title: "Torschüsse",
      key: "calcTotalShotsAndBlocked",
      deps: ["Total Shots", "Blocked Shots"],
      calc: "##Total Shots##+##Blocked Shots##",
    },
    {
      title: "Schüsse aufs Tor",
      key: "Shots On Target ( inc goals )",
    },
    {
      title: "Fouls",
      key: "Total Fouls Conceded",
    },
    {
      title: "Ecken",
      key: "Corners Taken (incl short corners)",
    },
    {
      title: "Ballbesitz in %",
      key: "Possession Percentage",
    },
    {
      title: "Passquote in %",
      key: "calcPassquote",
      deps: ["Total Successful Passes ( Excl Crosses & Corners ) ", "Total Passes"],
      calc: "##Total Successful Passes ( Excl Crosses & Corners ) ##/(##Total Passes##/100)",
    },
    {
      title: "Zweikampfquote in %",
      key: "calcZweikampfquote",
      deps: ["Duels lost", "Duels won"],
      calc: "##Duels won##/((##Duels lost##+##Duels won##)/100)",
    },
  ],
  teamGoalmouth: [
    {
      title: "Schüsse",
      key: "totalScoringAtt",
    },
    {
      title: "Torschüsse",
      key: "ontargetScoringAtt",
    },
    {
      title: "Tore",
      key: "goals",
    },
    {
      title: "Paraden %",
      key: "calcParaden",
      deps: ["#VERSUS#saves", "ontargetScoringAtt"],
      calc: "#VERSUS#saves##/((##ontargetScoringAtt##)/100)",
    },
  ],
  playerHeatmap: [
    {
      title: "Ballaktionen",
      key: "touches",
    },
    {
      title: "Passquote in %",
      key: "calcPassquote",
      deps: ["accuratePass", "totalPass"],
      calc: "##accuratePass##/(##totalPass##/100)",
    },
    {
      title: "Pässe gesamt",
      key: "totalPass",
    }
  ],
};

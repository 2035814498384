import React, {useEffect, useRef, useState} from 'react';
import {Form, Drawer} from "antd";
import {useTranslation} from "react-i18next";

export default function (props) {
  const {setState} = props;
  const {t} = useTranslation();

  useEffect(() => {

  }, []);


  return (
    <div className={"comp-GlobalSettigsDrawer"}>
      <Drawer
        title={t("Global settings")}
        placement={"right"}
        closable={true}
        onClose={props.onClose}
        open={props.visible}
        width={450}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Drawer>
    </div>
  );
}

import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();

  $(`#${id} .team-logo-container img`).css("opacity", 0);
  $(`#${id} .headline`).css("opacity", 0);
  $(`#${id} .last-x-games`).css("opacity", 0);
  $(`#${id} .team-stats .value, #${id} .team-stats .label `).css("opacity", 0);

  $(`#${id} .team-block-line`).css("opacity", 0);
  $(`#${id} .filler-point-base, #${id} .filler-container`).css("opacity", 0);
  $(`#${id} .filler-container`).css("width", 0);
  $(`#${id} .stroke-shadow, .stroke-shadow-2`).css("opacity", 0);
  $(`#${id} .line-stats-container .value, #${id} .line-stats-container .box-bg`).css("opacity", 0);


  anime({
    targets: [`#${id} .team-logo-container img`],
    duration: 600,
    easing: "easeOutSine",
    opacity: [1, 1],
    translateX: [800, 0],
    // rotateZ: ["22deg", "22deg"],
    delay: 1000
  });


  anime({
    targets: [`#${id} .headline`],
    duration: 800,
    easing: "easeOutQuint",
    opacity: [1, 1],
    scale: [0, 1],
    delay: 600
  });

  anime({
    targets: [`#${id} .last-x-games`],
    duration: 800,
    easing: "easeOutQuint",
    opacity: [1, 1],
    scale: [0, 1],
    delay: 700
  });

  anime({
    targets: `#${id} .team-block-line`,
    keyframes: [
      {clipPath: 'inset(100% 0 0 0)', duration: 0},
      {clipPath: 'inset(0% 0 0 0)', duration: 800},
    ],
    easing: 'easeInOutQuart',
    opacity: [1, 1],
    delay: 700, // 200ms delay between each elemen
  });


  anime({
    targets: [`#${id} .team-stats-block .value`],
    duration: 1000,
    easing: "easeOutQuint",
    opacity: [1, 1],
    scale: [0, 1],
    delay: anime.stagger(120, {start: 900})
  });

  anime({
    targets: [`#${id} .team-stats-block .label`],
    duration: 1000,
    easing: "easeOutQuint",
    opacity: [1, 1],
    scale: [0, 1],
    delay: anime.stagger(120, {start: 900})
  });

  anime({
    targets: [`#${id} .line-stats-container .label`],
    duration: 1000,
    easing: "easeOutQuint",
    opacity: [1, 1],
    scale: [0, 1],
    delay: anime.stagger(120, {start: 1100})
  });

  anime({
    targets: [`#${id} .line-stats-container .value, #${id} .line-stats-container .box-bg`],
    duration: 1000,
    easing: "easeOutQuint",
    opacity: [1, 1],
    scale: [0, 1],
    delay: anime.stagger(120, {start: 1200})
  });

  const timeout3 = setTimeout(() => {
    anime({
      targets: [`#${id} .filler-container`],
      duration: 1000,
      easing: "easeOutQuint",
      opacity: [1, 1],
      width: [0, 650],
      // translateY: [1000, 0],
      delay: anime.stagger(120)
    });

  }, 1500);

  timeouts.push(timeout3);


  return {
    timeline: timeline,
    timeouts: timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

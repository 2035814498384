import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();

  // $(`#${id} .teams-block .home img`).css("opacity", 0);
  // $(`#${id} .players-block-container .players > div`).css("opacity", 0);
  $(`#${id} .player-group-line, #${id} .player-group-line-2`).css("opacity", 0);
  $(`#${id} .names .player-data`).css("opacity", 0);
  $(`#${id} .player-image-mask`).css("opacity", 0);
  $(`#${id} .player-number`).css("opacity", 0);
  $(`#${id} .team-logo-container`).css("opacity", 0);
  // $(`#${id} .teams-block`).css("opacity", 0);


  const params = {};

  if (format === "1920x1080") {
    params.headlineTop = 380
    params.headlineScale = 2.0
  } else if (format === "1200x1500") {
    params.headlineTop = 400
    params.headlineScale = 1.1
  }

  anime.timeline({
    targets: `#${id} .headline`,
    easing: 'easeInOutQuint',
    delay: 200,
  })
    .add({
      scale: [0, params.headlineScale],
      duration: 900,
      opacity: [1, 1],
    })
    .add({
      scale: [params.headlineScale, 1.0],
      top: [params.headlineTop, $(`#${id} .headline`).css('top')],
      duration: 600,
      delay: 100,
      opacity: [1, 1],
    });

  anime.timeline({
    targets: `#${id} .player-number`,
    easing: 'easeInOutQuint',
    delay: anime.stagger(60, {start: 1300}), // 200ms delay between each elemen
  })
    .add({
      scale: [0, 1],
      duration: 700,
      opacity: [1, 1],
    })

  anime.timeline({
    targets: `#${id} .player-image-mask`,
    easing: 'easeInOutQuint',
    delay: anime.stagger(60, {start: 1400}), // 200ms delay between each elemen
  })
    .add({
      translateY: [250, 0],
      duration: 700,
      opacity: [1, 1],
    })

  anime({
    targets: `#${id} .names .player-data`,
    keyframes: [
      {translateY: [100, 0], duration: 700}],
    easing: 'easeOutQuint',
    opacity: [1, 1],
    delay: anime.stagger(60, {start: 1500}), // 200ms delay between each elemen
  });

  anime({
    targets: [`#${id} .teams-block .home img`],
    duration: 600,
    easing: "easeInOutSine",
    opacity: [0, 1],
    left: [0, 0],
    scale: [0, 1],
    // rotateZ: ["22deg", "22deg"],
    delay: 0
  });


  anime({
    targets: `#${id} .player-group-line`,
    keyframes: [
      {clipPath: 'inset(100% 0 0 0)', duration: 0},
      {clipPath: 'inset(0% 0 0 0)', duration: 800},
    ],
    easing: 'easeInOutQuart',
    opacity: [1, 1],
    delay: anime.stagger(300, {start: 1200}), // 200ms delay between each elemen
  });

  anime({
    targets: `#${id} .player-group-line-2`,
    keyframes: [
      {clipPath: 'inset(100% 0 0 0)', duration: 0},
      {clipPath: 'inset(0% 0 0 0)', duration: 800},
    ],
    easing: 'easeInOutQuart',
    opacity: [1, 1],
    delay: anime.stagger(300, {start: 1200}), // 200ms delay between each elemen
  });


  if (format === "1920x1080") {
    anime({
      targets: [`#${id} .team-logo-container.home, #${id} .team-logo-container.away`],
      duration: 800,
      easing: "easeOutQuint",
      opacity: [1, 1],
      translateX: [600, 0],
      delay: 700,
    });
  } else if (format === "1200x1500") {


    anime({
      targets: [`#${id} .teams-block`],
      duration: 800,
      easing: "easeOutQuint",
      opacity: [1, 1],
      translateX: [-300, -300],
      delay: 400,
    });

    anime({
      targets: [`#${id} .teams-block`],
      duration: 1000,
      easing: "easeOutQuint",
      opacity: [1, 1],
      translateX: [-300, 0],
      delay: 1600,
    });


    anime({
      targets: [`#${id} .team-vs`],
      duration: 800,
      easing: "easeOutQuint",
      opacity: [1, 1],
      scale: [0, 1],
      delay: 400,
    });

    anime({
      targets: [`#${id} .team-logo-container.home`],
      duration: 800,
      easing: "easeOutQuint",
      opacity: [1, 1],
      translateX: [-800, 0],
      delay: 700,
    });

    anime({
      targets: [`#${id} .team-logo-container.away`],
      duration: 800,
      easing: "easeOutQuint",
      opacity: [1, 1],
      translateX: [800, 0],
      delay: 700,
    });
  }


  // const timeout1 = setTimeout(() => {
  //
  //   let teamLogoParams = {
  //     size: null,
  //     left: null,
  //     right: null
  //   };
  //
  //   if (format === "1920x1080") {
  //     teamLogoParams = {
  //       top: 23,
  //       left: 1600,
  //       size: 230
  //     }
  //   } else if (format === "1200x1500") {
  //     teamLogoParams = {
  //       top: 74,
  //       left: 960,
  //       size: 190
  //     }
  //   }
  //
  //   anime({
  //     targets: [`#${id} .teams-block .home`],
  //     duration: 1200,
  //     easing: "easeOutQuint",
  //     top: teamLogoParams.top,
  //     left: teamLogoParams.left,
  //     width: teamLogoParams.size,
  //     height: teamLogoParams.size,
  //     delay: 0
  //   });
  //
  // }, 1000);

  // const timeout2 = setTimeout(() => {
  //
  //   anime({
  //     targets: [`#${id} .players-block-container .players > div`],
  //     duration: 1000,
  //     easing: "easeOutQuint",
  //     translateX: [-2000, 0],
  //     opacity: [1, 1],
  //     delay: anime.stagger(20)
  //   });
  //
  //   anime({
  //     targets: [`#${id} .team-vs,  #${id} .team-logo-container.away`],
  //     duration: 800,
  //     easing: "linear",
  //     translateX: [0, 0],
  //     opacity: [0, 1],
  //     delay: 200
  //   });
  //
  //
  // }, 1200);

  // timeouts.push(timeout1);
  // timeouts.push(timeout2);

  return {
    timeline: timeline,
    timeouts: timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

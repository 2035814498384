import {InputText} from './types/_FormInputs';
import {InputMedia} from './types/_FormMedia';
import {InputHtml} from './types/_FormHtml';
import {InputColor} from './types/_FormColor';
import {InputSelect} from './types/_FormSelect';

export default (props) => {
  const {fields, form} = props;
  const entries = [];

  fields.map((item, index) => {
    if (item.type === "text") {
      entries.push(<InputText key={item.id + index} item={item} index={index}/>)
    } else if (item.type === "media") {
      entries.push(<InputMedia key={item.id + index} item={item} index={index}/>)
    } else if (item.type === "select") {
      entries.push(<InputSelect key={item.id + index} item={item} index={index}/>)
    } else if (item.type === "html") {
      entries.push(<InputHtml key={item.id + index} item={item} index={index}/>);
    } else if (item.type === "color") {
      entries.push(<InputColor key={item.id + index} item={item} index={index}/>);
    } else {
      <br/>;
      entries.push(<div key={"Not_supported_" + index}>Form type not supported</div>);
    }
  });

  return <>
    {entries}
  </>
}

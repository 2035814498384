import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import _ from "lodash";

import RenderPreviewByType from "../RenderPreviewByType";

import {Form, Input, Button, Radio, Tooltip, Modal} from 'antd';
import {FolderViewOutlined, InfoCircleOutlined} from '@ant-design/icons';
import MediaLibrary from "../../../MediaLibrary/Index";
import EditMedia from "../../../MediaLibrary/EditMedia";
import Config from "../../../../../../config/frontend";
import parseMediaUrl from "../../../../../shared/functions/parseMediaUrl";
import urlParse from "url-parse";


const InputField = (props) => {
  const {mediaValue} = props;

  useEffect(() => {
    props.onChange(mediaValue);
  }, [mediaValue]);

  return <Input placeholder="input placeholder" value={props.value}/>
};


export const InputMedia = (props) => {
  const {item, form, skipForm} = props;
  const {labelImage} = item;

  const [openMediaLibrary, setOpenMediaLibrary] = useState(false);
  const [mediaValue, setMediaValue] = useState(item.value);

  const openModal = (e) => {
    setOpenMediaLibrary(!openMediaLibrary);
  };

  const onMediaValueChange = (prop, next1, next2) => {
    console.log("onMediaValueChange prop", prop)
  };

  useEffect(() => {
    // onMediaValueChange('test')

    // setTimeout(()=> {
    //     setMediaValue(null)
    // }, 500)

  }, []);

  useEffect(() => {
    if (item.value) {
      setMediaValue(item.value)
    }
  }, [item.value]);

  const mediaUrl = parseMediaUrl(_.get(mediaValue, "url", ""));
  const parsedUrl = urlParse(mediaUrl);

  return <div className={"form-input-media"}>

    <Form.Item label={item.label} tooltip={props.tooltip}>

      {!skipForm && <Form.Item name={item.id} noStyle hidden>
        <InputField mediaValue={mediaValue}/>
      </Form.Item>}

      <div className={"media-preview-block " + (labelImage ? 'media-preview-block-with-label-image' : '')}
           onClick={openModal}>
        <>
          {labelImage && <div className={'label-image'}>
            <img src={labelImage}/>
          </div>}
          {mediaUrl ? <RenderPreviewByType key={mediaUrl + item.updatedAt} url={mediaUrl} item={mediaValue}/> :
            <Tooltip className={"open-media-library"} title="Media library">
              <FolderViewOutlined className={"media-preview-empty"}/>
            </Tooltip>}
        </>
      </div>

    </Form.Item>

    <Modal
      title="Media"
      centered
      open={openMediaLibrary}
      // visible={true}
      onOk={() => openModal(false)}
      onCancel={() => openModal(false)}
      maskClosable={false}
      width={1000}
    >
      <MediaLibrary onSelect={(e, selectedItem) => {
        if (!skipForm) {
          setMediaValue(selectedItem)
        }
        // setMediaValue(selectedItem);
        // setMediaValue("test");
        // form.setFieldsValue({headline: "test"})
      }} item={item} initialActiveGroup={_.get(item, "initialActiveGroup", null)}/>
    </Modal>

  </div>
};

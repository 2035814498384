import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();

  $(`#${id} .teams-block .home, #${id} .teams-block .away`).css("opacity", 0);
  $(`#${id} .headline`).css("opacity", 0);
  $(`#${id} .team-vs`).css("opacity", 0);
  $(`#${id} .teams-block > .team-vs`).css("opacity", 0);
  $(`#${id} .date-container, #${id} .hashtag`).css("opacity", 0);

  anime({
    targets: [`#${id} .custom-background`], duration: 14000, easing: "linear", scale: [1, 1.35], delay: 0
  });

  anime({
    targets: [`#${id} .headline`],
    scale: [
      {
        value: [0, 1.3], duration: 800,
      },
      {
        value: 1.0, duration: 600, delay: 0
      }
    ],
    easing: 'easeInOutQuint',
    opacity: [1, 1],
    delay: 100
  });


  anime({
    targets: [`#${id} .team-vs`],
    scale: [
      {
        value: [0, 1.15], duration: 800,
      },
      {
        value: 1.0, duration: 600, delay: 0
      }
    ],
    easing: 'easeInOutQuint',
    opacity: [1, 1],
    delay: 800
  });

  anime({
    targets: [`#${id} .teams-block .home`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-600, 0],
    opacity: [1, 1],
    delay: 1200
  });

  anime({
    targets: [`#${id} .teams-block .away`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [600, 0],
    opacity: [1, 1],
    delay: 1200
  });


  const timeout1 = setTimeout(() => {

    anime({
      targets: [`#${id} .date-container`], duration: 1200, easing: "easeInOutQuint", // translateX: [-800, 0],
      opacity: [1, 1], scale: [0, 1], delay: 0
    });

    anime({
      targets: [`#${id} .hashtag`], duration: 1200, easing: "easeInOutQuint", // translateX: [-800, 0],
      opacity: [1, 1], scale: [0, 1], filter: ["blur(50px)", "blur(0)"], delay: 200
    });
  }, 1000);


  timeouts.push(timeout1);
  // timeouts.push(timeout3);

  return {
    timeline: timeline, timeouts: timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

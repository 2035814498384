export default {
  id: "2000",
  name: "Timeline Welcome",
  format: "1920x1080",
  options: {
    mode: "auto"
  },
  templates: [
    {
      templateId: "0003",
      template: "teamfacts"
    },
    {
      templateId: "0004",
      template: "playercompare"
    },
    {
      templateId: "0005",
      template: "teamcompare"
    }
  ],
  total: 3,
  usedTemplates: [
    "playerfacts",
    "playerstats",
    "teamfacts",
    "teamcompare",
    "playercompare"
  ]
};

import _ from "lodash";
import {Modal, notification} from "antd";
import React, {useEffect, useState} from "react";

import {InboxOutlined} from '@ant-design/icons';

import {Form, Input, Button, Radio, Upload, message} from 'antd';
import {useGlobalStore} from "../../../../stores/globalBackendStore";
import parseMediaUrl from "../../functions/parseMediaUrl";
import {uploadMedia} from "../../../../services/backend/api.service";
import {useTranslation} from "react-i18next";


const {Dragger} = Upload;

const validateMessages = {
  required: "'${name}' is required!",
  // ...
};

export default ((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const apiSetup = useGlobalStore(state => state.apiSetup);

  const {id, visibleEdit, setVisibleEdit, editData, setRefreshData, accept} = props;

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [file, setFile] = useState({
    uid: editData.uuid,
    name: editData.label,
    status: 'done',
    url: parseMediaUrl(editData.url),
    thumbUrl: parseMediaUrl(editData.url),
  });
  const [previewData, setPreviewData] = useState({
    previewVisible: false
  });

  const onValuesChange = ((value, allValues) => {
    // console.log("values", value);
    // console.log("allValues", allValues);
  });

  const onFinish = (async (values) => {
    const fallbackLabelFile = _.get(values, "files[0].name");
    const formLabel = _.isEmpty(values.label) ? fallbackLabelFile : values.label;

    const formData = new FormData();

    _.map(values, (item, key) => {
      if (key === "files") {
        formData.append("file", _.get(values, "files[0]"));
      } else if (key === "label") {
        formData.append("label", formLabel);
      } else {
        formData.append(key, item);
      }
    });

    await uploadMedia({
      uuid: editData.uuid,
      data: formData,
      group: editData.group
    }).catch((err) => {
      console.error('err', err);
      notification["warning"]({
        message: t('Warning'),
        description: t("Media upload problem: ") + err.message,
        placement: "topRight"
      });
      setConfirmLoading(false);
      setVisibleEdit(false);
    });

    setConfirmLoading(false);
    setVisibleEdit(false);
    setRefreshData(true);
  });

  const customRequest = ({file, onSuccess}) => {
    onSuccess("ok");
  };

  const draggerProps = {
    accept,
    name: 'file',
    maxCount: 1,
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: true,
      showDownloadIcon: true
    },
    multiple: false,
    action: null,
    // beforeUpload: (file, fileList) => {
    //     return true;
    // },
    listType: 'picture',
    defaultFileList: editData.uuid === "0" ? [] : [file],
    customRequest,
    onChange(info) {
      const {status} = info.file;

      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }

      if (info.file.originFileObj) {
        const objectUrl = URL.createObjectURL(info.file.originFileObj);

        setFile({
          ...file,
          url: objectUrl,
          name: info.file.name
        });

        form.setFieldsValue({files: [info.file.originFileObj]});
      }

    },
    onPreview(file) {
      setPreviewData({
        previewVisible: true
      })
    },
  };

  useEffect(() => {
    // console.log("editData", editData);
  }, []);

  const onSave = ((data) => {
    setConfirmLoading(true);
    form.submit();
    // setVisibleEdit(false)
  });

  const currentGroup = _.get(editData, "group", 0);
  const currentType = _.get(editData, "content_type", "");

  //use label, when data is empty
  const getInitialValues = () => {
    if (_.isEmpty(editData.data)) {
      return {
        label: editData.label
      }
    } else {
      return {
        ...editData.data
      }
    }
  };

  return <Modal
    title="Media edit"
    // centered
    style={{top: 20}}
    open={visibleEdit}
    okText={"Save"}
    confirmLoading={confirmLoading}
    onOk={() => {
      onSave();
    }}
    onCancel={() => setVisibleEdit(false)}
    maskClosable={false}
    width={1000}
    height={700}
    className={"media-edit-form"}
  >
    {editData ? <>
      <Dragger {...draggerProps}
        // onMouseEnter={(e) => {
        //     $(e.target).parents(".ant-upload-drag").addClass("upload-mouse-enter")
        // }}
        // onMouseLeave={(e) => {
        //     $(e.target).parents(".ant-upload-drag").removeClass("upload-mouse-enter")
        // }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined/>
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other
          band files
        </p>

        {/*<img src={"https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"}/>*/}
      </Dragger>

      <Modal
        key={previewData.previewVisible}
        open={previewData.previewVisible}
        title={file.name}
        footer={null}
        onCancel={() => {
          setPreviewData({
            previewVisible: false
          })
        }}
      >
        <img alt={file.name} style={{width: '100%'}} src={file.url}/>
      </Modal>

      <Form
        validateMessages={validateMessages}
        layout={"horizontal"}
        form={form}
        initialValues={getInitialValues()}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        {/*{_.map(editData.data, (item, key) => {*/}
        {/*    return <Form.Item key={key} label={_.capitalize(key)} name={key} rules={[]}>*/}
        {/*        <Input placeholder={_.capitalize(key)}/>*/}
        {/*    </Form.Item>*/}
        {/*})}*/}

        <Form.Item hidden={true} key={"files"} name={"files"} rules={[]}>
          <Input/>
        </Form.Item>
        {_.map(_.get(apiSetup, `mediaLibrary.groups.${currentType}.${currentGroup}.fields`, []), (item, key) => {
          return <Form.Item key={item.name} label={item.label} name={item.name} rules={[]}>
            <Input placeholder={item.label}/>
          </Form.Item>
        })}
      </Form>
    </> : null}

  </Modal>
});

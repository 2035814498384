import axiosPkg from "axios";
import backendConfig from "../../config/backend";
import frontendConfig from "../../config/frontend";
import authHeader from "../axios/auth-header";
import {notification} from "antd";

import {useGlobalStore} from "../../stores/globalBackendStore";

import i18n from "../../language";

export const axios = axiosPkg.create({
  baseURL: backendConfig.apiUrl,
  timeout: 20000,
  headers: {}
});

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  if (process.env.LOG_API_CALLS) {
    console.log('|--REQUEST--|' + config.method, config.baseURL + config.url);
  }

  //refresh authorization header, before every call, to make sure is always up-to-date
  const authorization = authHeader();
  if (authHeader) {
    config.headers = {
      ...config.headers,
      ...authorization
    };
  }

  return config;
}, function (error) {
  // Do something with request error

  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  if (process.env.LOG_API_CALLS) {
    console.log('|--RESPONSE--|', response.request.responseURL, response.status, response.data);
  }

  // if(error.code === "401") {
  //     notification["error"]({
  //         message: i18n.t("Unauhorized"),
  //         description: error.config.baseURL + error.config.url
  //     });
  // }

  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  // i18n.changeLanguage("de");

  if (error.message === "Network Error") {
    // notification["error"]({
    //   message: i18n.t("Network error"),
    //   description: error.config.url
    // });

    console.error('AXIOS error', error)
    const setCheckToken = useGlobalStore.getState().setCheckToken;
    setCheckToken(true);

    console.warn('AXIOS auto reload, because of network-error....', error)

    if(window.location.href.indexOf('/frontend/timeline/') > -1) {
      setTimeout(() => {
        window.location.reload();
      }, 5000)
    }
  } else if (error.response && error.response.status === 401) {
    const setCheckToken = useGlobalStore.getState().setCheckToken;
    setCheckToken(true);
  }

  return Promise.reject(error);
});

import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();
  $(`#${id} .headline`).css("opacity", 0);
  $(`#${id} .headline2`).css("opacity", 0);
  $(`#${id} .referees > div`).css("opacity", 0);
  $(`#${id} .team-logo-container`).css("opacity", 0);

  anime({
    targets: [`#${id} .headline`],
    duration: 700,
    easing: "easeOutQuint",
    translateX: [-1500, 0],
    opacity: [1, 1],
    // rotateZ: [22, 22],
    delay: 500
  });


  anime({
    targets: [`#${id} .headline2`],
    duration: 700,
    easing: "easeOutQuint",
    translateX: [-1500, 0],
    opacity: [1, 1],
    // rotateZ: [22, 22],
    delay: 700
  });


  anime({
    targets: [`#${id} .team-logo-container.home, #${id} .team-logo-container.away`],
    duration: 800,
    easing: "easeOutQuint",
    opacity: [1, 1],
    translateX: [600, 0],
    delay: 900,
  });

  const timeout1 = setTimeout(() => {


    anime({
      targets: [$(`#${id} .main-referees > div`).toArray().reverse()],
      duration: 800,
      easing: "easeOutQuint",
      // top: [1000, $(`#${id} .main-referees > div`).css("top")],
      left: [-1000, 0],
      filterBlur: ["0", "20"],
      // rotateZ: [22, 22],
      opacity: [0, 1],
      delay: anime.stagger(180)
    });


    const propAnim = {
      blur: 0
    };

    anime({
      targets: propAnim,
      duration: 400,
      blur: 10,
      easing: 'linear',
      round: 1,
      update: function () {
        const blurValue = 10 - propAnim.blur;
        $(`#${id} .referees`).css("filter", `blur(${blurValue}px)`);
      }
    });


  }, 1300);

  const timeout2 = setTimeout(() => {
    anime({
      targets: [$(`#${id} .line-referees > div`).toArray().reverse()],
      duration: 800,
      easing: "easeOutQuint",
      // top: [1000, $(`#${id} .main-referees > div`).css("top")],
      left: [-1000, 0],
      filterBlur: ["0", "20"],
      // rotateZ: [22, 22],
      opacity: [0, 1],
      delay: anime.stagger(180)
    });


  }, 1700);

  timeouts.push(timeout1);
  // timeouts.push(timeout2);

  return {
    timeline: timeline,
    timeouts: timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

import _get from "lodash/get";
import axios from "axios";

export const getTemplateLabel = (setupTemplates, templateUniq) => {
  return _.find(setupTemplates, {name: templateUniq})?.label || templateUniq
};

export const getStatsTypeLabel = (type) => {
  const types = {
    distanceTravelled: 'Traveled Distance',
    fastestScater: 'Fastest Skater',
    shotSpeed: 'Shot Speed',
  }
  return _.get(types, type, type)
};

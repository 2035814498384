import React from 'react';
import {createRoot} from 'react-dom/client';
import ReactDOM from 'react-dom';
import './index.css';
import './language';
import App from './App';
import configFrontend from './config/frontend'
import {getWebInstrumentations, initializeFaro} from '@grafana/faro-web-sdk';
// import {TracingInstrumentation} from '@grafana/faro-web-tracing';
import {ConsoleInstrumentation, ErrorsInstrumentation} from '@grafana/faro-web-sdk';

console.log('INDEX - NODE_ENV', process.env.NODE_ENV)

if (process.env.NODE_ENV === 'production' && configFrontend?.client === 'adler_live') {
  initializeFaro({
    url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/336a2865e214162745979a450ccc2502',
    app: {
      name: 'Adler DEL',
      version: '1.0.0',
      environment: 'production'
    },
    instrumentations: [
      // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
      ...getWebInstrumentations({captureConsole: true}),

      // Initialization of the tracing package.
      // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
      // new TracingInstrumentation(),
      new ConsoleInstrumentation(),
      new ErrorsInstrumentation()
    ],
  });

  console.log('INDEX - INIT MONITORING 1 - READY')
}

if (process.env.NODE_ENV === 'production' && configFrontend?.client === 'womansworldcup_live') {
  var faro = initializeFaro({
    url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/1b766cbcdea06f73192e22e3966800c8',
    app: {
      name: 'PerformStats - APP Live (womans worldcup)',
      version: '1.0.0',
      environment: 'production'
    },
    sessionTracking: {
      enabled: false
    },
    instrumentations: [
      // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
      ...getWebInstrumentations(),

      // Initialization of the tracing package.
      // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
      // new TracingInstrumentation(),
    ],
  });
  console.log('INDEX - INIT MONITORING 2 - READY')
}


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import getContentById from "../../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";

import {engine, fadeIn, slide} from "../../../../../animations/index"
import animationScript, {animationCleanUp} from "../../../../../animations/worldcup/seasongroupoverview"
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

import assets from './assets';
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import {useTranslation} from "react-i18next";
import {format as dateFormat} from "date-fns";
import {de, enUS} from "date-fns/locale";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import {
  TeamOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import Image from "../../../../shared/components/Image"
import {toIsoDateString} from "../../../../shared/utils/functions";

require('./style/style.scss');
require('./style/animation.scss');

function findGroupByContestantId(data, contestantId) {
  return _.findKey(data, (group, groupName) => {
    return group.some(match => {
      return _.get(match, 'matchInfo.contestant[0].id') === contestantId ||
        _.get(match, 'matchInfo.contestant[1].id') === contestantId;
    });
  });
}

const renderAllByChunks = ({groupStage, t, selectedTeamId}) => {
  return _.chunk(Object.values(groupStage), 4).map((groupChunk, chunkIndex) => (
    <div className={`page-${chunkIndex + 1}`} key={`page-${chunkIndex + 1}`}>
      {_.map(groupChunk, (group, groupIndex) => {
        const groupId = group?.[0]?.matchInfo?.series?.name?.replace('Group ', '');
        return <div className={`live-matchdays-group group-${_.toLower(groupId)}`}>
          <div className={'group-label'}>{t('Group', {keyPrefix: 'templates'})} {groupId}</div>

          <div className={'group-matches'}>{_.map(group, (match, matchIndex) => {

            const matchInfo = match?.matchInfo || {}
            const groupName = matchInfo?.series?.name

            const liveData = match?.liveData || {}
            const liveDataExtra = match?.liveData?.matchDetailsExtra || {}
            const matchStatus = _.toLower(liveData?.matchDetails?.matchStatus)

            const matchDate = new Date(
              toIsoDateString(matchInfo?.date, matchInfo?.time)
            );

            const time = matchInfo?.time ? dateFormat(matchDate, "HH:mm", {locale: de}) : '-';

            const home = _.find(matchInfo?.contestant, {"position": 'home'});
            const away = _.find(matchInfo?.contestant, {"position": 'away'});
            const homeId = _.get(home, "id");
            const awayId = _.get(away, "id");

            const teamHomeAlpha2 = getOptaCountryCode(home?.name, home?.shortName, home?.code);
            const teamAwayAlpha2 = getOptaCountryCode(away?.name, away?.shortName, away?.code);

            const isAdlerMatch = selectedTeamId === homeId || selectedTeamId === awayId ? true : false;
            let matchStatusClass = "";
            let statusString = "";
            let timeString = ""

            if (matchStatus === "played") {
              matchStatusClass = "after";
              statusString = "";
            } else if (matchStatus === "fixture") {
              matchStatusClass = "before";
              statusString = <span
                className={'status-string-time'}>{time} {dateFormat(matchDate, lng === 'de' ? "dd.MM." : "MM/dd", {locale: lng === 'de' ? de : enUS})}</span>;
            } else if (matchStatus === 'playing') {
              matchStatusClass = "live";
              statusString = "Live";
            }

            return <div key={`m-${matchInfo.id}`}
                        className={`match ${isAdlerMatch ? "adler-match" : ""} status-${matchStatusClass}`}>
              {/*<div className={"match"}>*/}

              <div className={"home-name"}>{t(home?.name, {ns: 'countries'})}</div>

              <div className={"team-logo-container home"}>
                <Image className="team-logo" src={`/images/countries/svg/${teamHomeAlpha2}.svg`}/>
              </div>

              <div className={"score-container"}>
                <div
                  className={"score-home"}>{matchStatus === "fixture" ? '-' : _.get(liveData, "matchDetails.scores.total.home", 0)}</div>
                <div className={"score-separator"}>:</div>
                <div
                  className={"score-away"}>{matchStatus === "fixture" ? '-' : _.get(liveData, "matchDetails.scores.total.away", 0)}</div>
                <div
                  className={`match-status ${matchStatus === 'playing' ? 'blink-me' : ''}`}>{statusString}</div>

                {/*<div*/}
                {/*  className={"attendance-info"}>*/}
                {/*  <TeamOutlined/> {liveDataExtra?.attendance || '-'}*/}
                {/*</div>*/}

                {/*{matchInfo?.series?.name.replace('Group', t('Group', {keyPrefix: 'templates'})) && <div*/}
                {/*  className={"group-info"}>*/}
                {/*  {matchInfo?.series?.name.replace('Group', t('Group', {keyPrefix: 'templates'})) || ''}*/}
                {/*</div>}*/}
              </div>

              <div className={"team-logo-container away"}>
                <Image className="team-logo" src={`/images/countries/svg/${teamAwayAlpha2}.svg`}/>
              </div>
              <div className={"away-name"}>{t(away?.name, {ns: 'countries'})}</div>
              {/*</div>*/}
            </div>
          })}</div>
        </div>
      })}
    </div>
  ))
}

const renderSingle = ({targetGroup, groupStage,  t, selectedTeamId, lng}) => {
  const group = groupStage[targetGroup];
  const groupId = targetGroup?.replace('Group ', '');
  return <div className={`live-matchdays-group group-${_.toLower(groupId)}`}>
      <div className={'group-matches'}>{_.map(group, (match, matchIndex) => {

        const matchInfo = match?.matchInfo || {}
        const groupName = matchInfo?.series?.name

        const liveData = match?.liveData || {}
        const liveDataExtra = match?.liveData?.matchDetailsExtra || {}
        const matchStatus = _.toLower(liveData?.matchDetails?.matchStatus)

        const matchDate = new Date(
          toIsoDateString(matchInfo?.date, matchInfo?.time)
        );

        const time = matchInfo?.time ? dateFormat(matchDate, "HH:mm", {locale: de}) : '-';

        const home = _.find(matchInfo?.contestant, {"position": 'home'});
        const away = _.find(matchInfo?.contestant, {"position": 'away'});
        const homeId = _.get(home, "id");
        const awayId = _.get(away, "id");

        const teamHomeAlpha2 = getOptaCountryCode(home?.name, home?.shortName, home?.code);
        const teamAwayAlpha2 = getOptaCountryCode(away?.name, away?.shortName, away?.code);

        const isAdlerMatch = selectedTeamId === homeId || selectedTeamId === awayId ? true : false;
        let matchStatusClass = "";
        let statusString = "";
        let timeString = ""

        if (matchStatus === "played") {
          matchStatusClass = "after";
          statusString = t("Finished");
        } else if (matchStatus === "fixture") {
          matchStatusClass = "before";
          statusString = <span
            className={'status-string-time'}>{time} {dateFormat(matchDate, lng === 'de' ? "dd.MM." : "MM/dd", {locale: lng === 'de' ? de : enUS})}</span>;
        } else if (matchStatus === 'playing') {
          matchStatusClass = "live";
          statusString = "Live";
        }

        return <div key={`m-${matchInfo.id}`}
                    className={`match ${isAdlerMatch ? "adler-match" : ""} status-${matchStatusClass}`}>
          {/*<div className={"match"}>*/}

          <div className={"home-name"}>{t(home?.name, {ns: 'countries'})}</div>

          <div className={"team-logo-container home"}>
            <Image className="team-logo" src={`/images/dynamic/soccer/team/${homeId}.png`}/>
          </div>

          <div className={"score-container"}>
            <div
              className={"score-home"}>{matchStatus === "fixture" ? '-' : _.get(liveData, "matchDetails.scores.total.home", 0)}</div>
            <div className={"score-separator"}>:</div>
            <div
              className={"score-away"}>{matchStatus === "fixture" ? '-' : _.get(liveData, "matchDetails.scores.total.away", 0)}</div>
            <div
              className={`match-status ${matchStatus === 'playing' ? 'blink-me' : ''}`}>{statusString}</div>

            <div
              className={"attendance-info"}>
              <TeamOutlined/> {liveDataExtra?.attendance || '-'}
            </div>

            <div
              className={"date-info"}>{dateFormat(matchDate, lng === 'de' ? "dd.MM.yyyy" : "MM/dd/yyyy", {locale: lng === 'de' ? de : enUS})} {time}</div>

            {/*{matchInfo?.series?.name.replace('Group', t('Group', {keyPrefix: 'templates'})) && <div*/}
            {/*  className={"group-info"}>*/}
            {/*  {matchInfo?.series?.name.replace('Group', t('Group', {keyPrefix: 'templates'})) || ''}*/}
            {/*</div>}*/}
          </div>

          <div className={"team-logo-container away"}>
            <Image className="team-logo" src={`/images/dynamic/soccer/team/${awayId}.png`}/>
          </div>
          <div className={"away-name"}>{t(away?.name, {ns: 'countries'})}</div>
          {/*</div>*/}
        </div>
      })}</div>
    </div>
}


const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef()
  };


  const {animation, data, images: Images, apiSetup, mode, format} = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const {t, i18n} = useTranslation()
  const lng = i18n?.language;

  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({refs, data, animation, mode, id, format})
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`).get(0).addEventListener("loadedmetadata", function () {
        this.currentTime = 3;
        this.pause();
      }, false);
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);

  const templateData = _.get(data, 'data');

  const {options, fields, dynamic} = templateData;
  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);

  const headline = getContentById('headline', fields);
  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const goalsHome = _.get(dynamic, "goals.home");
  const goalsAway = _.get(dynamic, "goals.visitor");
  const eventData = _.get(dynamic, "game");
  const currentMatchday = _.get(dynamic, "currentMatchday");

  const currentRound = _.get(currentMatchday, "round");
  const groupStage = _.get(dynamic, "groupStage");
  const apiAssets = _.get(dynamic, "assets");

  // const teamDataStyles = _.get(teamData, "styles", {});
  // const playerData = getContentById('player', fields, "self");

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));
  const customBackground = getContentById('background', fields);
  // const backgroundMask = getContentById('defaultBackgroundMask', options);

  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;

  const currentEvent = _.get(data, 'event');

  const selectedTeamId = _.get(currentEvent, 'selectedTeamId');

  // const firstMatchDate = new Date(matchday?.[0]?.matchInfo?.localDate + 'T' + matchday?.[0]?.matchInfo?.localTime + 'Z');

  const targetGroup = findGroupByContestantId(groupStage, selectedTeamId)
  const groupId = targetGroup?.replace('Group ', '');

  return (
    <div id={id}
         className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''}`}>

      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true}/>


        <div className={"wc-content"}>
          <div className={"wc-bg-content"}></div>

          {/*<div className={'group-label'}>{t('Group', {keyPrefix: 'templates'})} {groupId}</div>*/}

          <div className={"qatar-logo-container"}><img className={"qatar-logo"} src={assets.qatar_logo}/></div>
          <div
            className={'main-title green-glow'}>{t('Group Phase')} {'-'} {t('Group', {keyPrefix: 'templates'})} {groupId}
            {/*<span>{dateFormat(firstMatchDate, lng === 'de' ? "dd.MM" : "MM/dd", {locale: lng === 'de' ? de : enUS})}</span>*/}
          </div>

          {/*<div className={"team-logo-container home"}>*/}
          {/*  <img src={parseMediaUrl(_.get(teamHome, 'url', null))}/>*/}
          {/*</div>*/}

          {/*Required. The status of the match - one of the following: Fixture | Playing | Played | Cancelled | Postponed | Suspended | Awarded*/}
          {/*• Fixture - The match is scheduled and yet to start*/}
          {/*• Playing - The game is live*/}
          {/*• Played - The game is played already*/}
          {/*• Cancelled - The game is cancelled due to some reason*/}
          {/*• Postponed - The game is postponed to another date*/}
          {/*• Suspended - The match is interurpted in between and suspended. It can be rescheduled to another date in future*/}
          {/*• Awarded - The match is awarded to one contestants with an associated result*/}
          <div className={"live-matchdays-container"}>
            {/*{renderAllByChunks({groupStage, t, selectedTeamId})}*/}


            {renderSingle({targetGroup, groupStage, t, selectedTeamId, lng})}
          </div>

        </div>

      </TemplateBlock>

    </div>);
};

const getMainBackground = (format, side) => {
  return _.get(assets, `circle_center`);
};

export default Index;

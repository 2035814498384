import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();

// .group-rows, .main-header, .qatar-logo, .group-label

  // $(`#${id} .qatar-logo, #${id} .main-headline, #${id} .pitch-container, #${id} .player`).css("opacity", 0);

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 400
  });

  anime({
    targets: [`#${id} .score`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 500
  });

  anime({
    targets: [`#${id} .team-logo-container`],
    duration: 1000,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 600
  });

  anime({
    targets: [$(`#${id} .group-row`).get()],
    duration: 1400,
    easing: "easeOutQuint",
    translateX: [2000, 0],
    opacity: [1, 1],
    delay: anime.stagger(250)
  });

  return {
    timeline: [],
    timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();
  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200
  });

  anime({
    targets: [`#${id} .player-home`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 600
  });

  anime({
    targets: [`#${id} .teams-score`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 700
  });

  anime({
    targets: [`#${id} .team-stats`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [2000, 0],
    opacity: [1, 1],
    delay: 800
  });

  anime({
    targets: [`#${id} .main-headline`],
    duration: 1000,
    easing: "easeOutQuint",
    // translateX: [2000, 0],
    opacity: [0, 1],
    delay: 900
  });

  return {
    timeline: [],
    timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

import {useTranslation} from "react-i18next";
import {useGlobalStore} from "../../../../stores/globalBackendStore";
import React, {useEffect, useState} from "react";
import {getGlobalMatchdays} from "../../../../services/backend/api.service";
import _ from "lodash";
import {SettingOutlined} from "@ant-design/icons";
import Loader from "../Loader";

import {templates} from "../../../frontend/pages/Template/index";
import preloadImages from "../../functions/preloadImages";
import getImagesToPreload from "../../functions/getImagesToPreload";
import {useGlobalFrontendStore} from "../../../../stores/globalFrontendStore";

import "./styles.scss";

const Index = (props) => {
  const {previewFormat, templateData, imagesReadyHandler} = props;
  const {t} = useTranslation();
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const apiSetup = useGlobalStore(state => state.apiSetup);

  const templateName = templateData.template_name;
  const templateId = templateData.uuid;

  const TemplateComponent = templates[templateName];
  const Images = getImagesToPreload(_.get(templateData, 'data', []));

  useEffect( () => {
    const asyncCall = async () => {
      const promises = preloadImages(Images);
      await Promise.all([promises]);
      setImagesLoaded(true);
    }
    asyncCall()
  }, []);

  useEffect(() => {
    if (imagesLoaded) {
      if (imagesReadyHandler) {
        imagesReadyHandler();
      }
    }
  }, [imagesLoaded]);

  return <div className={`comp-template-preview f-${previewFormat}`}>
    <div className={"template-container"}>
      {templateData && <TemplateComponent format={previewFormat} images={Images} apiSetup={apiSetup} mode={"animation"}
                                          data={templateData} {...props} />}
    </div>
  </div>;
};

export default Index;

import React from "react";
import {Route, Switch, Redirect, Link, useParams, useLocation, useRouteMatch} from "react-router-dom";
import NotFoundPage from "../components/shared/pages/NotFound";
import queryString from "query-string";
import _ from "lodash";
import {Menu} from "antd";
import {UploadOutlined, UserOutlined, VideoCameraOutlined, MailOutlined, SettingOutlined, SettingFilled} from "@ant-design/icons";

import PagesContainerFrontend from "../components/frontend/pages/Index";
import PagesContainerBackend from "../components/backend/Index";
import Login from "../components/backend/pages/Login";

const {SubMenu} = Menu;


const Home = () => <h1>Home</h1>;

/**
 * Render a nested hierarchy of route configs with unknown depth/breadth
 */
export const RoutesMenu = (props) => {

  const generateSubmenu = (submenu) => {
    const MenuPoints = submenu?.map((item, key) => {
      return {
        key: "sub_" + item.path,
        icon: <SettingFilled/>,
        label: <Link to={`${props.path}${item.path}`}>
          {item.label}
        </Link>
      }
    });

    return MenuPoints;
  };


  /**
   * Render a single route as a list item link to the config's pathname
   */
  function singleRoute(route) {
    const isArray = Array.isArray(route.path);
    const fixPath = isArray ? route.path[0] : route.path;

    return {
        key: "single_" + route.path,
        icon: <SettingOutlined/>,
        label: <Link to={`${props.path}${fixPath}`}>
          {route.label || route.key}
          {/*({`${props.path}${fixPath}`})*/}
        </Link>,
        children: generateSubmenu(route.routes)
      }
  }

  const menuItems = [];

  props.routes && props.routes.map((route, index) => {
    if (route.menuHide) {
      return null;
    }

    // if this route has sub-routes, then show the ROOT as a list item and recursively render a nested list of route links

    if (route.routes) {
      menuItems.push({
          key: route.path,
          icon: <SettingOutlined/>,
          label: route.label,
          children: generateSubmenu(route.routes)
        }
        // <SubMenu key={route.path} icon={<SettingOutlined/>} title={route.label}>
        //   {generateSubmenu(route.routes)}
        // </SubMenu>
      );
    } else {
      // no nested routes, so just render a single route
      menuItems.push(singleRoute(route))
    }
  })

  // loop through the array of routes and generate an unordered list
  return (<Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} items={menuItems}/>);
};

const routesRenderer = (routes, base, type) => {
  return routes.map((route, index) => {
      const isArray = Array.isArray(route.path);
      // const fixPath = isArray ? route.path[0] : route.path;
      const finalPath = route.path ? base + route.path : "";

      if (route.routes) {
        return routesRenderer(route.routes, base, type);
      } else {
        return <Route key={finalPath + index} path={finalPath} exact={route.exact} render={(props) => {
          const extraProps = {
            _searchParams: queryString.parse(props.location.search),
            _urlParams: _.get(props, "match.params", {})
          };

          const ReactComponent = route.component;

          if (type === "backend") {
            return <PagesContainerBackend {...extraProps}>
              <ReactComponent {...props} {...extraProps}/>
            </PagesContainerBackend>
          } else if (type === "frontend") {
            return <PagesContainerFrontend {...extraProps}>
              <ReactComponent {...props} {...extraProps}/>
            </PagesContainerFrontend>
          }

        }}/>
      }
    }
  )
};

export const RoutesRender = (mainProps) => {
  const base = mainProps.path;
  const type = mainProps.type;

  return (
    <Switch>
      {routesRenderer(mainProps.routes, base, type)}
      <Route path={""} render={(props) => {
        return <NotFoundPage {...props} />
      }}/>
    </Switch>
  );
};

import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();
  $(`#${id} .players-container .player-image-container`).css("opacity", 0);
  $(`#${id} .player-group-line`).css("opacity", 0);
  $(`#${id} .player-info`).css("opacity", 0);
  $(`#${id} .player-image, #${id} .jersey`).css("opacity", 0);
  $(`#${id} .team-logo-container`).css("opacity", 0);


  const params = {};

  if (format === "1920x1080") {
    params.headlineTop = 380
    params.headlineScale = 1.5
  } else if (format === "1080x1920") {
    params.headlineTop = 650
    params.headlineScale = 1.3
  } else if (format === "1200x1500") {
    params.headlineTop = 600
    params.headlineScale = 1.1
  }

  anime.timeline({
    targets: `#${id} .headline`,
    easing: 'easeInOutQuint',
    delay: 100,
  })
    .add({
      scale: [0, params.headlineScale],
      duration: 900,
      opacity: [1, 1],
    })
    .add({
      scale: [params.headlineScale, 1.0],
      top: [params.headlineTop, $(`#${id} .headline`).css('top')],
      duration: 600,
      delay: 100,
      opacity: [1, 1],
    });

  anime.timeline({
    targets: `#${id} .jersey`,
    easing: 'easeInOutQuint',
    delay: anime.stagger(150, {start: 1600}), // 200ms delay between each elemen
  })
    .add({
      scale: [0, 1],
      duration: 1000,
      opacity: [1, 1],
    })

  anime.timeline({
    targets: `#${id} .player-image`,
    easing: 'easeInOutQuint',
    delay: anime.stagger(150, {start: 1500}), // 200ms delay between each elemen
  })
    .add({
      translateY: [250, 0],
      duration: 1000,
      opacity: [1, 1],
    })


  anime({
    targets: `#${id} .player-group-line`,
    keyframes: [
      {clipPath: 'inset(100% 0 0 0)', duration: 0},
      {clipPath: 'inset(0% 0 0 0)', duration: 800},
    ],
    easing: 'easeInOutQuart',
    opacity: [1, 1],
    delay: anime.stagger(300, {start: 1200}), // 200ms delay between each elemen
  });

  anime({
    targets: `#${id} .player-info`,
    keyframes: [
      {translateY: [100, 0], duration: 800}],
    easing: 'easeOutQuint',
    opacity: [1, 1],
    delay: anime.stagger(100, {start: 1500}), // 200ms delay between each elemen
  });


  if (format === "1920x1080") {
    anime({
      targets: [`#${id} .team-logo-container.home, #${id} .team-logo-container.away`],
      duration: 800,
      easing: "easeOutQuint",
      opacity: [1, 1],
      translateX: [600, 0],
      delay: 700,
    });
  } else if (format === "1080x1920" || format === "1200x1500") {


    if (format === "1200x1500") {
      anime({
        targets: [`#${id} .teams-block`],
        duration: 800,
        easing: "easeOutQuint",
        opacity: [1, 1],
        translateX: [-300, -300],
        delay: 300,
      });

      anime({
        targets: [`#${id} .teams-block`],
        duration: 1000,
        easing: "easeOutQuint",
        opacity: [1, 1],
        translateX: [-300, 0],
        delay: 1200,
      });
    } else {
      anime({
        targets: [`#${id} .teams-block`],
        duration: 800,
        easing: "easeOutQuint",
        opacity: [1, 1],
        top: [-300, -300],
        delay: 300,
      });

      anime({
        targets: [`#${id} .teams-block`],
        duration: 1000,
        easing: "easeOutQuint",
        opacity: [1, 1],
        top: [-300, 0],
        delay: 1200,
      });
    }


    anime({
      targets: [`#${id} .team-vs`],
      duration: 800,
      easing: "easeOutQuint",
      opacity: [1, 1],
      scale: [0, 1],
      delay: 500,
    });

    anime({
      targets: [`#${id} .team-logo-container.home`],
      duration: 800,
      easing: "easeOutQuint",
      opacity: [1, 1],
      translateX: [-800, 0],
      delay: 600,
    });

    anime({
      targets: [`#${id} .team-logo-container.away`],
      duration: 800,
      easing: "easeOutQuint",
      opacity: [1, 1],
      translateX: [800, 0],
      delay: 600,
    });
  }


  // if (format === "1920x1080") {
  //   teamLogoParams = {
  //     top: 113,
  //     left: 175,
  //     size: 135
  //   };
  // } else if (format === "1080x1920") {
  //   teamLogoParams = {
  //     top: 140,
  //     left: 770,
  //     size: 155,
  //   }
  // } else if (format === "1200x1500") {
  //   teamLogoParams = {
  //     top: 30,
  //     left: 920,
  //     size: 155,
  //   };
  // }

  const timeout1 = setTimeout(() => {


    // anime({
    //   targets: [$(`#${id} .players-container .side-separator`).toArray().reverse(), ],
    //   duration: 700,
    //   easing: "easeOutQuint",
    //   translateX: [-2000, 0],
    //   opacity: [1, 1],
    //   // rotateZ: [22, 22],
    //   delay: anime.stagger(80)
    // });


    // anime({
    //   targets: [$(`#${id} .players-container-splitted.line-de .player-image-container`).toArray(),],
    //   duration: 1000,
    //   easing: "easeOutQuint",
    //   translateX: [-2000, 0],
    //   opacity: [1, 1],
    //   // rotateZ: [22, 22],
    //   delay: anime.stagger(80)
    // });
    //
    // anime({
    //   targets: [$(`#${id} .players-container-splitted.line-de .player-label`).toArray()],
    //   duration: 1000,
    //   easing: "easeOutQuint",
    //   translateX: [-2000, 0],
    //   opacity: [1, 1],
    //   // rotateZ: [22, 22],
    //   delay: anime.stagger(80)
    // });
    //
    //
    // anime({
    //   targets: [$(`#${id} .players-container-splitted .side-separator`).toArray(),],
    //   duration: 1000,
    //   easing: "easeOutQuint",
    //   translateX: [-2000, 0],
    //   opacity: [1, 1],
    //   // rotateZ: [22, 22],
    //   delay: anime.stagger(80)
    // });


  }, 1200);

  const timeout2 = setTimeout(() => {
    //
    // anime({
    //   targets: [$(`#${id} .players-container-splitted.line-fo .player-image-container`).toArray(),],
    //   duration: 1000,
    //   easing: "easeOutQuint",
    //   translateX: [-2000, 0],
    //   opacity: [1, 1],
    //   // rotateZ: [22, 22],
    //   delay: anime.stagger(80)
    // });
    //
    // anime({
    //   targets: [$(`#${id} .players-container-splitted.line-fo .player-label`).toArray()],
    //   duration: 1000,
    //   easing: "easeOutQuint",
    //   translateX: [-2000, 0],
    //   opacity: [1, 1],
    //   // rotateZ: [22, 22],
    //   delay: anime.stagger(80)
    // });
  }, 2300);

  const timeout3 = setTimeout(() => {
    // anime({
    //   targets: [$(`#${id} .players-container-splitted.line-gk .player-image-container`).toArray(),],
    //   duration: 1000,
    //   easing: "easeOutQuint",
    //   translateX: [-2000, 0],
    //   opacity: [1, 1],
    //   // rotateZ: [22, 22],
    //   delay: anime.stagger(80)
    // });
    //
    // anime({
    //   targets: [$(`#${id} .players-container-splitted.line-gk .player-label`).toArray()],
    //   duration: 1000,
    //   easing: "easeOutQuint",
    //   translateX: [-2000, 0],
    //   opacity: [1, 1],
    //   // rotateZ: [22, 22],
    //   delay: anime.stagger(80)
    // });
  }, 3600);


  timeouts.push(timeout1);
  timeouts.push(timeout2);
  timeouts.push(timeout3);

  return {
    timeline: timeline,
    timeouts: timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

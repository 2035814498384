import {clearTimeouts, engine as anime} from "./index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {refs, id} = props;
  const {player, team} = refs;

  const timeline = anime.timeline();

  timeline
    .add({
      targets: [`#${id} .team-head-block, #${id} .headline, #${id} .factsListBg`],
      duration: 800,
      easing: "easeInOutSine",
      opacity: [0, 1],
      translateX: [2000, 0],
      delay: anime.stagger(150)
    })
    .add({
      targets: [`#${id} .factsList > p`],
      duration: 800,
      easing: "easeInOutSine",
      opacity: [0, 1],
      translateX: [2000, 0],
      delay: anime.stagger(150)
    }, 700)
    .add({
      targets: [`#${id} .players`],
      duration: 500,
      easing: "easeInOutSine",
      opacity: [0, 1],
      translateX: [-2000, 0],
      delay: anime.stagger(200)
    }, 250)
    .add({
      targets: [`#${id} .players`],
      duration: 600,
      easing: "easeInOutSine",
      scale: [1, 1.25, 1]
    });

  // setTimeout(()=> {
  //     timeline.reverse();
  //     timeline.restart();
  // }, 3000)


  return {
    timeline: timeline,
    timeouts: timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

import React, {useEffect, useRef, useState} from 'react';
import {Form, Drawer, Card, notification, Select} from "antd";
import _ from "lodash";

import {useTranslation} from "react-i18next";
import {useGlobalStore} from "../../../../stores/globalBackendStore";
import {InstagramOutlined, CloseOutlined, CheckOutlined} from "@ant-design/icons";

import {Input, Button as CheckButton, Checkbox, Button, Layout, Alert, Switch} from 'antd';
import AuthService from "../../../../services/auth.service";
import backendCfg from "../../../../config/backend";
import Image from "../../../shared/components/Image";

const {Meta} = Card;
const {Option} = Select;
const {TextArea} = Input;

const RenderSocialMediaIcon = (props) => {
  const {type} = props;

  const Icon = () => {
    let output = null;
    switch (type) {
      case "instagram":
        output = <InstagramOutlined/>;
        break;
    }

    return output
  };

  return <Icon/>
};

export default function (props) {
  const apiSetup = useGlobalStore(state => state.apiSetup);

  const {setDrawerVisible, visible, data} = props;
  const {t} = useTranslation();

  const {type, format, template} = data || {};

  const [form] = Form.useForm();

  const onClose = () => {
    setDrawerVisible(false);
  };

  const validateMessages = {
    required: `'\${label}' ${t("is required.")}`
    // ...
  };

  const onFinish = (values) => {
    // const instagramPublishUrl = "graph.facebook.com/17841400008460056/media\n" +
    //     "  ?media_type=VIDEO\n" +
    //     "  &video_url=https//www.example.com/videos/hungry-fonzes.mov\n" +
    //     "  &caption=%23Heyyyyyyyy!";
    // console.log("values", values);

    notification["success"]({
      message: t('Success'),
      description: t("Post has been successfully shared on Instagram."),
      placement: "topRight"
    });
    setDrawerVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  const handleSourceTypeChange = (selected) => {
    // console.log('selected:', selected);
    setSelectedSourceType(selected);
  };
  const templateSetup = _.find(apiSetup.templates, {name: _.get(template, "template_name")});
  const availableFormats = _.get(templateSetup, "formats", []);

  const [selectedFormat, setSelectedFormat] = useState();
  const [selectedSourceType, setSelectedSourceType] = useState("screenshot");
  const [sourceUrlValue, setSourceUrlValue] = useState();

  const videoUrl = _.get(template, `data.videos.${selectedFormat}.file`);
  const imageUrl = `${backendCfg.screenshotsUrl
    .replace("[[TEMPLATE]]", _.get(template, "template_name"))
    .replace("[[TYPE]]", "download_" + selectedFormat)
    .replace("[[UUID]]", _.get(template, "uuid"))
  }`;

  useEffect(() => {
    if (data) {
      setSelectedFormat(availableFormats[0]);
    }
  }, [data]);

  useEffect(() => {
    if (selectedSourceType && selectedFormat) {
      if (selectedSourceType === "screenshot") {
        form.setFieldsValue({"sourceurl": imageUrl})
      } else if (selectedSourceType === "video") {
        form.setFieldsValue({"sourceurl": videoUrl})
      }
    }
  }, [selectedSourceType, selectedFormat]);

  return (
    <div className={"comp-TemplateShareDrawer"}>
      <Drawer
        rootClassName={"comp-TemplateShareDrawer-container"}
        title={t("Sharing center")}
        placement={"top"}
        closable={true}
        onClose={onClose}
        open={props.visible}
        height={"700px"}
      >
        {(template && selectedFormat) && <div>
          <div className={"social-media-icon"}>
            <RenderSocialMediaIcon type={type}/> {_.upperFirst(type)}
          </div>

          <div className={"share-form"}>
            <div className={"share-form-left"}>
              <Form layout={"vertical"}
                    validateMessages={validateMessages}
                    initialValues={{
                      sourceformat: selectedFormat,
                      sourcetype: selectedSourceType,
                      posttype: "feed"
                    }} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}
              >
                <Form.Item
                  name="hashtags"
                  label={t("Post hashtags")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TextArea/>
                </Form.Item>


                <Form.Item
                  name="postcontent"
                  label={t("Post description")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TextArea/>
                </Form.Item>

                <Form.Item
                  name="posttype"
                  label={t("Post type")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select>
                    <Option value="feed">Feed</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="sourcetype"
                  label={t("Source type")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select onSelect={handleSourceTypeChange}>
                    <Option value="screenshot">{t("Screenshot")}</Option>
                    <Option value="video">{t("Video")}</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="sourceformat"
                  label={t("Source format")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select>
                    {availableFormats.map((thisFormat) => {
                      return <Option key={"source-" + thisFormat}
                                     value={thisFormat}>{t(thisFormat)}</Option>;
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="sourceurl"
                  hidden={true}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                  name="includeFacebook"
                  label={t("Post on facebook?")}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                  />
                </Form.Item>


                <Form.Item className={"submit-button"}>
                  <Button type="primary" htmlType="submit">
                    Publish
                  </Button>
                </Form.Item>
              </Form>
            </div>

            <div className={"share-form-right"}>
              {selectedSourceType === "screenshot" ? (
                <Image src={imageUrl}/>
              ) : (<video controls muted>
                <source src={videoUrl}/>
              </video>)}

            </div>

          </div>


        </div>}


      </Drawer>
    </div>
  );
}

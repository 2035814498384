import React, {useState, Suspense} from 'react';
import _ from "lodash";
import {Switch, BrowserRouter, Route, Redirect, useRouteMatch, useParams, useHistory} from "react-router-dom";
import {RoutesMenu, RoutesRender} from "../routes/Router";
import {BACKEND_ROUTES} from "../routes/backend";
import CheckLoginComp from "../components/shared/components/CheckLogin/Index";
import MediaLibrary from "../components/shared/components/MediaLibrary/Index";

import Config from '../config/backend';

import MatchdaySelector from "../components/shared/components/MatchdaySelector/Index";

import {withTranslation} from 'react-i18next';

import HeaderLogo from "../../src/assets/logo/vigital_solutions.png"

import {getApiSetup, getGlobalMatchday} from "../services/backend/api.service"

import {MdLiveTv} from "react-icons/md"

//
// const Home = () => <p>Home</p>;
// const Dashboard = () => <p>Dashboard</p>;
// const Login = () => <p>Login</p>;
// const Logout = () => <p>logout</p>;
// const Profile = () => <p>profile</p>;
//
//
// export default function AppLayout(props) {
//     const { url, path } = useRouteMatch();
//     const { id } = useParams();
//
//     console.log('##id', id)
//
//     return (
//         <div>
//             <h1>Backend Menu</h1>
//             <RoutesMenu path={path} routes={BACKEND_ROUTES}/>
//
//             <h1>App Layout</h1>
//             <RoutesRender path={path} routes={BACKEND_ROUTES} {...props}/>
//         </div>
//     );
// }

import {Layout, Menu, Tooltip, Modal, Dropdown, notification} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  SettingFilled,
  FolderViewOutlined,
  DownOutlined
} from '@ant-design/icons';

import GlobalSettingsDrawer from './backend/components/GlobalSettingsDrawer'
import LiveWidgetDrawer from './backend/components/LiveWidgetDrawer'

import {subscribeWithSelector} from 'zustand/middleware';

import {useGlobalStore} from "../stores/globalBackendStore";
import CreateTemplateDrawer from "../components/backend/pages/Dashboard/CreateTemplateDrawer";
import AuthService from "../services/auth.service";
import {InputMedia} from "../components/shared/components/Forms/Fields/types/_FormMedia";

const {Header, Sider, Content, Footer} = Layout;
const {SubMenu} = Menu;

const SlackChatBox = React.lazy(() => import(/* webpackChunkName: "SlackChat" */"../components/shared/components/SlackChat/IndexSource"));

class SiderDemo extends React.Component {
  state = {
    collapsed: false,
    openMediaLibrary: false,
    globalSettingsVisible: false,
    liveWidgetVisible: false
  };

  componentDidMount() {
    const {t, i18n} = this.props;
    require('./backend/main.scss');

    //get selectedMatchday
    const setSelectedMatchday = useGlobalStore.getState().setSelectedMatchday;
    const setApiSetup = useGlobalStore.getState().setApiSetup;
    const setTimelineAutomationSetup = useGlobalStore.getState().setTimelineAutomationSetup;

    getGlobalMatchday().then((globalMatchday) => {
      const apiData = _.get(globalMatchday, "data.match", null);

      setSelectedMatchday(apiData)
    });

    getApiSetup().then((apiSetup) => {
      const apiData = _.get(apiSetup, "data.data", null);
      setApiSetup(apiData);
      this.setState({
        apiSetup: apiData
      });
    }).catch((err) => {
      console.error("apiSetup err", err);
    });

    getApiSetup(3).then((apiTimelineAutomationSetup) => {
      const apiData = _.get(apiTimelineAutomationSetup, "data.data", null);
      setTimelineAutomationSetup(apiData);
      // this.setState({
      //   apiTimelineAutomationSetup: apiData
      // });
    }).catch((err) => {
      console.error("apiTimelineAutomationSetup err", err);
    });

    const listenerGlobalApiSetup = subscribeWithSelector((globalState, previousGlobalState) => {
      this.setState({
        apiSetup: globalState
      });
    }, state => state.apiSetup);
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    }, () => {
      const setGlobalMenuCollapse = useGlobalStore.getState().setGlobalMenuCollapse;
      setGlobalMenuCollapse(this.state.collapsed);
    });
  };

  // openModal = () => {
  //     this.setState({
  //         openMediaLibrary: !this.state.openMediaLibrary
  //     });
  // }

  render() {
    const {url, path, params} = this.props.match;
    const {location} = this.props;
    const {id} = params;

    const {t, i18n} = this.props;

    // const apiSetup = useGlobalStore.getState().apiSetup;

    const design = _.get(this.state.apiSetup, "design");

    const currentUser = AuthService.getCurrentUser();
    const setLang = useGlobalStore.getState().setLang;

    // const [globalSettingsVisible, setGlobalSettingsVisible] = useState(false);
    // const [liveWidgetVisible, setLiveWidgetVisible] = useState(false);

    return (
      <CheckLoginComp {...this.props}>
        <Layout className={"ant-layout-main-element"}>
          <Sider className={"main-sidenav"} trigger={null} collapsedWidth={0} collapsible
                 width={'220px'}
                 collapsed={this.state.collapsed}>
            <div className="logo"><span>{Config.appName}</span></div>
            <RoutesMenu path={path} routes={BACKEND_ROUTES}/>
          </Sider>
          <div className={"backend-main-background-container"}>
            <div style={{
              // backgroundImage: "radial-gradient(circle, #ffffff, #004289)"
              backgroundImage: `url(${Config.apiUrl}${_.get(design, "backendBackground.url")})`
            }
            } className={"backend-main-background"}></div>
            <div className={"bg-layer-mask"}></div>
          </div>
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{padding: 0}}>
              <div className={"main-header-flex"}>
                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  onClick: this.toggle,
                })}
                <a href={"https://vigitalsolutions.com"} target={"_blank"} rel="noreferrer"><img
                  className={"header-logo"} src={HeaderLogo}/></a>

                <MatchdaySelector/>

                <div className={"header-icons"}>
                  <div className={"header-setup"}>
                    <Tooltip title={t("Media library")}>
                      <InputMedia
                        skipForm={true}
                        item={{
                          value: null
                        }}
                      />

                    </Tooltip>
                    <Tooltip title={t("Match center")}>
                      <MdLiveTv className={"anticon link"} onClick={(e) => {
                        this.setState({
                          liveWidgetVisible: true
                        });
                      }} style={{fontSize: '18px'}}/>
                    </Tooltip>

                    {/*<Tooltip title={t("Global settings")}>*/}
                    {/*    <SettingFilled onClick={(e) => {*/}
                    {/*        this.setState({*/}
                    {/*            globalSettingsVisible: true*/}
                    {/*        });*/}
                    {/*    }} style={{fontSize: '18px'}}/>*/}
                    {/*</Tooltip>*/}

                    <GlobalSettingsDrawer visible={this.state.globalSettingsVisible}
                                          onClose={(e) => {
                                            this.setState({
                                              globalSettingsVisible: false
                                            });
                                          }}/>
                    <LiveWidgetDrawer visible={this.state.liveWidgetVisible} onClose={(e) => {
                      this.setState({
                        liveWidgetVisible: false
                      });
                    }}/>
                  </div>


                  <div className={"current-user"}>
                    <Dropdown trigger={['click']} overlay={<Menu>
                      {/*<Menu.Item>Global settings</Menu.Item>*/}

                      <Menu.ItemGroup title={t("General")}>
                        <SubMenu title="Language">
                          <Menu.Item key={"user-menu-lang-en"} className={"lang-switch"}><span
                            className={i18n.language === "en" ? "active" : ""}
                            onClick={() => {

                              setLang("en");
                              i18n.changeLanguage("en")
                            }}>Englisch</span></Menu.Item>
                          <Menu.Item key={"user-menu-lang-de"} className={"lang-switch"}><span
                            className={i18n.language === "de" ? "active" : ""}
                            onClick={() => {
                              setLang("de");
                              i18n.changeLanguage("de")
                            }}>German</span></Menu.Item>
                        </SubMenu>
                      </Menu.ItemGroup>
                      <Menu.ItemGroup title={t("Account")}>
                        <Menu.Item onClick={() => {
                          AuthService.logout({history: this.props.history});
                        }}>Logout</Menu.Item>
                      </Menu.ItemGroup>
                    </Menu>}>
                      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        {_.get(currentUser, "user.name")} <DownOutlined/>
                      </a>
                    </Dropdown>
                  </div>
                  <div className={"lang-switcher"}>
                    UI language: <span>{_.toUpper(i18n.language)}</span>
                      {/*<span className={i18n.language === "en" ? "active" : ""} onClick={() => {*/}
                      {/*  i18n.changeLanguage("en")*/}
                      {/*}}>EN</span> | <span className={i18n.language === "de" ? "active" : ""}*/}
                      {/*                     onClick={() => {*/}
                      {/*                       i18n.changeLanguage("de")*/}
                      {/*                     }}>DE</span>*/}
                  </div>
                </div>
              </div>

            </Header>
            <Content
              className="site-layout-background main-content"
              style={{
                padding: 24,
                minHeight: 280
              }}
            >

              <RoutesRender path={path} routes={BACKEND_ROUTES} {...this.props} type={"backend"}/>
            </Content>


            {/*<Suspense fallback={<div>Chat is loading...</div>}>*/}
            {/*    <SlackChatBox username={_.get(currentUser, "user.name")}/>*/}
            {/*</Suspense>*/}

            {Config.chatbox.active ?
              <Suspense fallback={<div>Chat is loading...</div>}>
                <div className={"slack-chat-container"}>
                  <SlackChatBox username={_.get(currentUser, "user.name")}/>
                </div>
              </Suspense> : null
            }

            {/*<Footer className={"main-footer"} style={{textAlign: 'center'}}>{t(`© 2021 Vigital Solutions. All rights reserved.`)}</Footer>*/}
          </Layout>
        </Layout>
      </CheckLoginComp>
    );
  }
}

export default withTranslation()(SiderDemo);

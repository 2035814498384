import NotFoundPage from "../components/shared/pages/NotFound";
import TimelinePages from "../components/frontend/pages/Timeline";
import TimelinePagesSoccer from "../components/frontend/pages/Timeline/soccer";

import TemplatePages from "../components/frontend/pages/Template";


//animation={true} == means we want to add class animation at specific template container
export const FRONTEND_ROUTES = [
  {
    path: `/`, exact: true, component: props => {
      return <h3>FRONTEND HOME</h3>
    }
  },
  {
    path: "/template/:template/:id", exact: true, component: props => {
      return <TemplatePages {...props} mode={"animation"} animation={true}/>
    }
  },
  {
    path: "/screenshot/:template/:id", exact: true, component: props => {
      return <TemplatePages {...props} mode={"screenshot"} animation={false}/>
    }
  },
  {
    path: "/video/timeline/dynamic/:id", exact: true, component: props => {
      return <TimelinePages {...props} mode={"video"} animation={true}/>
    }
  },
  {
    path: "/video/timeline/:id", exact: true, component: props => {
      return <TimelinePages {...props} mode={"video"} animation={true}/>
    }
  },
  {
    path: "/video/:template/:id", exact: true, component: props => {
      return <TemplatePages {...props} mode={"video"} animation={true}/>
    }
  },
  {
    path: "/timeline/:id", exact: true, component: props => {
      return <TimelinePages {...props} mode={"timeline"} animation={true}/>
    }
  },
  {
    path: "/timeline/dynamic/soccer/:cid/:tid/:mid?", exact: true, component: props => {
      console.log('##props', props);
      return <TimelinePagesSoccer {...props} mode={"timeline"} animation={true}/>
    }
  }
];

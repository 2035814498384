import React from 'react';
import {Divider, Select, Button} from 'antd';
import {useTranslation} from 'react-i18next';

import {FileAddOutlined} from '@ant-design/icons';
import _ from "lodash";

const {Option} = Select;

// function onChange(value) {
//     console.log(`selected ${value}`);
// }

function onBlur() {
  console.log('blur');
}

function onFocus() {
  // console.log('focus');
}

function onSearch(val) {
  // console.log('search:', val);
}

const Index = (props) => {
  const {groups, onChange, initialActiveGroup, addNewElement} = props;

  const [t, i18n] = useTranslation();

  const AddNewButton = props.addNewElement;

  const defaultValue = initialActiveGroup || _.get(groups, "[0].name", "_default");
  const groupExist = _.has(groups, defaultValue);

  const generateGroups = () => {
    return <Select
      defaultActiveFirstOption={true}
      defaultValue={groupExist ? defaultValue : "_default"}
      showSearch
      style={{width: 200}}
      placeholder="Select group"
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {_.map(groups, (group, groupKey) => {
        const {id, name, label} = group;
        return <Option key={name + id} value={name} id={id}>{label}</Option>
      })
      }
    </Select>
  };

  return <React.Fragment>
    {generateGroups()}
    {" "}
    <AddNewButton/>
    <Divider className={"media-library-divider"}></Divider>

  </React.Fragment>
};

export default Index;

import _ from "lodash";

const assets = {
  circle_center: require("../../../assets/wc/videos/circle_center.webm"),
  qatar_logo: require("../../../assets/wc/images/sport_world_logo.png"),
  pitch: require("../../../assets/wc/images/pitch.png"),
  trikot: require("../../../assets/wc/images/trikot.png")
};

export default assets;

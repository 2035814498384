import _ from "lodash";

const assets = {
  circle_center: require("../../../assets/wc/videos/circle_center.webm"),
  qatar_logo: require("../../../assets/wc/images/sport_world_logo.png"),
  trikot_empty: require("../../../assets/wc/images/trikot_empty.png"),
  poweredBy: require("../../../assets/wc/images/powered_by_samsung.png")
};

export default assets;

import React, {useState, useEffect, useLayoutEffect} from "react";

import interact from 'interactjs'

import {FaExpandArrowsAlt} from 'react-icons/fa';

import {Menu, Dropdown, Divider, Button, Modal, Form} from 'antd';
import {MenuOutlined, AppstoreOutlined, CheckOutlined, FullscreenOutlined} from '@ant-design/icons';

import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {asyncTimeout} from "../../../shared/functions/timeout";

import {useTranslation} from 'react-i18next';
import {useGlobalStore} from '../../../../stores/globalBackendStore';

import TemplatePreview from '../../../shared/components/TemplatePreview/Index';
import TemplateEditForm from './TemplateEditForm';
import TemplateFormatSelector from './TemplateFormatSelector';

import {getElementsToInit, initInteract, resetMoveResize, unmountInteract} from '../../../shared/functions/wysiwyg'

import _ from "lodash";

const {SubMenu} = Menu;

const TemplateEditPopup = (props) => {
  const {t} = useTranslation();
  const {visible, setVisible, template, initialTemplateData, setReloadTemplatesList} = props;

  const [form] = Form.useForm();

  const [buttonLoading, setButtonLoading] = useState(false);
  const selectedMatchday = useGlobalStore(state => state.selectedMatchday);

  const apiSetup = useGlobalStore(state => state.apiSetup);
  const templateSetup = _.find(apiSetup.templates, {name: template.template_name});
  const availableFormats = _.get(templateSetup, "formats", []);

  const [currentTemplateData, setCurrentTemplateData] = useState(initialTemplateData);
  const [newStylingData, setNewStylingData] = useState({});
  const [editFormResponseData, setEditFormResponseData] = useState({});
  const [animation, setAnimation] = useState(false);
  const [mode, setMode] = useState("animation");
  const [previewReady, setPreviewReady] = useState(false);
  const [extraEventsInit, setExtraEventsInit] = useState(false);

  const [previewFormat, setPreviewFormat] = useState(availableFormats[0]);

  const fullScreenHandle = useFullScreenHandle();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    // setButtonLoading(true);
    form.submit();
  };

  const handleCancel = () => {
    setVisible(false);
    handleResetData();
  };

  const handleResetData = () => {
    const copy = _.cloneDeep(initialTemplateData);
    resetMoveResize({currentTemplateData, initialTemplateData});
    setNewStylingData({});
    setCurrentTemplateData(copy);
  };

  const handleRestartAnimation = (e) => {
    setAnimation(false);
    setTimeout(() => {
      setAnimation(true);
    }, 100)
  };

  const handleAnimationOn = (e) => {
    setAnimation(!animation);
  };

  useEffect(() => {
    setCurrentTemplateData(initialTemplateData);
  }, [previewFormat]);

  useEffect(() => {
    // console.log("editFormResponseData", editFormResponseData);
    // await asyncTimeout(1000);

    if (!_.isEmpty(editFormResponseData)) {
      setReloadTemplatesList({
        ...editFormResponseData,
        reload: true
      });
    }
  }, [editFormResponseData]);

  // WYSIWYG EXTRA ACTIONS
  useLayoutEffect(() => {
    if (visible) {
      setTimeout(() => {
        // if (!extraEventsInit) {
        //     initMoveResize({currentTemplateData, newStylingData, setNewStylingData, previewFormat});
        //     setExtraEventsInit(true);
        // }
        // setAnimation(true);
        setPreviewReady(true);

        initInteract(currentTemplateData, newStylingData, setNewStylingData, previewFormat);
      }, 500)
    } else {
      unmountInteract(currentTemplateData);
      setPreviewReady(false);
      setAnimation(false);

    }


    return () => {
      setPreviewReady(false);
      setAnimation(false);
      unmountInteract(currentTemplateData);
      // handleResetData();
      // setCurrentTemplateData(null);
    }

  }, [visible]);

  useEffect(() => {
    if (visible) {
      const fields = _.get(currentTemplateData, "data.fields");
      const initialStylingData = {};
      fields.forEach((item, index) => {
        if (item.dragResize || item.drag) {
          if (!_.isEmpty(item.styles)) {
            const stylesFormat = _.get(item, `styles`, null);

            if (stylesFormat) {
              _.set(initialStylingData, `${item.id}`, item.styles);
            }
          }
        }
      });

      setNewStylingData(initialStylingData);

      if (previewFormat) {
        initInteract(currentTemplateData, newStylingData, setNewStylingData, previewFormat);
      }

    }

    return () => {
      unmountInteract(currentTemplateData);
    }

  }, [previewFormat, visible]);

  // const liveCreation = _.get(templateSetup, "liveCreation", false);
  const liveCreation = false;

  return (
    visible && <>
      <Modal className="comp-TemplateEditPopup" title={null} open={visible} okText={t("Save")}
             okButtonProps={{disabled: liveCreation}}
             destroyOnClose={true} maskClosable={false} onOk={handleOk} confirmLoading={buttonLoading}
             onCancel={handleCancel} width={"1200px"}
             bodyStyle={{height: window.innerHeight - 90, paddingBottom: 0}}
             style={{top: 5, left: 5, maxWidth: "1200px"}}>
        <div className={"edit-container"}>
          <div className={"preview-side"}>
            <FullScreen handle={fullScreenHandle}>
              <div className={"preview-header"}>
                <TemplateFormatSelector template={currentTemplateData} previewFormat={previewFormat}
                                        setPreviewFormat={setPreviewFormat}/>
                <Divider type="vertical"/>
                <Button type="primary" onClick={handleRestartAnimation}>Restart animation</Button>
                <Divider type="vertical"/>
                <Button type="primary" onClick={handleResetData}>Reset data</Button>
                <Divider type="vertical"/>
                <Button type="primary" onClick={handleAnimationOn}>Animation {animation ? "Off" : "On"}</Button>
                <FaExpandArrowsAlt className={"full-screen-icon"} onClick={fullScreenHandle.enter}/>
              </div>

              <Divider style={{margin: "10px 0"}}/>
              <div className={previewReady ? "preview-ready" : "preview-not-ready"}>
                <TemplatePreview
                  key={previewFormat + animation.toString() + mode + currentTemplateData.updatedAt}
                  previewFormat={previewFormat}
                  templateData={currentTemplateData} animation={animation}
                  mode={animation ? "animation" : "screenshot"}/>
              </div>

            </FullScreen>
          </div>
          <div className={"form-side"}>
            <TemplateEditForm form={form} newStylingData={newStylingData}
                              setCurrentTemplateData={setCurrentTemplateData}
                              templateData={currentTemplateData}
                              setEditFormResponseData={setEditFormResponseData} setVisible={setVisible}
                              selectedMatchday={selectedMatchday}/>
          </div>
        </div>
      </Modal>
    </>
  );

};

export default TemplateEditPopup;

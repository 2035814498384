import React, { useState } from 'react';
import backendConfig from "../../../../config/backend";

export default React.memo(function Image({ src, className, id, alt }) {
  const [dynamicClassName, setDynamicClassName] = useState(className);

  const handleError = (currentTarget) => {
    if (currentTarget.src.includes('?fallback=true')) {
      // If fallback has already been applied, do nothing
      return;
    }

    const originalSrc = currentTarget.src;

    // Only attempt to switch to SVG for images in /dynamic/team/ directory
    if (originalSrc.includes('soccer/team') && !originalSrc.endsWith('.svg') && originalSrc.endsWith('.png')) {
      // Switch to SVG and update class name
      currentTarget.src = originalSrc.replace('.png', '.svg');
      setDynamicClassName(`${className} svg-logo`); // Add extra class for SVG
    } else {
      // Apply existing fallback logic only after trying with .svg
      currentTarget.onerror = null; // prevents looping

      if (backendConfig.clientBodyClass === 'app-opta') {
        if (src.indexOf('soccer/team') > -1) {
          currentTarget.src = `/images/preview/worldcup/ball.png`;
        } else {
          currentTarget.src = `/images/preview/worldcup/preview_soccer2.jpg`;
        }
      } else if (backendConfig.clientBodyClass === 'app-adler') {
        currentTarget.src = `/images/preview/staticmedia.jpg`;
      }

      // Add a query parameter to mark that fallback has been applied
      currentTarget.src += '?fallback=true';

      // Reset class name to original if SVG fails
      setDynamicClassName(className);
    }
  };

  return (
    <img
      src={src}
      id={id}
      className={dynamicClassName}
      alt={alt}
      onError={({ currentTarget }) => handleError(currentTarget)}
    />
  );
});

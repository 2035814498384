import React, {useEffect} from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from '../../../../shared/functions/prepareVideoBg';
import animationScript, {animationCleanUp} from "../../../../../animations/worldcup/playercompare_season"

import assets from './assets';
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import {getPlayerStats, getPlayerStatsSeason} from "../../../../shared/utils/opta/statsHandler";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require('./style/style.scss');
require('./style/animation.scss');

import {positionTranslations, positionExtension} from '../../../../shared/utils/opta/player'
import {useTranslation} from "react-i18next";
import {getPlayerPosition} from "../../../../shared/utils/players";
import Image from "../../../../shared/components/Image"
import {getShortPlayerName} from "../../../../shared/feeds/worldcup/playerHelper";

const Index = (props) => {
  const refs = {
    player: React.createRef(), team: React.createRef()
  };

  const {animation, data, images: Images, apiSetup, mode, format, isTimeline} = props;

  const {t} = useTranslation()

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {

      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({refs, data, animation, mode, id, format})
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);

  const templateData = _.get(data, 'data');

  const {options, fields, dynamic} = templateData;
  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);

  // const headline = getContentById('headline', fields);
  const position = getContentById('playerPosition', fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const matchInfo = _.get(dynamic, "matchInfo");
  const matchday = _.get(dynamic, "matchday");
  const playerCompare = _.get(dynamic, "playerCompare");

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];
  //
  // const teamHomeAlpha2 = getOptaCountryCode(homeTeam?.name, homeTeam?.shortName, homeTeam?.code);
  // const teamAwayAlpha2 = getOptaCountryCode(awayTeam?.name, awayTeam?.shortName, awayTeam?.code);


  const teamHomeAlpha2 = homeTeam?.id;
  const teamAwayAlpha2 = awayTeam?.id;


  const playerHome = _.get(playerCompare, 'home');
  const playerAway = _.get(playerCompare, 'away');
  const homeStats = _.get(playerHome, 'stat');
  const awayStats = _.get(playerAway, 'stat');

  const playerHomePositionMap = _.get(positionExtension, playerHome?.position, _.get(positionExtension, playerHome?.subPosition, 'Midfielder'));
  const playerAwayPositionMap = _.get(positionExtension, playerAway?.position, _.get(positionExtension, playerAway?.subPosition, 'Midfielder'));

  const statsMappingHome = getPlayerStatsSeason(_.toLower(playerHomePositionMap), homeStats);
  const statsMappingAway = getPlayerStatsSeason(_.toLower(playerAwayPositionMap), awayStats);

  console.log('_.toLower(playerHomePositionMap)', _.toLower(playerHomePositionMap))
  console.log('playerHome', playerHome)
  console.log('statsMappingHome', statsMappingHome)

  return (<div id={id}
               className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''} side-${_.get(dynamic, "teamSide")}`}>

    <TemplateBlock>
      <Backgrounds bg1={backgroundImage} loop={true}/>

      <div className={"wc-content"}>
        <div className={"wc-bg-content"}></div>

        <div className={"score green-glow"}>{t('Player Compare Season Headline', {keyPrefix: 'templates'})}</div>

        <div className={"qatar-logo"}>
          <img src={assets.qatar_logo}/>
        </div>

        <div className={'versus-info'}>
          <div className={'group'}>
            {matchInfo?.series?.name.replace('Group', t('Group', {keyPrefix: 'templates'})) || ''}
          </div>
          {/*<div className={'vs-team-info'}>*/}
          {/*  Spieltag {matchInfo?.week}*/}
          {/*</div>*/}
        </div>

        <div className={"teams-block"}>
          <div className={"player player-home"}>
            <div className={"trikot"}>
              <img src={assets.trikot_empty}/>
              <div className={"player-number"}>{playerHome?.shirtNumber}</div>
              <div
                className={"player-position"}>{t(getPlayerPosition(playerHome), {keyPrefix: "templates"})}</div>
            </div>

            <div className={"team-logo-container home"}>
              <Image className="team-logo" src={`/images/dynamic/soccer/team/${homeTeam?.id}.png`}/>
            </div>

            <div className={"player-name"}>
              <div className={"player-firstname"}>{getShortPlayerName(playerHome, true)}</div>
              <div className={"player-lastname"}>{getShortPlayerName(playerHome, false, true)}</div>
            </div>

          </div>
          <div className={"player player-away"}>
            <div className={"trikot"}>
              <img src={assets.trikot_empty}/>
              <div className={"player-number"}>{playerAway?.shirtNumber}</div>
              <div
                className={"player-position"}>{t(getPlayerPosition(playerAway), {keyPrefix: "templates"})}</div>
            </div>

            <div className={"team-logo-container away"}>
              <Image className="team-logo" src={`/images/dynamic/soccer/team/${awayTeam?.id}.png`}/>
            </div>

            <div className={"player-name"}>
              <div className={"player-firstname"}>{getShortPlayerName(playerAway, true)}</div>
              <div className={"player-lastname"}>{getShortPlayerName(playerAway, false, true)}</div>
            </div>
          </div>
        </div>


        <div className={"team-stats"}>
          {statsMappingHome.map((statRowHome, index) => {
            const statRowAway = statsMappingAway?.[index];
            return (
              <div key={statRowHome.type} className={"stat-row"}>
                <div className={"stat-home"}>{statRowHome?.value || '0'}</div>
                <div className={"stat-label"}>{t(statRowHome?.type, {keyPrefix: 'stats'})}</div>
                <div className={"stat-away"}>{statRowAway?.value || '0'}</div>
              </div>
            )
          })}
        </div>
      </div>

    </TemplateBlock>

  </div>);
};

const getMainBackground = (format, side) => {
  return _.get(assets, `circle_center`);
};

export default Index;

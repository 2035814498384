import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import _ from "lodash";

import {Form, Select, Button, Radio} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

const {Option} = Select;

export const InputSelect = (props) => {
  const {item} = props;

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onBlur() {
    console.log('blur');
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }

  return <Form.Item label={item.label} name={`${item.id}`}
                    rules={[{required: false, message: 'Please input your username!'}]} tooltip={props.tooltip}>
    <Select
      showSearch
      style={{width: 200}}
      placeholder={item.label}
      optionFilterProp="children"
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props}
    >
      {_.map(item.selectData, (item, itemIndex) => {
        return <Option key={"s" + item.value} value={item.value}>{item.label}</Option>
      })}
    </Select>
  </Form.Item>
};

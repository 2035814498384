import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
// import Player from './Player';
import $ from 'jquery';
// import AnimationLibrary from 'FRONTENDNPM/src/frontend/src/templates/AnimationLibrary';
// import {
//     themePlayerFactsAnimation
// } from '../animations';

import getContentById from "../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../_partials/Backgrounds/Index";
import TemplateBlock from "../_partials/TemplateBlock/Index";
import Player from './Player';

import {engine, fadeIn} from "../../../../animations/index"
import ImageOrVideo from "../../../shared/components/ImageOrVideo/Index";
// import Header from "./Header";
import Stats from "./Stats";
import animationScript from "../../../../animations/playercompare";
import {animationCleanUp} from "../../../../animations/playerstats";

import parseMediaUrl from "../../../shared/functions/parseMediaUrl";

require('./style/style.scss');
require('./style/animation.scss');

const Index = (props) => {
  const refs = {
    teamHome: React.createRef(),
    teamAway: React.createRef(),
    playerHome: React.createRef(),
    playerAway: React.createRef()
  };

  const {animation, data, images: Images, apiSetup, mode} = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    // const {urlQuery} = this.props;
    // const $container = $('#' + this.props.id);

    // $container.find('.factsList > ul > li, .factsList > ol > li, .factsList > p ').wrapInner('<div class="li-wrapper"><div class="li-wrapper-content"> </div></div>');
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      prepareAnimation();
      animationReturn = animationScript({refs, data, animation, mode, id})
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);

  const templateData = _.get(data, 'data');

  const {options, fields} = templateData;
  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);

  const teamHomeData = getContentById('teamHome', fields, "self");
  const teamHomeDataStyles = _.get(teamHomeData, "styles", {});

  const teamAwayData = getContentById('teamAway', fields, "self");
  const teamAwayDataStyles = _.get(teamAwayData, "styles", {});

  const playerHomeData = getContentById('playerHome', fields, "self");
  const playerHomeDataStyles = _.get(playerHomeData, "styles", {});

  const playerAwayData = getContentById('playerAway', fields, "self");
  const playerAwayDataStyles = _.get(playerAwayData, "styles", {});

  const backgroundImage = getContentById('defaultBackground', options);
  const backgroundMask = getContentById('defaultBackgroundMask', options);

  return (
    <div id={id}
         className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''}`}>
      <Backgrounds bg1={parseMediaUrl(backgroundImage.url)} bg2={parseMediaUrl(backgroundMask.url)} color1={color1}
                   color2={color2}/>
      <TemplateBlock>

        {/*<Player id={_.get(playerData, 'id', null)} ref={playerRef} image={_.get(playerData, 'value.url', null)} primaryColor={color1}*/}
        {/*        backgroundColor={color2} styles={_.get(playerData, 'styles', null)}/>*/}


        <div className={'background-light'}/>


        <div id="header" className={'header'}>

          <div className={'home-team-block'}>
            <div className="HomeTeamName">
              {_get(teamHomeData, 'value.data.name', null)}
            </div>
            <div ref={refs.teamHome} style={{...teamHomeDataStyles}} className="HomeTeamLogo">{<ImageOrVideo
              src={parseMediaUrl(_get(teamHomeData, 'value.url', null))}/>}</div>
          </div>

          <div className={'away-team-block'}>
            <div ref={refs.teamAway} style={{...teamAwayDataStyles}} className="AwayTeamLogo">{<ImageOrVideo
              src={parseMediaUrl(_get(teamAwayData, 'value.url', null))}/>}</div>
            <div className="AwayTeamName">
              {_get(teamAwayData, 'value.data.name', null)}
            </div>
          </div>


          <div className={'home-player-block'}>
            <div className="HomePlayerName" style={{backgroundColor: color1}}>
              <span>{_.get(playerHomeData, 'value.data.lastname', null)}</span></div>
            <div className="HomePlayerPosition" style={{color: color1}}>
              <span>{_.get(playerHomeData, 'value.data.position', null)}</span></div>
            <div className="HomePlayerNumber"><span>{_.get(playerHomeData, 'value.data.shirtNumber', null)}</span>
            </div>
          </div>
          <div className={'away-player-block'}>
            <div className="AwayPlayerName" style={{backgroundColor: color1}}>
              <span>{_.get(playerAwayData, 'value.data.lastname', null)}</span></div>
            <div className="AwayPlayerPosition" style={{color: color1}}>
              <span>{_.get(playerAwayData, 'value.data.position', null)}</span></div>
            <div className="AwayPlayerNumber"><span>{_.get(playerAwayData, 'value.data.shirtNumber', null)}</span>
            </div>
          </div>

        </div>

        <Player ref={refs.playerHome} id={"playerHome"} side={'home'} data={playerHomeData} color1={color1}
                styles={playerHomeDataStyles}/>
        <Stats fields={fields} primaryColor={color1} backgroundColor={color2}/>
        <Player ref={refs.playerAway} id={"playerAway"} side={'away'} data={playerAwayData} color1={color1}
                styles={playerAwayDataStyles}/>
      </TemplateBlock>

    </div>);
};

export default Index;

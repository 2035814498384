import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Form, Drawer, Button, Input, Dropdown, Menu, Popconfirm, notification, Select} from "antd";

import {useTranslation} from "react-i18next";

import {message, Space} from 'antd';


import {
  CameraOutlined,
  CheckOutlined, DeleteOutlined,
  EditOutlined, InstagramOutlined,
  MenuOutlined,
  PlusCircleOutlined, ShareAltOutlined,
  VideoCameraAddOutlined, VideoCameraOutlined, ZoomInOutlined, PlayCircleOutlined, AimOutlined
} from '@ant-design/icons';

import {useDraggable} from '@dnd-kit/core';
import {useDroppable} from '@dnd-kit/core';
import {CSS} from "@dnd-kit/utilities";
import {restrictToHorizontalAxis, restrictToVerticalAxis, restrictToWindowEdges,} from '@dnd-kit/modifiers';

import {
  DndContext,
  DragOverlay,
  closestCorners,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import {
  SortableContext,
  verticalListSortingStrategy,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
  useSortable,
  arrayMove
} from "@dnd-kit/sortable";

import {ContainerTimeline, ContainerPicker} from "./Sortable/Container";
import SortableItem, {Item} from "./Sortable/SortableItem";
import _ from "lodash";
import TimelinePreviewPopup from "./TimelinePreviewPopup";
import {useGlobalStore} from "../../../../stores/globalBackendStore";
import {
  getTemplatesByMatchday,
  getTimelinesByMatchday,
  getTimelinesBySeason,
  setTimelineById,
  deleteTimelineById, apiPrepareMatchday, apiSyncMatchdayTimelines
} from "../../../../services/backend/api.service";
import Loader from "../../../shared/components/Loader";
import {produceVideoTimeline} from "../../../../services/backend/bvr.service";
import downloadFile from "../../../shared/functions/downloadFile";
import getEventFromMatchday from "../../../shared/functions/getEventFromMatchday";
import backendCfg from "../../../../config/backend";
import Image from "../../../shared/components/Image";
import copy from "clipboard-copy";
import {InputSelect} from "../../../shared/components/Forms/Fields/types/_FormSelect";
import BackendConfig from "../../../../config/backend";
import backendConfig from "../../../../config/backend";

const {Option} = Select;
const {SubMenu} = Menu;

const wrapperStyle = {
  display: "flex",
  flexDirection: "row"
};

const submenuPreventClick = (e) => {
  e.domEvent.stopPropagation();
};

const TimelineMenu = (props) => {
  const {
    timeline,
    indexKey,
    setDropdownVisible,
    dropdownVisible,
    setEditData,
    editData,
    setPreview,
    setTimelinePreviewData,
    setReloadTimelinesList
  } = props;
  const {t} = useTranslation();

  const menuRef = React.createRef();

  function handleBodyClick(event) {
    setDropdownVisible(dropdownVisible.map(() => false))
  }

  const apiSetup = useGlobalStore(state => state.apiSetup);
  const apiSelectedMatchday = useGlobalStore(state => state.selectedMatchday);

  const onClick = async (menuItem, actionType, data) => {
    //prevent trigger body event
    if (menuItem.domEvent) {
      menuItem.domEvent.stopPropagation();
    } else {
      // menuItem.stopPropagation();
    }

    const {uuid} = timeline;
    // const node = menuItem.node;
    // console.info(`Click on item ${menuItem}`, menuItem);
    // console.info(`Click on item ${actionType}`);

    if (actionType === "preview") {
      setTimelinePreviewData(timeline);
      setPreview(true);
    }

    if (actionType === "copy-timeline-url") {
      const {timeline, format} = data;
      copy(`${backendCfg.frontendUrl}/timeline/${timeline.uuid}?format=${format}`);
      notification["success"]({
        message: t('Success'),
        description: t("Timeline url copied."),
        placement: "bottomRight"
      });
    }

    if (actionType === "open-timeline-url") {
      const {timeline, format} = data;
      window.open(`${backendCfg.frontendUrl}/timeline/${timeline.uuid}?format=${format}`, "_blank")
    }

    if (actionType === "edit") {
      setEditData({
        data: timeline,
        uuid
      })
      // console.log("id",);
    }

    if (actionType === "remove") {

      await deleteTimelineById({uuid: timeline.uuid}).catch((err) => {
        console.error('err', err);
      });

      notification["success"]({
        message: t('Success'),
        description: t("Timeline has been removed."),
        placement: "bottomRight"
      });

      setReloadTimelinesList({
        reload: true
      })
    }


    if (actionType === "produce_video") {
      const {timeline, format, lng} = data;
      await produceVideoTimeline({timeline, format, apiSetup, apiSelectedMatchday, lng}).catch((err) => {
        console.error('err', err);
      });

      setReloadTimelinesList({
        reload: true
      })
    }

    if (actionType === "download_video") {
      const {timeline, format, type} = data;
      const videoFormatFileUrl = _.get(timeline, `data.videos.${format}.file`, null);

      if (videoFormatFileUrl) {
        downloadFile(`${videoFormatFileUrl}?action=download&filename=${timeline.name}_[${format}].mp4`);
      } else {
        notification["warning"]({
          message: t('Video download'),
          description: t("Selected format has been not yet produced. You need to produce it first.")
        });
      }
    }

    dropdownVisible[indexKey] = false;
    setDropdownVisible([...dropdownVisible])
  };

  useEffect(() => {
    if (dropdownVisible[indexKey]) {
      document.body.addEventListener("click", handleBodyClick);
    }

    return () => document.body.removeEventListener("click", handleBodyClick);
  }, [dropdownVisible[indexKey]]);

  const availableFormats = _.get(apiSetup, "timeline.formats", []);

  return (<>
    {/*<TemplateEditPopup visible={edit} setVisible={setEdit} template={template}*/}
    {/*                   initialTemplateData={_.cloneDeep(template)}/>*/}
    <Menu ref={menuRef} className={"template-menu-nav"} selectable={false}>
      {/*<Menu.Item onClick={(menuInfo) => {*/}
      {/*    onClick(menuInfo, "preview")*/}
      {/*}} key={"preview_" + indexKey} id={"timeline-preview-trigger"} className={"menu-entry"}*/}
      {/*           icon={<ZoomInOutlined/>}>*/}
      {/*    /!*<a href="#action1">{t("Preview")}</a>*!/*/}
      {/*    {t("Preview")}*/}
      {/*</Menu.Item>*/}
      <Menu.Item onClick={(menuInfo) => {
        onClick(menuInfo, "edit")
      }} key={"edit_" + indexKey} className={"menu-entry"} icon={<EditOutlined/>}>
        {/*<a href="#action1">{t("Edit")}</a>*/}
        {t("Edit")}
      </Menu.Item>
      <Menu.Divider/>
      <SubMenu onTitleClick={submenuPreventClick} title={t("Open in tab")} key={"open_timeline_url_" + indexKey}
               className={"menu-entry"} icon={<VideoCameraAddOutlined/>}>

        {availableFormats.map((thisFormat) => {
          return <Menu.Item key={"open_timeline_url_" + thisFormat} onClick={(menuInfo) => {
            onClick(menuInfo, "open-timeline-url", {
              format: thisFormat,
              timeline
            })
          }}>{thisFormat}</Menu.Item>
        })}

      </SubMenu>
      <SubMenu onTitleClick={submenuPreventClick} title={t("Copy url")} key={"copy_timeline_url_" + indexKey}
               className={"menu-entry"} icon={<VideoCameraAddOutlined/>}>

        {availableFormats.map((thisFormat) => {
          return <Menu.Item key={"copy_timeline_url_" + thisFormat} onClick={(menuInfo) => {
            onClick(menuInfo, "copy-timeline-url", {
              format: thisFormat,
              timeline
            })
          }}>{thisFormat}</Menu.Item>
        })}

      </SubMenu>
      <Menu.Divider/>
      <SubMenu onTitleClick={submenuPreventClick} title={t("Produce video")} key={"produce_video_" + indexKey}
               className={"menu-entry"} icon={<VideoCameraAddOutlined/>}>

        <SubMenu onTitleClick={submenuPreventClick} title={t("en")} key={"produce_video_en_" + indexKey}>
          {availableFormats.map((thisFormat) => {
            return <Menu.Item key={"produce_video_en_" + thisFormat} onClick={(menuInfo) => {
              onClick(menuInfo, "produce_video", {
                format: thisFormat,
                timeline,
                lng: 'en'
              })
            }}>{thisFormat}</Menu.Item>
          })}
        </SubMenu>

        <SubMenu onTitleClick={submenuPreventClick} title={t("de")} key={"produce_video_de_" + indexKey}>
          {availableFormats.map((thisFormat) => {
            return <Menu.Item key={"produce_video_de_" + thisFormat} onClick={(menuInfo) => {
              onClick(menuInfo, "produce_video", {
                format: thisFormat,
                timeline,
                lng: 'de'
              })
            }}>{thisFormat}</Menu.Item>
          })}
        </SubMenu>

      </SubMenu>
      <Menu.Divider/>
      <SubMenu onTitleClick={submenuPreventClick} title={t("Download video")} key={"download_video_" + indexKey}
               className={"menu-entry"}
               icon={<VideoCameraOutlined/>}>
        {availableFormats.map((thisFormat) => {
          return <Menu.Item key={"download_video_" + thisFormat} onClick={(menuInfo) => {
            onClick(menuInfo, "download_video", {
              format: thisFormat,
              timeline
            })
          }}>{thisFormat}</Menu.Item>
        })}
      </SubMenu>
      <Menu.Divider/>
      <Menu.Divider/>

      <Menu.Item key={"remove_" + indexKey} className={"menu-entry action-remove"} icon={<DeleteOutlined/>}
                 onClick={(menuInfo) => {
                   onClick(menuInfo, "remove")
                 }
                 }>
        {t("Remove")}
        {/*<Popconfirm placement="left" title={t("Are you sure to remove this item?")} onConfirm={(menuInfo) => {*/}
        {/*}} okText="Yes" cancelText="No">*/}
        {/*    <a href="#action1">{t("Remove")}</a>*/}
        {/*</Popconfirm>*/}
      </Menu.Item>

    </Menu>
  </>)
};

const defaultAnnouncements = {
  onDragStart(id) {
    console.log(`Picked up draggable item ${id}.`);
  },
  onDragOver(id, overId) {
    if (overId) {
      console.log(
        `Draggable item ${id} was moved over droppable area ${overId}.`
      );
      return;
    }

    console.log(`Draggable item ${id} is no longer over a droppable area.`);
  },
  onDragEnd(id, overId) {
    if (overId) {
      console.log(
        `Draggable item ${id} was dropped over droppable area ${overId}`
      );
      return;
    }

    console.log(`Draggable item ${id} was dropped.`);
  },
  onDragCancel(id) {
    console.log(`Dragging was cancelled. Draggable item ${id} was dropped.`);
  }
};

let interval = null;

export default function (props) {
  const uniqueIdField = "templateId";
  const {visibleTimeline, visiblePicker, setReloadTimelinesList, timelinesList, setTimelinesList} = props;
  const {t} = useTranslation();

  const [editData, setEditData] = useState({
    data: null,
    uuid: null
  });
  const [compLoaded, setCompLoaded] = useState(false);
  const [refreshTimelines, setRefreshTimelines] = useState(false);

  const [preview, setPreview] = useState(false);
  const [timelinePreviewData, setTimelinePreviewData] = useState();

  const newTimelineInput = useRef();

  const [dropdownVisible, setDropdownVisible] = useState([]);
  const [timelineSaveLoading, setTimelineSaveLoading] = useState(false);

  const [timelineStep2Drawer, setTimelineStep2Drawer] = useState(false);

  const [activeId, setActiveId] = useState();
  const [activeItem, setActiveItem] = useState();
  const [refreshVideoStatus, setRefreshVideoStatus] = useState(false);

  const apiSetup = useGlobalStore(state => state.apiSetup);
  const matchday = useGlobalStore(state => state.selectedMatchday);
  // const [timelineList, setTimelineList] = useState([]);

  const [timelineSettings, setTimelineSettings] = useState({
    mode: 'auto'
  });

  const [prepareMatchdayHandlerProgress, setPrepareMatchdayHandlerProgress] = useState(false);

  const refreshTimelinesByMatchday = async () => {
    if (matchday?.competitionId) {
      setCompLoaded(false);
      const data = await getTimelinesBySeason(matchday.competitionId).catch((err) => {
        console.error('err', err);
      });

      const apiData = _.get(data, "data.docs", []);
      setTimelinesList(apiData);
    }
    setCompLoaded(true);
  };

  useEffect(() => {
    if (refreshVideoStatus) {
      if (!interval) {
        interval = setInterval(() => {
          setReloadTimelinesList({
            reload: true
          })
        }, backendCfg.refreshVideoInterval);
      }
      setRefreshVideoStatus(false);
    } else {
      if (interval) {
        clearInterval(interval);
        setReloadTimelinesList({
          reload: false
        });
        interval = null;
      }
    }

  }, [refreshVideoStatus]);

  useEffect(() => {
    const asyncCall = async () => {
      if (visibleTimeline) {
        await refreshTimelinesByMatchday().catch((err) => {
          console.error('err', err);
        })
      }
    }
    asyncCall()
  }, [matchday, visibleTimeline]);

  useEffect(() => {
    const asyncCall = async () => {
      if (refreshTimelines) {
        await refreshTimelinesByMatchday();
        setRefreshTimelines(false);
      }
    }
    asyncCall()
  }, [refreshTimelines]);

  useEffect(() => {
    const asyncCall = async () => {
      if (timelineStep2Drawer) {

        const templatesSetup = _.get(apiSetup, `templates`, []);

        const pickerTemplateData = await getTemplatesByMatchday(matchday?.eventId).catch((err) => {
          console.error('err', err);
        });

        const apiData = _.get(pickerTemplateData, "data.docs", []);

        //allow only 1920x1080 for timeline
        const apiDataFormatFiltered = _.filter(apiData, (item) => {
          const templateFormats = _.get(_.find(templatesSetup, {name: item.template_name}), "formats", []);

          if (templateFormats.indexOf("1920x1080") > -1) {
            return true;
          }

          return false;
        });

        if (editData.uuid) {
          const {data} = editData;
          const selectedTemplates = _.get(data, "templates", []);

          setItems({
            root: apiDataFormatFiltered.map((entry, index) => {
              const lng = _.find(entry?.data?.fields, {id: 'lang'})?.value;
              return {
                ...entry?.event,
                templateId: entry.uuid,
                template: entry.template_name,
                name: entry.name,
                lng
              };
            }).filter((item) => {
              return _.findIndex(selectedTemplates, {templateId: item.templateId}) === -1
            }),
            timeline: selectedTemplates.map((entry, index) => {
              return entry;
            })
          })
        } else {
          setItems({
            root: apiDataFormatFiltered.map((entry, index) => {
              const lng = _.find(entry?.data?.fields, {id: 'lang'})?.value;
              return {
                ...entry?.event,
                templateId: entry.uuid,
                template: entry.template_name,
                name: entry.name,
                lng
              };
            }),
            timeline: []
          })
        }


      }
    }

    asyncCall()

  }, [timelineStep2Drawer]);

  useEffect(() => {
    if (editData.uuid) {
      setTimelineSettings(_.get(editData, "data.options"));
      setTimelineStep2Drawer(true);
    }
  }, [editData.uuid]);

  const [items, setItems] = useState({
    root: [],
    timeline: []
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const createTimelineStep1Handler = useCallback((e) => {
    const timelineName = _.get(newTimelineInput, "current.input.value", "");

    if (_.isEmpty(timelineName)) {
      message.error(t("Timeline name cannot be empty."));
    } else {
      setTimelineSettings({
        mode: 'auto'
      });
      setTimelineStep2Drawer(true);
    }
  });

  const createTimelineStep2Handler = useCallback(async (e) => {
    const postData = {};
    let timelineName = _.get(newTimelineInput, "current.input.value", "");

    if (editData.uuid) {
      timelineName = _.get(editData, "data.name", null);
    }

    postData.name = timelineName;
    postData.format = "1920x1080";
    postData.event = getEventFromMatchday(matchday);
    postData.templates = items.timeline;
    postData.options = timelineSettings;

    // console.log("Create timeline action! Save in DB.", postData)

    await setTimelineById({
      uuid: editData.uuid,
      data: postData
    }).catch((err) => {
      console.error('err', err);
      notification["warning"]({
        message: t('Warning'),
        description: t("Timeline saving problem: ") + err.message,
        placement: "bottomRight"
      });
    });

    // console.log('newTimelineInput.current', newTimelineInput.current);

    //clear input value
    newTimelineInput.current.input.value = "";


    setTimelineStep2Drawer(false);
    setEditData({
      uuid: null,
      data: {}
    });

    setRefreshTimelines(true);

    notification["success"]({
      message: t('Success'),
      description: t("Timeline has been saved."),
      placement: "bottomRight"
    });
  });

  const timelineStep2Close = (e) => {
    setItems({
      root: [],
      timeline: []
    });
    setEditData({
      uuid: null,
      data: {}
    });
    setTimelineStep2Drawer(false);
  };

  const syncMatchdayTimelines = (e) => {
    e.preventDefault();
    if (!prepareMatchdayHandlerProgress) {
      setPrepareMatchdayHandlerProgress(true)

      apiSyncMatchdayTimelines({
        competitionId: matchday?.competitionId,
        eventId: matchday?.eventId,
        home: matchday?.home,
        away: matchday?.away,
      }).then((data) => {
        setPrepareMatchdayHandlerProgress(false)
        setRefreshTimelines(true);
      }).catch((err) => {
        console.error('err', err);
        setPrepareMatchdayHandlerProgress(false)
        setRefreshTimelines(true);
      })
    }


  };

  return (
    <div className={"drawers-containers"}>
      <div style={wrapperStyle}>

        <Drawer
          rootClassName={"timeline-create-step-1-drawer"}
          title={<div className={"timeline-create-step-1-title"}>
            <div>{t("Saved timelines")}</div>
            {<div className={"main-button-worldcup"} onClick={syncMatchdayTimelines}>
              {prepareMatchdayHandlerProgress ? <AimOutlined spin/> :
                <PlusCircleOutlined/>} {t("Prepare Timelines")}
            </div>}
          </div>}
          placement={"bottom"}
          mask={true}
          closable={true}
          getContainer={null}
          maskClosable={false}
          onClose={props.onClose}
          open={visibleTimeline}
          width={450}
          zIndex={"500"}
          height={"600px"}
        >
          {!compLoaded ? <Loader type={"inline"}/> : <div className={"dashboard-timeline-list"}>
            <div className={"create-timeline-block"}>
              <div className={"timeline-step1-button"}>
                <div>{t("New timeline")}</div>
                <Input ref={newTimelineInput} name={"new-timeline-name"} allowClear
                       onPressEnter={(e) => {
                         createTimelineStep1Handler(e);
                       }} className={"new-timeline-name"} size="large" placeholder={"Timeline name"}
                       onClick={(e) => {

                       }}/>
                <PlusCircleOutlined className={"link"} onClick={(e) => {
                  e.stopPropagation();
                  createTimelineStep1Handler(e);
                }}/>
              </div>
            </div>

            {preview &&
              <TimelinePreviewPopup visible={preview} setVisible={setPreview} timeline={timelinePreviewData}
                                    initialTemplateData={_.cloneDeep(timelinePreviewData)}/>}

            {timelinesList.map((timeline, index) => {
              const availableFormats = _.get(apiSetup, "timeline.formats", []);

              availableFormats.forEach((thisFormat) => {
                const formatStatus = _.get(timeline, `data.videos.${thisFormat}.status`, null);
                if (formatStatus == "sent" && !refreshVideoStatus) {
                  setRefreshVideoStatus(true);
                }
              });

              const updatedAtPrefix = new Date(timeline.updatedAt).getTime();

              return <div data-id={timeline.uuid + timeline.updatedAt} key={timeline.uuid} className={"template-block"}
                          onClick={(e) => {
                            setTimelinePreviewData(timeline);
                            setPreview(true);
                          }
                          }>

                <div className={"video-indicators"}>
                  {availableFormats.map((thisFormat) => {
                    const formatStatus = _.get(timeline, `data.videos.${thisFormat}.status`, "no-status");
                    return <div key={timeline.uuid + thisFormat}
                                className={"format " + thisFormat + " " + formatStatus}>{thisFormat}</div>
                  })}
                </div>

                <div className={"timeline-template-counter"}>{t("Total")}:{timeline.templates.length}</div>
                <div className={"template-image"}>
                  {/*<PlayCircleOutlined />*/}
                  <Image src={
                    `${backendCfg.screenshotsUrl
                      .replace("[[TEMPLATE]]", _.get(timeline, 'templates[0].template'))
                      .replace("[[TYPE]]", "preview")
                      .replace("[[UUID]]", _.get(timeline, 'templates[0].templateId'))
                    }?updated_at=${updatedAtPrefix}`
                  }/>
                </div>
                <div
                  className={"template-label"}> {++index}. {timeline.name}

                  <Dropdown className={"template-menu"}
                            overlay={<TimelineMenu timeline={timeline} indexKey={index}
                                                   setEditData={setEditData} editData={editData}
                                                   setDropdownVisible={setDropdownVisible}
                                                   dropdownVisible={dropdownVisible}
                                                   setPreview={setPreview}
                                                   setTimelinePreviewData={setTimelinePreviewData}
                                                   setReloadTimelinesList={setReloadTimelinesList}/>}
                            placement="bottomLeft"
                            trigger={['click']} open={_.get(dropdownVisible, index, false)}
                            onClick={(e) => {
                              e.stopPropagation();
                            }
                            }>
                    <a className="ant-dropdown-link" onClick={(e) => {
                      e.preventDefault();

                      let tempArr = [...dropdownVisible];

                      tempArr = tempArr.filter((currentState, currentIndex) => {
                        if (currentIndex === index) {
                          return true;
                        } else {
                          return currentState != true
                        }
                      });

                      if (tempArr[index] === true) {
                        tempArr[index] = false;
                      } else {
                        tempArr[index] = true;
                      }

                      setDropdownVisible(tempArr)
                    }}>
                      <MenuOutlined/>
                    </a>
                  </Dropdown>
                </div>
              </div>
            })}
          </div>}
        </Drawer>

        <Drawer
          rootClassName={"timeline-create-step-2-drawer"}
          title={<>Timeline edit: <span
            style={{color: "#c51d1d"}}>{t(_.get(editData, "data.name", _.get(newTimelineInput, "current.input.value", "New timeline")))}</span></>}
          placement={"bottom"}
          mask={true}
          closable={true}
          getContainer={null}
          maskClosable={false}
          destroyOnClose={true}
          onClose={timelineStep2Close}
          open={timelineStep2Drawer}
          width={450}
          height={"98%"}
        >

          <DndContext
            announcements={defaultAnnouncements}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >

            <h3>{t("Matchday templates")}</h3>
            <div className={"timeline-templates-list-picker"}>
              {items.root && <ContainerPicker id={"root"} items={items.root}/>}
            </div>
            <h3 className={"timeline-target-title"}>{t("Create timeline")}</h3>

            <div className={"timeline-templates-list-target"}>
              {items.timeline && <ContainerTimeline id={"timeline"} items={items.timeline}/>}

              <DragOverlay style={{opacity: 0.90}}>
                {activeId ?
                  <div className={"drag-overlay-element"}>
                    <Item item={activeItem} id={activeId}/>
                  </div>
                  : null}
              </DragOverlay>
            </div>
          </DndContext>


          <div className={"timeline-options"}>
            <div>Timeline Play mode</div>
            <Select
              style={{width: 200}}
              placeholder={"Timeline mode"}
              optionFilterProp="children"
              value={_.get(timelineSettings, "mode")}
              onChange={(value, e) => {
                setTimelineSettings({
                  ...timelineSettings,
                  mode: value
                })
              }}
              onFocus={(e) => {

              }}
              onBlur={(e) => {

              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={"auto"}>Automatic</Option>
              <Option value={"manual"}>Manual</Option>
            </Select>
          </div>

          <div className={"create-timeline-buttons"}>
            <Button loading={timelineSaveLoading} type="primary" onClick={(e) => {
              createTimelineStep2Handler(e);
            }}>
              {t("Save timeline")}
            </Button>
          </div>

        </Drawer>


      </div>
    </div>

  );

  function findContainer(id) {
    if (id in items) {
      return id;
    }


    return Object.keys(items).find((key) => {
      const foundElement = _.find(items[key], {[uniqueIdField]: id});
      return foundElement ? id : null;
    })

    // return Object.keys(items).find((key) => {
    //     items[key].includes(id)
    // });
  }

  function handleDragStart(event) {
    const {active} = event;
    const {id} = active;

    const containerId = _.get(active, "data.current.sortable.containerId", null);
    // console.log("event", event);
    // console.log("items", items);
    const currentItem = _.find(items[containerId], {templateId: id});

    setActiveId(id);
    setActiveItem(currentItem);
  }

  function handleDragOver(
    {
      active, over
    }
  ) {
    const overId = over?.id;
    if (!overId) {
      return;
    }


    const overContainer = findContainer(overId);
    const activeContainer = findContainer(active.id);
    if (!overContainer || !activeContainer) {
      return;
    }
    if (activeContainer !== overContainer) {

      setItems((items) => {
        const activeItems = items[activeContainer];
        const overItems = items[overContainer];

        const activeIndex = _.findIndex(activeItems, {[uniqueIdField]: active.id});
        const overIndex = _.findIndex(overItems, {[uniqueIdField]: overId});
        // const overIndex = overItems.indexOf(overId);
        // const activeIndex = activeItems.indexOf(active.id);
        let newIndex;
        if (overId in items) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowLastItem = over &&
            overIndex === overItems.length - 1 &&
            active.rect.current.translated &&
            active.rect.current.translated.offsetTop >
            over.rect.offsetTop + over.rect.height;
          const modifier = isBelowLastItem ? 1 : 0;
          newIndex =
            overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        return {
          ...items,
          [activeContainer]: [
            ...items[activeContainer].filter((item) => item[uniqueIdField] !== active.id),
          ],
          [overContainer]: [
            ...items[overContainer].slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...items[overContainer].slice(newIndex, items[overContainer].length),
          ],
        };
      });
    }
  }

  function handleDragEnd(event) {
    const {active, over} = event;
    const {id} = active;
    const {id: overId} = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = _.findIndex(items[activeContainer], {[uniqueIdField]: active.id});
    const overIndex = _.findIndex(items[overContainer], {[uniqueIdField]: overId});

    if (activeIndex !== overIndex) {
      setItems((items) => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
      }));
    }

    setActiveId(null);
    setActiveItem(null);
  }
}

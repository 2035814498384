export const asyncTimeout = (delay) => {
  return new Promise(resolve => setTimeout(resolve, delay));
};


export const syncTimeout = (fn, delay) => {
  return setTimeout(() => {
    fn();
  }, delay);
};

export const sleepFn = async function sleep(delay, fn, ...args) {
  await asyncTimeout(delay);
  return fn(...args);
};

import React, {Suspense} from "react";
import {Switch, BrowserRouter, Route, Redirect, useParams, useRouteMatch} from "react-router-dom";

import Loader from "../components/shared/components/Loader"

import FrontendLayout from "./Frontend"
// import BackendLayout from "./Backend";
import Login from "../components/backend/pages/Login";
import NotFoundPage from "../components/shared/pages/NotFound";


const BackendLayout = React.lazy(() => import(/* webpackChunkName: "Backend" */ './Backend'));
// const Loader = React.lazy(() => import(/* webpackChunkName: "Loader" */ '../components/shared/components/Loader'));
// const FrontendLayout = React.lazy(() => import(/* webpackChunkName: "Frontend" */'./Frontend'));

// const Login = React.lazy(() => import('../components/backend/pages/Login'));

// const PrivateRoute = ({component: Component, ...rest}) => (
//   <Route
//     {...rest}
//     render={props => true ? <Component {...props} /> : <Redirect to={{pathname: '/auth'}}/>}
//   />
// );

export default function Layouts() {

  return (
    <Switch>
      <Route key={"/backend/login"} path={"/backend/login"} exact={true} render={(props) => {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
          return <Redirect to={"/backend"}/>;
        }
        return <Login/>;
      }
      }/>
      <Route path="/backend" component={(props) => {
        return <Suspense fallback={<Loader type={"fullscreen"}/>}>
          <BackendLayout {...props} />
        </Suspense>
      }}/>
      <Route path="/frontend" component={(props) => {
        return <FrontendLayout {...props} />
      }}/>
      <Route path="/" exact component={(props) => {
        return <Redirect to={"/backend"}/>
      }}/>
      <Route path="*" component={(props) => {
        return <NotFoundPage {...props} />
      }}/>
    </Switch>
  );
}

import React, {useEffect, useState} from 'react';
import {Drawer, Tabs} from 'antd';
import {CaretDownOutlined} from '@ant-design/icons';
import _ from 'lodash';
import Image from '../Image'

import {getGlobalMatchdays, setGlobalMatchdays, getSelectedLiveData} from "../../../../services/backend/api.service"

import './styles/main.scss'
import {useGlobalStore} from "../../../../stores/globalBackendStore";
import {useTranslation} from "react-i18next";
import config from "../../../../config/frontend";
import getOptaCountryCode from "../../utils/getOptaCountryCode";

const opta3ToAlpha2Fallback = {
  'United States': 'us',
  'Korea Republic': 'kr',
  'Wales': 'gb-wls',
  'England': 'gb-eng',
  'Iran': 'ir'
};

const getTeamLogoById = (id, teams) => {
  const logoUrl = _.get(_.find(teams, {id: id}), "logo.url");
  // const fallback = '/images/wc2022/qatar-2022-logo.jpg';
  // const fallback = '/images/logos/bundesliga.svg';
  // const fallback = '/images/dynamic/soccer/team/placeholder.jpg';
  const fallback = '/images/preview/worldcup/ball.png';
  if (logoUrl) {
    return <Image className="team-logo" src={`${config.apiUrl}${logoUrl}`}/>
  }

  const countryCode = _.get(_.find(teams, {id: id}), "name");
  const countryCodeShortcut = _.get(_.find(teams, {id: id}), "shortcut");
  const opta3Alpha = _.get(_.find(teams, {id: id}), "code");

  let alpha2 = getOptaCountryCode(countryCode, countryCodeShortcut, opta3Alpha);


  return <Image className="team-logo" src={`/images/dynamic/soccer/team/${id}.png`}/>

  // if (alpha2) {
  //   return <Image className="team-logo" src={`/images/dynamic/soccer/team/${id}.png`}/>
  // }
  //
  // return <Image className="team-logo" src={`${fallback}`}/>;
};

const MatchDayPopup = (props) => {
  const selectedMatchday = useGlobalStore(state => state.selectedMatchday);
  const setSelectedMatchday = useGlobalStore(state => state.setSelectedMatchday);

  const {drawerVisible, closeGlobalMatchday, data} = props;
  const {t} = useTranslation();

  const [cid, setCid] = useState();
  const [tid, setTid] = useState();
  const [mid, setMid] = useState();
  const [season, setSeason] = useState();

  const [teams, setTeams] = useState([]);
  const [matchdays, setMatchdays] = useState([]);

  // const getTeamLogoById = (id) => {
  //   const logoUrl = _.get(_.find(teams, {id: id}), "logo.url");
  //   const fallback = '/images/wc2022/qatar-2022-logo.jpg';
  //   if (logoUrl) {
  //     return <img src={`${config.apiUrl}${logoUrl}`}/>;
  //   }
  //
  //   const countryCode = _.get(_.find(teams, {id: id}), "name");
  //
  //   if (countryCode) {
  //     let alpha2 = _.lowerCase(iso.whereCountry(countryCode)?.alpha2);
  //
  //     if (!alpha2) {
  //       alpha2 = _.get(opta3ToAlpha2Fallback, countryCode, null);
  //     }
  //     return <img className="team-logo" src={`/images/countries/svg/${alpha2}.svg`}/>
  //   }
  //
  //   return <img src={`${fallback}`}/>;
  // };

  useEffect(() => {
    if (selectedMatchday) {

      const {competitionId, teamId, eventId, season} = selectedMatchday;
      setCid(competitionId);
      setTid(teamId);
      setMid(eventId);
      setSeason(season);

      if (eventId) {
        setGlobalMatchdays(selectedMatchday).then((data) => {
          // const apiDataTeams = _.get(data, "data.teams", []);
          // setTeams(apiDataTeams);
        }).catch((err) => {
          console.error("err", err)
        });
      }
    }


  }, [selectedMatchday]);

  useEffect(() => {
    if (cid) {
      getTeams(cid);
    }
  }, [cid]);

  useEffect(() => {
    if (tid) {
      getMatchadys(cid, tid);
    }
  }, [tid]);

  const getTeams = (cid) => {
    setCid(cid);
    getGlobalMatchdays({cid}).then((data) => {
      const apiDataTeams = _.get(data, "data.teams", []);
      setTeams(apiDataTeams);
    }).catch((err) => {
      console.error("err", err)
    });
  };

  const getMatchadys = (cid, tid) => {
    setTid(tid);
    getGlobalMatchdays({cid, tid}).then((data) => {
      const apiDataMatches = _.get(data, "data.matches", []);

      setMatchdays(apiDataMatches);
      // return data;
    }).catch((err) => {
      console.error("err", err)
    });
  };

  return <Drawer
    rootClassName={"comp-MatchDayPopup"}
    title={t("Matchday selection") + ` (Season ${season})`}
    placement="top"
    height={"50%"}
    closable={true}
    onClose={closeGlobalMatchday}
    open={drawerVisible}
    // visible={true}
  >
    <div className={"main-label"}>{t("Competitions")}</div>
    <div className={"competition-block"}>
      {data.map((item) => {
        return <div key={"cid-" + item.competitionId}
                    className={"competition-item " + (cid == item.competitionId ? "active" : "")}
                    onClick={(event) => {
                      setCid(item.competitionId);
                      setTid(null);
                      setMid(null);
                    }}>
          {item.competitionName}
        </div>
      })}
    </div>

    <div className={"main-label"}>{t("Teams")}</div>
    <div className={"teams-block"}>
      {teams.map((item) => {
        return <div key={"tid-" + item.id} data-id={item.id}
                    className={"team-item " + (tid == item.id ? "active" : "")}
                    onClick={(event) => {
                      setTid(item.id)
                    }}>
          <div className={"team-name"}>
            {item.name}
          </div>
          <div className={"team-logo"}>
            {getTeamLogoById(item.id, teams)}
          </div>
        </div>
      })}
    </div>

    <div className={"main-label"}>{t("Matchdays")}</div>
    <div className={"matchdays-block"}>
      {matchdays.map((item) => {

        return <div key={"mid-" + item.eventId} data-id={item.eventId}
                    className={"matchday-item " + (mid == item.eventId ? "active" : "")} onClick={(event) => {
          setSelectedMatchday(item);
          closeGlobalMatchday()
        }}>
          <div className={"home-name"}>
            {item.home.name}
          </div>
          <div className={"vs-separator"}>vs</div>
          <div className={"away-name"}>
            {item.away.name}
          </div>

          <div className={"logos"}>
            <div className={"home-logo"}>
              {/*<img src={}/>*/}
              {getTeamLogoById(item.home.id, teams)}
            </div>
            <div className={"away-logo"}>
              {getTeamLogoById(item.away.id, teams)}
            </div>
          </div>

          <div className={"matchdate"}>
            {item.date} {item.time}
          </div>
        </div>
      })}
    </div>

    {/*<Button className={"select-matchday"} type="primary">{t("Select matchday")}</Button>*/}

  </Drawer>
};

const Index = (props) => {
  const {t} = useTranslation();
  const GlobalMatchday = useGlobalStore(state => state.selectedMatchday);
  const GlobalLanguage = useGlobalStore(state => state.lang);
  const [matchDate, setMatchDate] = useState("");
  const [matchProgress, setMatchProgress] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [globalMatchdaysData, setGlobalMatchdaysData] = useState([]);
  const [selectedLiveData, setSelectedLiveData] = useState([]);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    if (drawerVisible && globalMatchdaysData.length === 0) {
      getGlobalMatchdays({}).then((data) => {
        const apiDataCompetitions = _.get(data, "data.competitions", []);
        setGlobalMatchdaysData(apiDataCompetitions);
        setTeams(_.get(data, "data.teams", []))
      }).catch((err) => {
        console.error("err", err)
      });
    }
  }, [drawerVisible]);

  // useEffect(() => {
  //   if (GlobalMatchday?.eventId) {
  //     getSelectedLiveData({mid: GlobalMatchday?.eventId}).then((data) => {
  //       setSelectedLiveData(data?.data);
  //     }).catch((err) => {
  //       console.error("err", err)
  //     });
  //   }
  // }, [GlobalMatchday?.eventId]);

  const openGlobalMatchday = (event) => {
    setDrawerVisible(true);
  };

  const closeGlobalMatchday = (event) => {
    setDrawerVisible(false);
  };

  useEffect(() => {
    const date = _.get(GlobalMatchday, "date", "");

    if (date) {
      const monthsShort = require(`../../../../language/${GlobalLanguage}/months_short.json`);
      const splitted = date.split(".");
      const shortName = _.get(monthsShort, splitted[1], splitted[1]);

      setMatchDate({
        day: splitted[0], month: shortName
      });

      setTimeout(() => {
        setMatchProgress(75);
      }, 1000)

    }
  }, [GlobalMatchday, GlobalLanguage]);

  return <div className={"comp-matchday-selector live"}>
    <div className={"content-block"}>

      <CaretDownOutlined onClick={openGlobalMatchday} className={"global-matchday-selector"}/>

      <div className={"competition-label"}>
        {/*{_.get(GlobalMatchday, "competitionName")}*/}
        {t("Kick-Off")}: {_.get(GlobalMatchday, "time")}
      </div>

      {globalMatchdaysData && <MatchDayPopup data={globalMatchdaysData} drawerVisible={drawerVisible}
                                             closeGlobalMatchday={closeGlobalMatchday}/>}

      <div className={"date-block"}>
        <div className={"skew-helper"}>
          <div className={"missing-triangle"}></div>
          <div className={"month"}>{matchDate.month}</div>
          <div className={"day"}>{matchDate.day}</div>
        </div>
      </div>

      <div className={"score-block"}>
        {/*<div className={"home"}>{_.get(selectedLiveData, "matchDetails.scores.total.home")}</div>*/}
        {/*<div className={"separator"}>:</div>*/}
        {/*<div className={"away"}>{_.get(selectedLiveData, "matchDetails.scores.total.away")}</div>*/}

        <div className={"home"}>{_.get(GlobalMatchday, "score.home")}</div>
        <div className={"separator"}>:</div>
        <div className={"away"}>{_.get(GlobalMatchday, "score.away")}</div>

        {/*<div className={"match-progress-bar"} style={{opacity: matchProgress ? 1 : 0}}>*/}
        {/*    <div className={"match-progress-bar-container"} style={{width: `${matchProgress}%`}} />*/}
        {/*    <div className={"stripes"}></div>*/}
        {/*</div>*/}

      </div>

      <div className={"home"}>
        <div className={"team-logo-container"}>
          {getTeamLogoById(_.get(GlobalMatchday, "home.id"), teams)}
          <div className="team-label">{_.get(GlobalMatchday, "home.name")}</div>
        </div>

      </div>
      <div className={"away"}>
        <div className={"team-logo-container"}>
          {getTeamLogoById(_.get(GlobalMatchday, "away.id"), teams)}
          <div className="team-label">{_.get(GlobalMatchday, "away.name")}</div>
        </div>
      </div>
    </div>
  </div>;
};

export default Index;

const assets = {
  _conditional: (data) => {
    const {format, apiData} = data;
    return [assets.mainbackground_1920];
  },
  mainbackground_1920: require("./videos/Background_11_SPIELTAG_16-9.webm"),
  sap_presents: require("../../../assets/logos/sap-presents-one-line.png"),
  penny_del: require("../../../assets/logos/penny-del-logo.png"),
};

export default assets;

import React, {useState, useEffect, useLayoutEffect} from "react";

import interact from 'interactjs'

import backendCfg from '../../../../config/backend';
import frontendCfg from '../../../../config/frontend';

import {FaExpandArrowsAlt} from 'react-icons/fa';

import {Menu, Dropdown, Divider, Button, Modal, Form, Progress} from 'antd';
import {MenuOutlined, AppstoreOutlined, CheckOutlined, FullscreenOutlined} from '@ant-design/icons';

import {FullScreen, useFullScreenHandle} from "react-full-screen";

import {useTranslation} from 'react-i18next';
import {useGlobalStore} from '../../../../stores/globalBackendStore';

import TimelinePreview from '../../../shared/components/TimelinePreview/Index';
import TemplateEditForm from './TemplateEditForm';
import TemplateFormatSelector from './TemplateFormatSelector';

import {getElementsToInit, initMoveResize, resetMoveResize} from '../../../shared/functions/wysiwyg'

import {
  getTemplatesByFilter, getTimelinesByMatchday
} from "../../../../services/backend/api.service";

import _ from "lodash";

import copy from 'clipboard-copy';
import Image from "../../../shared/components/Image";
import blacklist from "validator/es/lib/blacklist";

const {SubMenu} = Menu;

const TimelineEditPopup = (props) => {
  const {t} = useTranslation();
  const {visible, setVisible, timeline, initialTemplateData} = props;

  const [form] = Form.useForm();

  const apiSetup = useGlobalStore(state => state.apiSetup);

  const [currentTimelineData, setCurrentTimelineData] = useState(timeline);
  const [animation, setAnimation] = useState(true);
  const [showTimelinePreview, setShowTimelinePreview] = useState(false);
  const [timelineTotalDuration, setTimelineTotalDuration] = useState();
  const [timelineProgressPercent, setTimelineProgressPercent] = useState();

  const [timelineStats, setTimelineStats] = useState({});
  const [currentTemplateDuration, setCurrentTemplateDuration] = useState();
  const [refreshTimeline, setRefreshTimeline] = useState(false);
  const [fullTemplateData, setFullTemplateData] = useState();

  const fullScreenHandle = useFullScreenHandle();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    form.submit();
    // setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setShowTimelinePreview(false)
  };

  //catch timeline updates and display
  const changeEmitterHandler = (data) => {
    setTimelineStats({
      ...data
    })
  };

  useEffect(() => {
    const asyncCall = async () => {
      if (currentTimelineData.uuid) {
        const filter = {
          uuid: []
        };
        _.each(timeline.templates, (item) => {
          filter.uuid.push(item.templateId);
        });

        const data = await getTemplatesByFilter(filter, ["data.options.id", "data.options.value"]).catch((err) => {
          console.error('err', err);
        });

        const apiData = _.get(data, "data.docs", []);

        setFullTemplateData(apiData)

        let totalDuration = _.sumBy(apiData, function (templateEntry) {
          if (frontendCfg.testMode) {
            return parseFloat(frontendCfg.testData.templateDuration);
          }

          return parseFloat(_.get(_.find(templateEntry.data.options, {id: "videoduration"}), "value"));
        });

        const inDuration = frontendCfg.timeline.fadeInDuration / 1000;
        const outDuration = (frontendCfg.timeline.fadeOutDuration / 1000) * timeline.templates.length - 1;

        const totalIncludedOthers = totalDuration + inDuration + outDuration;

        setTimelineTotalDuration(totalIncludedOthers);
        setShowTimelinePreview(true)
      }
    }
    asyncCall()
  }, [currentTimelineData.uuid]);

  useEffect(() => {
    if (timelineStats.currentSlide) {
      let templateDuration = parseInt(_.get(timelineStats, "currentDuration"));
      const $container = $('.timeline-visual-blocks');
      const $scrollTo = $container.find('.template-block.current');

      // debugger;
      const scrollTo = $scrollTo.get(0).offsetTop - $container.get(0).offsetTop - parseFloat($scrollTo.css("margin-top")) - parseFloat($scrollTo.css("border"));

      $container.animate({
        scrollTop: scrollTo
      }, {
        duration: 500
      });

      const durationInterval = setInterval(() => {
        setCurrentTemplateDuration(templateDuration);
        templateDuration--;
      }, 1000);

      return () => {
        clearInterval(durationInterval);
      }

    }
  }, [timelineStats.currentSlide]);

  useEffect(() => {
    if (timelineTotalDuration > 0 && timelineStats.state) {
      if (timelineStats.state === "TIMELINE_END") {
        $(".timeline-progress-bar .ant-progress-bg").css("transition", "none");
        setTimelineProgressPercent(0);
      } else if (timelineStats.state === "TIMELINE_START") {
          $(".timeline-progress-bar .ant-progress-bg").css("transition", "");
          $(".timeline-progress-bar .ant-progress-bg").css("transition-duration", `${timelineTotalDuration}s`);
          setTimelineProgressPercent(100);
      }
    }

  }, [timelineTotalDuration, timelineStats.state]);

  return (
    visible && <>
      <Modal className="comp-TimelinePreviewPopup" title={null} open={visible} okText={t("Save")}
             destroyOnClose={true} maskClosable={false} onOk={handleOk} onCancel={handleCancel} width={"1200px"}
             bodyStyle={{height: window.innerHeight - 90, paddingBottom: 0}}
             style={{top: 5, left: 5, maxWidth: "1200px"}}>
        <div className={"edit-container"}>
          <div className={"preview-side"}>
            <FullScreen handle={fullScreenHandle}>
              <div className={"preview-header"}>
                {/*<TemplateFormatSelector template={currentTemplateData} previewFormat={previewFormat}*/}
                {/*                        setPreviewFormat={setPreviewFormat}/>*/}

                <FaExpandArrowsAlt className={"full-screen-icon"} onClick={fullScreenHandle.enter}/>
              </div>
              <Divider style={{margin: "10px 0"}}/>

              {<div className={`f-${timeline.format}`}>
                <div className={`comp-timeline-preview f-${timeline.format}`}>
                  {showTimelinePreview && <TimelinePreview changeEmitter={changeEmitterHandler}
                                                           previewFormat={timeline.format}
                                                           timelineData={currentTimelineData}
                                                           animation={animation}
                                                           setRefreshTimeline={setRefreshTimeline}
                  />}
                  <div className={"timeline-progress-bar"}>
                    <Progress strokeLinecap="square" percent={timelineProgressPercent} showInfo={false}/>
                  </div>
                </div>
              </div>
              }
            </FullScreen>


          </div>
          <div className={"form-side"}>
            <h2>{currentTimelineData.name}</h2>
            <div className={"timeline-actions"}>
              <Button onClick={(e) => {
                copy(`${backendCfg.frontendUrl}/timeline/${timeline.uuid}`);
              }}>{t("Copy direct url")}</Button>
            </div>

            <div className={"timeline-visual-blocks"}>
              {timeline.templates.map((item, index) => {
                // const singleTemplateData = _.find(fullTemplateData, {uuid: item.templateId})
                const isCurrent = timelineStats.currentSlide - 1 === index;

                return <div key={`${index}${item.templateId}`} className={"template-block " + (isCurrent ? "current" : "")}>
                  <Image src={
                    `${backendCfg.screenshotsUrl
                      .replace("[[TEMPLATE]]", item.template)
                      .replace("[[TYPE]]", "preview")
                      .replace("[[UUID]]", item.templateId)
                    }`
                  }/>

                  <div
                    className={"template-label"}>{index + 1}) {item.template} {((isCurrent && currentTemplateDuration) ? `[${currentTemplateDuration}]` : "")}</div>
                  <div>
                    {item?.homeName && `(${item.homeName} vs ${item.awayName})`}
                  </div>
                </div>
              })}
            </div>

            <div className={"timeline-infos"}>
              <p>Format: {timeline.format}</p>
              <p>Current slide: {timelineStats.currentSlide}/{timelineStats.totalSlides}</p>
              {/*<p>Current template: {timelineStats.currentTemplate}</p>*/}
              <p>Current duration: {timelineStats.currentDuration}s</p>
              <p>Total duration (incl. in/out animations): {timelineTotalDuration}s</p>
            </div>

          </div>
        </div>
      </Modal>
    </>
  );

};

export default TimelineEditPopup;

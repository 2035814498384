import React, {useEffect, useState} from "react";
import {getAudios, getImages} from "../../../../services/media.service"
import _ from 'lodash';
import parseMediaUrl from "../../functions/parseMediaUrl";
import {FaPlusSquare} from 'react-icons/fa';

import MediaDescriptionRender from "./MediaDescriptionRender";
import {deleteMediaByUuid} from "../../../../services/backend/api.service";
import {notification} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import EditMedia from "./EditMedia";
import {useGlobalStore} from "../../../../stores/globalBackendStore";

export default (props) => {
  const {t} = useTranslation();
  const apiSetup = useGlobalStore(state => state.apiSetup);
  const [refreshData, setRefreshData] = useState(false);
  const {group, onSelect, initialActiveGroup, visibleEdit, setVisibleEdit, editData, setEditData} = props;
  const [images, setImages] = useState([]);

  const refreshDataList = ({group}) => {
    getAudios({group}).then((data) => {
      const apiItems = _.get(data, "data.docs", []);

      if (group == "_default") {
        setImages(apiItems);
      } else {
        const filtered = _.filter(apiItems, (item) => {
          if (initialActiveGroup && !group) {
            return item.group === initialActiveGroup || initialActiveGroup === "0";
            // return item.group === group || _.isEmpty(group);
          } else {
            return item.group === group;
          }
        });
        setImages(filtered);
      }
    })
  };

  useEffect(() => {
    refreshDataList({group})
  }, [group]);

  const handleSelection = (e, item) => {
    props.onSelect(e, item)
  };

  useEffect(() => {
    if (refreshData) {
      refreshDataList({group})
    }
  }, [refreshData]);

  const handleDelete = async (e, item) => {
    await deleteMediaByUuid({
      uuid: item.uuid,
    }).catch((err) => {
      notification["warning"]({
        message: t('Warning'),
        description: t("Media removing problem: ") + err.message,
        placement: "topRight"
      });
    });

    refreshDataList({group});

    notification["success"]({
      message: t('Success'),
      description: t("Media has been deleted."),
      placement: "topRight"
    });
  };

  // const openEdit = (e, item) => {
  //     // props.onSelect(e, item)
  //     setEditData(item);
  //     setVisibleEdit(true);
  // }

  return <div className={"media-audios-list"}>
    {visibleEdit && <EditMedia visibleEdit={visibleEdit} setVisibleEdit={setVisibleEdit} editData={editData}
                               setRefreshData={setRefreshData} accept={_.get(apiSetup, `mediaLibrary.accept.audio`)}/>}
    {images.map((item, index) => {
      return (<div key={item.uuid + index} className={"media-item"}>
        <div className={"media-actions"}>
          <DeleteOutlined style={{color: "red"}} onClick={(e) => {
            handleDelete(e, item);
          }}/>

          {/*<EditOutlined onClick={(e) => {*/}
          {/*    openEdit(e, item);*/}
          {/*}}/>*/}
        </div>
        <div className={"media-item-av"}>
          <FaPlusSquare className={"audio-click-handler"} onClick={(e) => handleSelection(e, item)}/>
          <audio controls>
            <source src={parseMediaUrl(item.url)}/>
          </audio>
        </div>
        <div className={"media-item-data"}>
          <MediaDescriptionRender item={item}/>
        </div>
      </div>)

    })}
  </div>
};

import _ from "lodash";

const extractIds = ({fields, options}) => {
  const extracted = {
    fields: [],
    options: []
  };

  fields.forEach((item) => {
    extracted.fields.push(item.id);
  });

  options.forEach((item) => {
    extracted.options.push(item.id);
  });

  return extracted;
};

//split form values into twom groups options / fields including optional styling, in the end example value looks like this:
// {
// "value": "[FORMVALUE]",
// "styles": {[STYLING]}
// }
export default (data, {fields, options}, {updatedStyles}) => {
  const output = {};
  const extracted = extractIds({fields, options});

  _.map(data, (value, id, updated) => {
    if (extracted.fields.indexOf(id) > -1) {
      _.set(output, `fields.${id}.value`, value);
      const updatedStyling = _.get(updatedStyles, id, null);
      const currentStyling = _.get(output, `fields.${id}.styles`, {});
      _.set(output, `fields.${id}.styles`, Object.assign(currentStyling, updatedStyling))

    } else {
      _.set(output, `options.${id}.value`, value)
    }
  });

  return output;
}

import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import getContentById from "../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../_partials/Backgrounds/Index";
import TemplateBlock from "../_partials/TemplateBlock/Index";
import Player from '../_partials/Player/Index';

import {engine, fadeIn, slide} from "../../../../animations/index"
import animationScript, {animationCleanUp} from "../../../../animations/playerstats"
import ImageOrVideo from "../../../shared/components/ImageOrVideo/Index";
import Stats from "../PlayerStats/Stats";
import parseMediaUrl from "../../../shared/functions/parseMediaUrl";

require('./style/style.scss');
require('./style/animation.scss');

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef()
  };


  const {animation, data, images: Images, apiSetup, mode} = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    //     const $container = $('#' + id);
    //     $container.find('.factsList > ul > li, .factsList > ol > li, .factsList > p ').wrapInner('<div class="li-wrapper"><div class="li-wrapper-content"></div></div>');
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      prepareAnimation();
      animationReturn = animationScript({refs, data, animation, mode, id})
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);

  const templateData = _.get(data, 'data');

  const {options, fields} = templateData;
  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);

  const headline = getContentById('headline', fields);
  const teamData = getContentById('team', fields, "self");
  const teamDataStyles = _.get(teamData, "styles", {});
  const playerData = getContentById('player', fields, "self");

  const backgroundImage = getContentById('defaultBackground', options);
  const backgroundMask = getContentById('defaultBackgroundMask', options);

  return (
    <div id={id}
         className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''}`}>
      <Backgrounds bg1={parseMediaUrl(backgroundImage.url)} bg2={parseMediaUrl(backgroundMask.url)} color1={color1}
                   color2={color2}/>
      <TemplateBlock>

        <Player id={_.get(playerData, 'id', null)} ref={_.get(refs, "player")}
                image={parseMediaUrl(_.get(playerData, 'value.url', null))} primaryColor={color1}
                backgroundColor={color2} styles={_.get(playerData, 'styles', null)}/>

        <div className={"content-block"}>
          <div id="header" className={'header'}>
            <div className="team-head-block">
              {/*<div className={"team-name"}>{_.get(teamData, 'data.name', null)}</div>*/}
              <div className={"team-name"}>{headline}</div>
              <div id={teamData.id} ref={_.get(refs, "team")} className={"team-logo"}
                   style={{...teamDataStyles}}><ImageOrVideo src={parseMediaUrl(teamData.value.url)}/></div>
            </div>
            <div className="player-head-block">
              <div className={"player-number"}>{_.get(playerData, 'value.data.shirtNumber', null)}</div>
              <div className={"player-name-block"}>
                <div className={"player-name"}
                     style={{backgroundColor: color1}}>{_.get(playerData, 'value.data.firstname', null)} {_.get(playerData, 'value.data.lastname', null)}</div>
                <div className={"player-position"}
                     style={{color: color1}}>{_.get(playerData, 'value.data.position', null)}</div>
              </div>

            </div>
          </div>

          <Stats fields={fields} primaryColor={color1} backgroundColor={color2}/>
        </div>
      </TemplateBlock>

    </div>);
};

export default Index;

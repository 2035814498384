import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import _ from "lodash";

import {Form, Input, Button, Radio} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

export const InputText = (props) => {
  const {item} = props;

  return <Form.Item label={item.label} name={`${item.id}`}
                    rules={[{required: false, message: 'Please input your username!'}]} tooltip={props.tooltip}>
    <Input placeholder={item.placeholder}/>
  </Form.Item>
};

const assets = {
  _conditional: (data) => {
    const {format, apiData} = data;
    return [assets.mainbackground_1080, assets.mainbackground_1200];
  },
  mainbackground_1080: require("./videos/Background_9-16.webm"),
  mainbackground_1200: require("./videos/Background_4-5.webm")
};

export default assets;

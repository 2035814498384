import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import getContentById from "../../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";

import { engine, fadeIn, slide } from "../../../../../animations/index";
import animationScript, { animationCleanUp } from "../../../../../animations/adler/statistics";
import ImageOrVideo from "../../../../shared/components/ImageOrVideo/Index";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

import assets from "./assets";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import outroVideo16x9 from "../../../assets/outros/2023/outro_playoffs_16x9.webm";
import outroVideo4x5 from "../../../assets/outros/2023/outro_playoffs_4x5.webm";
import frontendCfg from "../../../../../config/frontend";
import { getScorersOverride } from "../../../../shared/functions/adlerScorers";
import {usePlayoffsVideo} from "../../../../shared/hooks/usePlayoffsVideo";
import {parseGoal} from "../../../../shared/utils/goals";

require("./style/style.scss");
require("./style/animation.scss");

const numerWithSeparator = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const renderScorers = (goals, overrides, prefix) => {
  return goals.map((goal, index) => {
    const override = overrides?.[(goals.length - 1) - index];
    const line = override ? override : parseGoal(goal, prefix === "goalhome");
    return <div key={prefix + index}>{line}</div>;
  });
};

const calcValue = (stat, side) => {
  let HomeValue = _.get(stat, "home", 0);
  let AwayValue = _.get(stat, "away", 0);

  if (side === "away") {
    HomeValue = _.get(stat, "away", 0);
    AwayValue = _.get(stat, "home", 0);
  }

  var result = 0,
    v1 = parseFloat(HomeValue),
    v2 = parseFloat(AwayValue),
    max = v1 + v2;

  if (v1 === 0 && v2 === 0) {
    return 50;
  }

  result = (v1 / max) * 100;
  return Math.round(result);
};

const renderStatEntryBlock = (teamStats, label, className, type, key) => {
  const stat = {
    home: _.get(teamStats, `home.${key}`),
    away: _.get(teamStats, `away.${key}`),
  };
  let homeValue = calcValue(stat, "home");
  let awayValue = calcValue(stat, "away");

  if (type === "%") {
    homeValue = _.get(stat, "home", 0);
    awayValue = _.get(stat, "away", 0);
  }

  return (
    <div className={"stat-entry-block"}>
      <div className={"stat-entry " + className}>
        <div className={"home-value"}>
          <div className={"box-bg"}>
            <div className="triangle1"></div>
            <div className="triangle2"></div>
          </div>
          <div>{Math.round(_.get(stat, "home", 0) * 10) / 10}</div>
        </div>
        <div className={"away-value"}>
          <div className={"box-bg"}>
            <div className="triangle1"></div>
            <div className="triangle2"></div>
          </div>
          <div>{Math.round(_.get(stat, "away", 0) * 10) / 10}</div>
        </div>

        <div className={"label-container"}>
          <div className={"label"}>
            {label} {type ? `(${type})` : null}
          </div>
        </div>

        <div className={"stat-line-home-container"}>
          <div className={"filler " + (homeValue == 0 ? "filler-none" : "")} style={{ width: homeValue + "%" }}>
            <div></div>
            <div></div>
          </div>
          <div className={"filler-empty"}></div>
          {/*<div className={"stats-mark-points"}>*/}
          {/*  {_.times(11, (markIndex)=> {*/}
          {/*    return <div key={`homepointer-${markIndex}`} className={`mark-${markIndex}`}></div>*/}
          {/*  })*/}
          {/*  }*/}
          {/*</div>*/}
        </div>

        <div className={"stat-line-away-container"}>
          <div className={"filler " + (awayValue == 0 ? "filler-none" : "")} style={{ width: awayValue + "%" }}>
            <div></div>
            <div></div>
          </div>
          <div className={"filler-empty"}></div>
          {/*<div className={"stats-mark-points"}>*/}
          {/*  {_.times(11, (markIndex)=> {*/}
          {/*    return <div key={`awaypointer-${markIndex}`} className={`mark-${markIndex}`}></div>*/}
          {/*  })*/}
          {/*  }*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const { animation, data, images: Images, apiSetup, mode, format, isTimeline } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  // handle outro video BEGIN
  // usePlayoffsVideo({animation, mode, isTimeline, id})
  // handle outro video END

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({ refs, data, animation, mode, id, format });
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`)
        .get(0)
        ?.addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 10;
            this.pause();
          },
          false
        );
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const goalsHome = _.get(dynamic, "goals.home");
  const goalsAway = _.get(dynamic, "goals.visitor");
  const eventData = _.get(dynamic, "game");
  const teamStats = _.get(dynamic, "teamStats");
  // const teamDataStyles = _.get(teamData, "styles", {});
  const playerData = getContentById("player", fields, "self");

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));
  const customBackground = getContentById("background", fields, "self");
  const customBackgroundStyles = _.get(customBackground, `styles.${format}`, {});

  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;

  const scorersOverride = getScorersOverride(fields);

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(
        dynamic,
        "teamSide"
      )}`}
    >
      {/*<img className={"custom-background"} src={parseMediaUrl(customBackground.url)}/>*/}

      <TemplateBlock>
        <div className={"custom-background-overflow"}>
          <div
            id={_.get(customBackground, "id", null)}
            style={customBackgroundStyles}
            className={"custom-background-container"}
          >
            <img className={"custom-background"} src={parseMediaUrl(_.get(customBackground, "value.url"))} />
          </div>
        </div>

        <Backgrounds bg1={backgroundImage} />

        {/*handle outro video BEGIN*/}
        {format === "1920x1080" && (
          <video className={"outro-optional-video"} controls={false} autoPlay={false} muted={true} loop={false}>
            <source src={outroVideo16x9}/>
          </video>
        )}

        {format === "1200x1500" && (
          <video className={"outro-optional-video"} controls={false} autoPlay={false} muted={true} loop={false}>
            <source src={outroVideo4x5}/>
          </video>
        )}
        {/*handle outro video END*/}

        <img className={"sponsors sponsor-sap"} src={assets.sap_presents} />
        <img className={"sponsors sponsor-del"} src={assets.penny_del} />

        <div className={"number-of-viewers-container"}>
          <div className={"number-of-viewers"}>
            Zuschauer: {numerWithSeparator(_.get(eventData, "numberOfViewers", 0))}
          </div>
        </div>

        <div className={"score-and-period-block"}>
          <div className={"home-scorers"}>
            {renderScorers(
              React.useMemo(() => [...goalsHome].reverse(), [goalsHome]),
              React.useMemo(() => [...(scorersOverride?.homeScorers || [])], [scorersOverride?.homeScorers]),
              "goalhome"
            )}
          </div>

          <div className={"away-scorers"}>
            {renderScorers(
              React.useMemo(() => [...goalsAway].reverse(), [goalsAway]),
              React.useMemo(() => [...(scorersOverride?.awayScorers || [])], [scorersOverride?.awayScorers]),
              "goalaway"
            )}
          </div>

          <div className={"score-container"}>
            <div>
              <div className={"score-home"}>
                <div className={"playoffs-headline"} data-score={_.get(eventData, "results.score.final.score_home", 0)}>
                  {_.get(eventData, "results.score.final.score_home", 0)}
                </div>
              </div>
              <div className={"score-separator"}></div>
              <div className={"score-away"}>
                <div
                  className={"playoffs-headline"}
                  data-score={_.get(eventData, "results.score.final.score_guest", 0)}
                >
                  {_.get(eventData, "results.score.final.score_guest", 0)}
                </div>
              </div>
            </div>
          </div>

          <div className={"periods-container " + actualTimeAliasClass}>
            {_.get(eventData, "results.score.first_period", null) && (
              <div className={"period-1-score"}>
                <div className={"box-bg"}>
                  <div className="triangle1"></div>
                  <div className="triangle2"></div>
                </div>
                <div className={"box-score"}>
                  {_.get(eventData, "results.score.first_period.score_home", null)}
                  <span className={"score-separator"}>:</span>
                  {_.get(eventData, "results.score.first_period.score_guest", null)}
                </div>
              </div>
            )}
            {_.get(eventData, "results.score.second_period", null) && (
              <div className={"period-2-score"}>
                <div className={"box-bg"}>
                  <div className="triangle1"></div>
                  <div className="triangle2"></div>
                </div>
                <div className={"box-score"}>
                  {_.get(eventData, "results.score.second_period.score_home", null)}
                  <span className={"score-separator"}>:</span>
                  {_.get(eventData, "results.score.second_period.score_guest", null)}
                </div>
              </div>
            )}
            {_.get(eventData, "results.score.third_period", null) && (
              <div className={"period-3-score"}>
                <div className={"box-bg"}>
                  <div className="triangle1"></div>
                  <div className="triangle2"></div>
                </div>
                <div className={"box-score"}>
                  {_.get(eventData, "results.score.third_period.score_home", null)}
                  <span className={"score-separator"}>:</span>
                  {_.get(eventData, "results.score.third_period.score_guest", null)}
                </div>
              </div>
            )}
            {_.get(eventData, "results.score.overtime", null) && (
              <div className={"period-4-score"}>
                <div className={"box-bg"}>
                  <div className="triangle1"></div>
                  <div className="triangle2"></div>
                </div>
                <div className={"box-score"}>
                  {_.get(eventData, "results.shooting", false) === true
                    ? _.get(eventData, "results.score.shootout.score_home", null)
                    : _.get(eventData, "results.score.overtime.score_home", null)}
                  <span className={"score-separator"}>:</span>
                  {_.get(eventData, "results.shooting", false) === true
                    ? _.get(eventData, "results.score.shootout.score_guest", null)
                    : _.get(eventData, "results.score.overtime.score_guest", null)}
                  <span className={"extra-time-info"}>
                    {_.get(eventData, "results.shooting", false) === true ? "SO" : "OT"}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={"teams-block"}>
          {/*<div className={"home-name"}>{eventData.home.shortcut}</div>*/}
          {/*<div className={"away-name"}>{eventData.away.shortcut}</div>*/}

          <div className={"team-logo-container home"}>
            <img src={parseMediaUrl(_.get(teamHome, "url", null))} />
          </div>
          <div className={"team-logo-container away"}>
            <img src={parseMediaUrl(_.get(teamAway, "url", null))} />
          </div>
        </div>

        <div className={"statistics-block"}>
          {renderStatEntryBlock(teamStats, "Schüsse", "schusseStat", null, "shotsOnGoal")}
          {renderStatEntryBlock(teamStats, "Saves", "savesStat", "%", "savesPercent")}
          {renderStatEntryBlock(teamStats, "Bullygewinne", "bullyGewinneStat", null, "faceOffsWon")}
          {renderStatEntryBlock(teamStats, "Strafminuten", "strafminutenStat", null, "penaltyMinutes")}
          {renderStatEntryBlock(teamStats, "Powerplay", "powerplayStat", "%", "ppEfficiency")}
          {renderStatEntryBlock(teamStats, "Unterzahl", "unterzahlStat", "%", "shEfficiency")}
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  if (format === "1920x1080") {
    return _.get(assets, `mainbackground_1920_${side}`);
  }

  if (format === "1200x1500") {
    return _.get(assets, `mainbackground_1200_${side}`);
  }
};

export default Index;

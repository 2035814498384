import React, {useEffect, useRef, useState} from 'react';
import {Form, Drawer, Card, Tooltip} from "antd";
import _ from "lodash";
import backendConfig from "../../../../config/backend";

import {useTranslation} from "react-i18next";
import {useGlobalStore} from "../../../../stores/globalBackendStore";
import {AimOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {axios} from "../../../../services/axios";

const {Meta} = Card;

export default function (props) {
  const {item, setDrawerVisible, onClick} = props;
  const {t} = useTranslation();
  const [prepareCompetitionTimelineProgress, setPrepareCompetitionTimelineProgress] = useState(false);
  const [preparePostMatchTimeline, setPreparePostMatchTimeline] = useState(false);

  const apiSetup = useGlobalStore(state => state.apiSetup);
  const selectedMatchday = useGlobalStore(state => state.selectedMatchday);

  const onClose = () => {
    setDrawerVisible(false);
  };

  useEffect(() => {

  }, []);

  if (_.isEmpty(apiSetup)) {
    return null;
  }

  const handlePrepareTimelines = async () => {
    setPrepareCompetitionTimelineProgress(true)
    await axios({
      url: `/actions/prepare-comp-timelines`,
      method: "post",
      timeout: 120 * 1 * 1000,
      data: {
        videoproduction: false
      }
    }).catch((err) => {
      console.error('handlePrepareTimelines', err)
      setPrepareCompetitionTimelineProgress(false)
    })

    setPrepareCompetitionTimelineProgress(false)
  }

  const handlePreparePostMatchTimeline = async () => {
    setPreparePostMatchTimeline(true)

    await axios({
      url: `/actions/prepare-post-match-timeline`,
      method: "post",
      timeout: 120 * 1 * 1000,
      data: {
        videoproduction: false,
        tournamentCalendarId: selectedMatchday?.competitionId,
        onlyLastMatchday: true
      }
    }).catch((err) => {
      console.error('handlePrepareChampionsLeagueTimeline', err)
      setPreparePostMatchTimeline(false)
    })

    setPreparePostMatchTimeline(false)
  }

  return (
    <div className={"comp-GlobalActionsDrawer"}>
      <Drawer
        rootClassName={"comp-GlobalActionsDrawer-container"}
        title={t("Global Actions")}
        placement={"right"}
        closable={true}
        onClose={onClose}
        open={props.visible}
        width={300}
      >

        {/*<Tooltip placement="left" title={'Prepare timelines for all games, based on competition and POST-MATCH timeline. Video will be automatically produced.'}>*/}
        {/*{backendConfig.clientBodyClass !== 'app-adler' && <div className={"main-button-worldcup"} onClick={handlePrepareTimelines}>*/}
        {/*  {prepareCompetitionTimelineProgress ? <AimOutlined spin/> : <PlusCircleOutlined/>} {t("Prepare Competition Timelines")}*/}
        {/*</div>}*/}
        {/*</Tooltip>*/}

        <Tooltip placement="left"
                 title={'Prepare timeline, based on selected competition and POST-MATCH for last Matchday relative to current date.'}>
          {backendConfig.clientBodyClass !== 'app-adler' &&
            <div className={"main-button-worldcup"} onClick={handlePreparePostMatchTimeline}>
              {preparePostMatchTimeline ? <AimOutlined spin/> :
                <PlusCircleOutlined/>} {t("Prepare Post-Match Timeline")}
            </div>}
        </Tooltip>

      </Drawer>
    </div>
  );
}

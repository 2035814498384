import React, {useState, useEffect, useMemo} from "react";

import downloadFile from "../../../shared/functions/downloadFile";
import {PoweroffOutlined} from '@ant-design/icons';

import ShareDrawer from "./ShareDrawer"
import {asyncTimeout} from "../../../shared/functions/timeout";

import BackendConfig from '../../../../config/backend';

import {Menu, Dropdown, Divider, Button, Modal, Popconfirm, notification} from 'antd';
import {
  MenuOutlined,
  AppstoreOutlined,
  CheckOutlined,
  InstagramOutlined,
  UpOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  ShareAltOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
  CameraOutlined,
  EditOutlined,
  AimOutlined
} from '@ant-design/icons';
import CreateTemplateDrawer from "./CreateTemplateDrawer"
import CreateTimelineDrawer from "./CreateTimelineDrawer"
import GlobalActionsDrawer from "./GlobalActionsDrawer"

import Image from "../../../shared/components/Image";


import {useTranslation} from 'react-i18next';
import {useGlobalStore} from '../../../../stores/globalBackendStore';

import TemplateEditPopup from "./TemplateEditPopup"
import {
  getTemplateById,
  getTemplatesByMatchday,
  deleteTemplateById,
  apiPrepareMatchday
} from "../../../../services/backend/api.service"

import backendCfg from "../../../../config/backend";

import _ from "lodash";
import {produceVideo} from "../../../../services/backend/bvr.service";
import Loader from "../../../shared/components/Loader";
import backendConfig from "../../../../config/backend";

const {SubMenu} = Menu;

const submenuPreventClick = (e) => {
  e.domEvent.stopPropagation();
};

const TemplateMenu = (props) => {
  const {
    template,
    indexKey,
    setDropdownVisible,
    dropdownVisible,
    setReloadTemplatesList,
    apiTemplateSetup,
    setShareDrawerVisible,
    templateEdit,
    setTemplateEdit,
    setCurrentTemplate
  } = props;

  const {t} = useTranslation();

  const apiSetup = useGlobalStore(state => state.apiSetup);
  const apiSelectedMatchday = useGlobalStore(state => state.selectedMatchday);

  const onClick = async (menuItem, actionType, data) => {
    setCurrentTemplate(template);

    if (menuItem.domEvent) {
      menuItem.domEvent.stopPropagation();
    } else {
      // menuItem.stopPropagation();
    }

    // const node = menuItem.node;
    // console.info(`Click on item ${menuItem}`, menuItem);
    // console.info(`Click on item ${actionType}`);

    // console.log("menuItem, actionType", menuItem, actionType);
    //
    // console.log("%%%%apiSetup", apiSetup);
    // console.log("%%%%apiSelectedMatchday", apiSelectedMatchday);

    if (actionType === "edit") {
      setTemplateEdit(true);
    }

    if (actionType === "open-template-url") {
      const {template, format} = data;
      window.open(`${backendCfg.frontendUrl}/template/${template.template_name}/${template.uuid}?format=${format}`, "_blank")
    }

    if (actionType === "download_screenshot") {
      const {template, format} = data;
      downloadFile(`${backendCfg.screenshotsUrl
        .replace("[[TEMPLATE]]", template.template_name)
        .replace("[[TYPE]]", `download_${format}`)
        .replace("[[UUID]]", template.uuid)
      }?action=download&filename=${template.name}.jpg`);
    }

    if (actionType === "produce_video") {
      const {template, format, lng} = data;
      await produceVideo({template, format, apiSetup, apiSelectedMatchday, lng}).catch((err) => {
        console.error('err', err);
      });

      setReloadTemplatesList({
        reload: true
      })
    }

    if (actionType === "download_video") {
      const {template, format, type} = data;
      const videoFormatFileUrl = _.get(template, `data.videos.${format}.file`, null);

      if (videoFormatFileUrl) {
        downloadFile(`${videoFormatFileUrl}?action=download&filename=${template.name}_[${format}].mp4`);
      } else {
        notification["warning"]({
          message: t('Video download'),
          description: t("Selected format has been not yet produced. You need to produce it first.")
        });
      }
    }

    if (actionType === "remove") {
      await deleteTemplateById({uuid: template.uuid}).catch((err) => {
        console.error('err', err);
      });

      setReloadTemplatesList({
        reload: true
      })
    }

    if (actionType === "share") {
      const {template, format, type} = data;
      setShareDrawerVisible({
        visible: true,
        data: {template, format, type}
      })
    }

    dropdownVisible[indexKey] = false;
    setDropdownVisible([...dropdownVisible])
  };

  function handleBodyClick(event) {
    setDropdownVisible(prev => prev.map(() => false))
  }

  useEffect(() => {
    if (dropdownVisible[indexKey]) {
      document.body.addEventListener("click", handleBodyClick);
    }

    return () => document.body.removeEventListener("click", handleBodyClick);
  }, [dropdownVisible[indexKey]]);

  const availableFormats = _.get(apiTemplateSetup, "formats", []);

  return (<>
    <Menu className={"template-menu-nav"} selectable={false}>
      <Menu.Item onClick={(menuInfo) => {
        onClick(menuInfo, "edit", {
          template
        })
      }} key={"edit_" + indexKey} className={"menu-entry"} icon={<EditOutlined/>}>
        {t("Edit")}
      </Menu.Item>
      <Menu.Divider/>
      <SubMenu onTitleClick={submenuPreventClick} title={t("Produce video")} key={"produce_video_" + indexKey}
               className={"menu-entry"} icon={<VideoCameraAddOutlined/>}>
        <SubMenu onTitleClick={submenuPreventClick} title={t("en")} key={"produce_video_en_" + indexKey}>
          {availableFormats.map((thisFormat) => {
            return <Menu.Item key={"produce_video_en_" + thisFormat} onClick={(menuInfo) => {
              onClick(menuInfo, "produce_video", {
                format: thisFormat,
                template,
                lng: 'en'
              })
            }}>{thisFormat}</Menu.Item>
          })}
        </SubMenu>

        <SubMenu onTitleClick={submenuPreventClick} title={t("de")} key={"produce_video_de_" + indexKey}>
          {availableFormats.map((thisFormat) => {
            return <Menu.Item key={"produce_video_de_" + thisFormat} onClick={(menuInfo) => {
              onClick(menuInfo, "produce_video", {
                format: thisFormat,
                template,
                lng: 'de'
              })
            }}>{thisFormat}</Menu.Item>
          })}
        </SubMenu>
      </SubMenu>
      <Menu.Divider/>
      <SubMenu onTitleClick={submenuPreventClick} title={t("Download screenshot")}
               key={"download_screenshot_" + indexKey}
               className={"menu-entry"} icon={<CameraOutlined/>}>

        {availableFormats.map((thisFormat) => {
          return <Menu.Item key={"download_screenshot_" + thisFormat} onClick={(menuInfo) => {
            onClick(menuInfo, "download_screenshot", {
              format: thisFormat,
              template
            })
          }}>{thisFormat}</Menu.Item>
        })}
      </SubMenu>
      <SubMenu onTitleClick={submenuPreventClick} title={t("Download video")} key={"download_video_" + indexKey}
               className={"menu-entry"}
               icon={<VideoCameraOutlined/>}>
        {availableFormats.map((thisFormat) => {
          return <Menu.Item key={"download_video_" + thisFormat} onClick={(menuInfo) => {
            onClick(menuInfo, "download_video", {
              format: thisFormat,
              template
            })
          }}>{thisFormat}</Menu.Item>
        })}
      </SubMenu>
      <Menu.Divider/>
      <SubMenu onTitleClick={submenuPreventClick} title={t("Open in tab")} key={"open_template_url_" + indexKey}
               className={"menu-entry"} icon={<VideoCameraAddOutlined/>}>

        {availableFormats.map((thisFormat) => {
          return <Menu.Item key={"open_template_url_" + thisFormat} onClick={(menuInfo) => {
            onClick(menuInfo, "open-template-url", {
              format: thisFormat,
              template
            })
          }}>{thisFormat}</Menu.Item>
        })}

      </SubMenu>
      <SubMenu onTitleClick={submenuPreventClick} title={t("Share")} key={"share_social_" + indexKey}
               className={"menu-entry"}
               icon={<ShareAltOutlined/>}>
        <Menu.Item onClick={(menuInfo) => {
          onClick(menuInfo, "share", {type: "instagram", template})
        }} key={"share_instagram"}><InstagramOutlined/> Instagram</Menu.Item>
      </SubMenu>
      <Menu.Divider/>

      <Menu.Item key={"remove_" + indexKey} className={"menu-entry action-remove"} icon={<DeleteOutlined/>}
                 onClick={(menuInfo) => {
                   // e.stopPropagation();
                   onClick(menuInfo, "remove")
                 }}>
        {t("Remove")}

        {/*<Popconfirm placement="left" title={t("Are you sure to remove this item?")} onConfirm={(menuInfo) => {*/}
        {/*    // menuInfo.domEvent.stopImmediatePropagation();*/}
        {/*    onClick(menuInfo, "remove")*/}
        {/*}} onClick={(e) => {*/}
        {/*    e.stopPropagation();*/}
        {/*}} okText="Yes" cancelText="No">*/}
        {/*    {t("Remove")}*/}
        {/*</Popconfirm>*/}
      </Menu.Item>

    </Menu>
  </>)
};

let interval = null;

const TemplatesList = (props) => {
  const {
    templatesList,
    dropdownVisible,
    setDropdownVisible,
    setReloadTemplatesList,
    reloadTemplatesList,
    setShareDrawerVisible
  } = props;

  const apiSetup = useGlobalStore(state => state.apiSetup);

  const [refreshVideoStatus, setRefreshVideoStatus] = useState(false);
  const [templateEdit, setTemplateEdit] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState();

  useEffect(() => {
    if (refreshVideoStatus) {
      if (!interval) {
        interval = setInterval(() => {
          setReloadTemplatesList({
            reload: true
          })
        }, backendCfg.refreshVideoInterval);
      }
      setRefreshVideoStatus(false);
    } else {
      if (interval) {
        clearInterval(interval);
        setReloadTemplatesList({
          reload: false
        });
        interval = null;
      }
    }

  }, [refreshVideoStatus]);
  //
  // useEffect(() => {
  //   const reloadTemplate
  //
  // }, [])

  let counters = {};

  return <div className="dashboard-templates-list">
    {currentTemplate &&
      <TemplateEditPopup key={currentTemplate.uuid + currentTemplate.updatedAt} visible={templateEdit}
                         setVisible={setTemplateEdit} template={currentTemplate}
                         initialTemplateData={_.cloneDeep(currentTemplate)}
                         setReloadTemplatesList={setReloadTemplatesList}/>}

    {templatesList.map((template, index) => {
      const apiTemplateSetup = _.find(apiSetup.templates, {name: template.template_name});
      const availableFormats = _.get(apiTemplateSetup, "formats", []);

      if (!counters[template.template_name]) {
        counters[template.template_name] = 1;
      } else {
        counters[template.template_name] = ++counters[template.template_name];
      }

      availableFormats.forEach((thisFormat) => {
        const formatStatus = _.get(template, `data.videos.${thisFormat}.status`, null);
        if (formatStatus == "sent" && !refreshVideoStatus) {
          setRefreshVideoStatus(true);
        }
      });

      const updatedAtPrefix = new Date(template.updatedAt).getTime();
      let hashUpdate = "";

      if (updatedAtPrefix > (Date.now() - 1000 * 15)) {
        hashUpdate = Date.now();
      }

      return <div key={template.uuid + template.updatedAt} className={"template-block"} onClick={(e) => {
        setCurrentTemplate(template);
        setTemplateEdit(true);
      }}>
        <div className={"video-indicators"}>
          {availableFormats.map((thisFormat) => {
            const formatStatus = _.get(template, `data.videos.${thisFormat}.status`, "no-status");
            return <div key={template.uuid + thisFormat}
                        className={"format " + thisFormat + " " + formatStatus}>{thisFormat}</div>
          })}
        </div>
        <div className={"template-image"}>
          <Image src={
            `${backendCfg.screenshotsUrl
              .replace("[[TEMPLATE]]", template.template_name)
              .replace("[[TYPE]]", "preview")
              .replace("[[UUID]]", template.uuid)
            }?updated_at=${updatedAtPrefix}-${hashUpdate}`
          }/>
        </div>
        <div
          className={"template-label"}> {++index}. {template.name} #{counters[template.template_name]}

          <Dropdown className={"template-menu"}
                    key={template.uuid + _.get(dropdownVisible, index, false)}
            // key={Math.random()}
                    overlay={<TemplateMenu template={template} setCurrentTemplate={setCurrentTemplate}
                                           indexKey={index}
                                           setDropdownVisible={setDropdownVisible}
                                           dropdownVisible={dropdownVisible}
                                           setReloadTemplatesList={setReloadTemplatesList}
                                           apiTemplateSetup={apiTemplateSetup}
                                           setShareDrawerVisible={setShareDrawerVisible}
                                           setTemplateEdit={setTemplateEdit}
                    />}
                    placement="bottomLeft"
                    trigger={['click']} open={_.get(dropdownVisible, index, false)}
                    onClick={(e) => {
                      e.stopPropagation();
                    }
                    }>

            <a className="ant-dropdown-link" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              let tempArr = [...dropdownVisible];

              tempArr = tempArr.filter((currentState, currentIndex) => {
                if (currentIndex === index) {
                  return true;
                } else {
                  return currentState != true
                }
              });

              if (tempArr[index] === true) {
                tempArr[index] = false;
              } else {
                tempArr[index] = true;
              }

              setDropdownVisible([...tempArr])
            }}>
              <MenuOutlined/>
            </a>
          </Dropdown>
        </div>
      </div>
    })}
  </div>
};

const Home = (props) => {
    const matchday = useGlobalStore(state => state.selectedMatchday);
    const globalMenuCollapse = useGlobalStore(state => state.globalMenuCollapse);
    const {t} = useTranslation();
    const {
      templatesList,
      setReloadTemplatesList,
      reloadTemplatesList,
      setReloadTimelinesList,
      reloadTimelinesList,
      timelinesList,
      setTimelinesList
    } = props;
    let templatesByGroup = {};

    const [templateEdit, setTemplateEdit] = useState(false);
    const [templateEditData, setTemplateEditData] = useState(null);
    const [prepareMatchdayHandlerProgress, setPrepareMatchdayHandlerProgress] = useState(false);

    const [dropdownVisible, setDropdownVisible] = useState([]);
    const [templateDrawerVisible, setTemplateDrawerVisible] = useState(false);
    const [globalActionsDrawerVisible, setGlobalActionsDrawerVisible] = useState(false);
    const [shareDrawerVisible, setShareDrawerVisible] = useState({
      visible: false,
      data: null
    });

    const [timelineVisibleDrawer, setTimelineVisibleDrawer] = useState(false);

    const newTemplateClick = (e, {id, template_name}) => {
      getTemplateById({id, template_name}).then((data) => {
        const apiData = _.get(data, "data", {});
        setTemplateEditData(apiData);
        setTemplateEdit(true);
      })
    };

    const globalActions = (e, {id, template_name}) => {
      console.log('GLOBAL ACTIONS');
    };

    const prepareMatchdayHandler = (e) => {
      e.preventDefault();
      if (!prepareMatchdayHandlerProgress) {
        setPrepareMatchdayHandlerProgress(true)

        apiPrepareMatchday({
          competitionId: matchday?.competitionId,
          eventId: matchday?.eventId,
          home: matchday?.home,
          away: matchday?.away,
        }).then((data) => {
          setPrepareMatchdayHandlerProgress(false)
        }).catch((err) => {
          console.error('err', err);
          setPrepareMatchdayHandlerProgress(false)
        })
      }


    };

    useEffect(() => {

    }, [templatesList]);

    return (<>
        <header className="jumbotron template-list-header">
          <h2>{t("Game templates")} ({templatesList.length})</h2>
          <div className={"overview-main-buttons"}>
            {<div className={"main-button-worldcup"} onClick={prepareMatchdayHandler}>
              {prepareMatchdayHandlerProgress ? <AimOutlined spin/> : <PlusCircleOutlined/>} {t("Prepare Matchday")}
            </div>}

            <div className={"zenga-button-1"} onClick={(e) => {
              setTemplateDrawerVisible(true);
            }}><PlusCircleOutlined/> {t("Create template")}</div>

            <div className={"zenga-global-actions"} onClick={(e) => {
              setGlobalActionsDrawerVisible(true);
            }}><MenuOutlined/></div>

            <CreateTemplateDrawer onClick={newTemplateClick} visible={templateDrawerVisible}
                                  setDrawerVisible={setTemplateDrawerVisible}/>

            <ShareDrawer onClick={newTemplateClick} visible={shareDrawerVisible.visible}
                         data={shareDrawerVisible.data} setDrawerVisible={setShareDrawerVisible}/>

            <GlobalActionsDrawer onClick={globalActions} visible={globalActionsDrawerVisible}
                                 setDrawerVisible={setGlobalActionsDrawerVisible}/>

          </div>

        </header>

        {/*//only for new record*/}
        {templateEditData &&
          <TemplateEditPopup key={`${templateEditData.uuid}_${templateEditData.template_name}`} visible={templateEdit}
                             setVisible={setTemplateEdit} template={templateEditData}
                             initialTemplateData={_.cloneDeep(templateEditData)}
                             setReloadTemplatesList={setReloadTemplatesList}/>}

        {/*{useMemo(() => <div className="dashboard-templates-list">*/}


        <TemplatesList reloadTemplatesList={reloadTemplatesList} templatesList={templatesList}
                       dropdownVisible={dropdownVisible} setDropdownVisible={setDropdownVisible}
                       setReloadTemplatesList={setReloadTemplatesList}
                       setShareDrawerVisible={setShareDrawerVisible}/>

        <CreateTimelineDrawer
          visibleTimeline={timelineVisibleDrawer}
          // templatesList={templatesList}
          timelinesList={timelinesList}
          setTimelinesList={setTimelinesList}
          setReloadTimelinesList={setReloadTimelinesList}
          onClose={() => {
            setTimelineVisibleDrawer(false);
            $('body').css('overflow', '');
          }}/>
        {/*<CreateTimelinePickerDrawer visible={timelinePickerDrawerVisible}  onClose={() => {*/}
        {/*    setTimelineDrawerVisible(false);*/}
        {/*    setTimelinePickerDrawerVisible(false);*/}
        {/*}}/>*/}

        <div className={"create-timeline-footer"} style={globalMenuCollapse ? {left: 0} : {}}>
          <div className={"link create-timeline-container"} onClick={(e) => {
            setTimelineVisibleDrawer(true);
            $('body').css('overflow', 'hidden');
            $(document).scrollTop(0);
          }}>
            <UpOutlined/>
            <div className={"create-timeline-button"}>{t("Saved timelines")}</div>
          </div>

        </div>

        {/*<Button >{t("Create timeline")}</Button>*/}
      </>
    );
  }
;


export default Home;

import _ from "lodash";
import interact from "interactjs";

const previewScaleByFormat = {
  "1920x1080": 0.35, "1200x1500": 0.35, "1080x1920": 0.35
};

const dragMoveListener = (event, scale) => {
  var target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute('data-x')) || 0) + (event.dx / scale);
  var y = (parseFloat(target.getAttribute('data-y')) || 0) + (event.dy / scale);

  // translate the element
  target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

  // update the posiion attributes
  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y)
};

const resizeListener = (event, scale) => {
  var target = event.target;
  var x = (parseFloat(target.getAttribute('data-x')) || 0);
  var y = (parseFloat(target.getAttribute('data-y')) || 0);

  // update the element's style
  target.style.width = event.rect.width / scale + 'px';
  target.style.height = event.rect.height / scale + 'px';

  // translate when resizing from top or left edges
  x += event.deltaRect.left / scale;
  y += event.deltaRect.top / scale;

  target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px,' + y + 'px)';

  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y)
  // target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height);
};

export const getElementsToInit = (fields) => {
  const output = [];

  fields.forEach((item) => {
    if (_.get(item, 'dragResize', false)) {
      output.push({
        elementId: item.id, type: ['resize', 'drag']
      });
    } else if (_.get(item, 'drag', false)) {
      output.push({
        elementId: item.id, type: ['drag']
      });
    }
  });

  return output;
};

export const unmountInteract = (currentTemplateData) => {
  const fields = _.get(currentTemplateData, "data.fields");
  fields.forEach((item, index) => {
    if (item.dragResize) {
      interact(`.comp-template-preview #${item.id}`).unset();
    }

    if (item.drag) {
      interact(`.comp-template-preview #${item.id}`).unset();
    }
  })
};

export const resetMoveResize = ({currentTemplateData, newStylingData, setNewStylingData, initialTemplateData}) => {
  const elementsToInit = getElementsToInit(_.get(currentTemplateData, "data.fields"));
  elementsToInit.map((item) => {
    const styles = _.get(initialTemplateData, `data.fields.${item.elementId}.styles`, {});

    $(`.comp-template-preview #${item.elementId}`).css('transform', 'translate(0, 0)');
    $(`.comp-template-preview #${item.elementId}`).attr('data-x', 0);
    $(`.comp-template-preview #${item.elementId}`).attr('data-y', 0);


  });
};

export const initInteract = (currentTemplateData, newStylingData = {}, setNewStylingData, previewFormat) => {
  const scale = _.get(previewScaleByFormat, previewFormat, 1);
  const fields = _.get(currentTemplateData, "data.fields");
  const elementsToInit = getElementsToInit(fields);
  const initStylingData = {};

  fields.map((elem) => {
    if (!newStylingData?.[`${elem.id}`]) {
      _.set(newStylingData, `${elem.id}`, elem?.styles || {});
    }
  });

  setNewStylingData({
    ...newStylingData
  });

  elementsToInit.map(({elementId, type = ['resize', 'drag']}) => {
    // const currentFieldData = _.get(_.find(fields, {id: elementId}), `styles.${previewFormat}`, {});


    if (type.indexOf('resize') > -1) {
      $(`.comp-template-preview #${elementId}`).addClass("init-resizable");

      interact(`.comp-template-preview #${elementId}`).resizable({
        // resize from all edges and corners
        edges: {
          left: true, right: true, bottom: true, top: true,
        }, listeners: {
          move: (e) => {
            resizeListener(e, scale)
          }, end: (e) => {
            const target = e.target;
            const x = parseFloat(target.getAttribute('data-x')) || null;
            const y = parseFloat(target.getAttribute('data-y')) || null;

            const elementStyling = {};

            const cssLeft = parseFloat($(target).css("left"));
            const cssTop = parseFloat($(target).css("top"));

            if (x) {
              _.set(elementStyling, "left", x + cssLeft)
            }

            if (y) {
              _.set(elementStyling, "top", y + cssTop)
            }

            if (e.rect.width) {
              _.set(elementStyling, "width", e.rect.width / scale)
            }

            if (e.rect.height) {
              _.set(elementStyling, "height", e.rect.height / scale)
            }

            _.set(initStylingData, `${elementId}.${previewFormat}`, elementStyling);

            const toUpdate = {
              ...newStylingData, ...initStylingData, [elementId]: {
                ...newStylingData[elementId], ...initStylingData[elementId],
              }
            };

            setNewStylingData({
              ...toUpdate
            })
          }
        }, inertia: true, modifiers: [interact.modifiers.aspectRatio({
          // make sure the width is always double the height
          ratio: "preserve", // also restrict the size by nesting another modifier
          modifiers: [// interact.modifiers.restrictSize({max: 'parent'}),
          ]
        }), // keep the edges inside the parent
          // interact.modifiers.restrictEdges({
          //   outer: 'parent'
          // }),
        ]
      });

    }

    if (type.indexOf('drag') > -1) {
      $(`.comp-template-preview #${elementId}`).addClass("init-movable");

      interact(`.comp-template-preview #${elementId}`)
        .draggable({
          listeners: {
            move: (e) => {
              dragMoveListener(e, scale)
            }, end: (e) => {
              // console.log("draggable e.getBoundingClientRect()", e.target.getBoundingClientRect());

              const target = e.target;

              const x = parseFloat(target.getAttribute('data-x')) || null;
              const y = parseFloat(target.getAttribute('data-y')) || null;

              const elementStyling = {};

              const cssLeft = parseFloat($(target).css("left"));
              const cssTop = parseFloat($(target).css("top"));

              if (x) {
                _.set(elementStyling, "left", x + cssLeft)
              }

              if (y) {
                _.set(elementStyling, "top", y + cssTop)
              }

              if (e.rect.width) {
                _.set(elementStyling, "width", e.rect.width / scale)
              }

              if (e.rect.height) {
                _.set(elementStyling, "height", e.rect.height / scale)
              }

              _.set(initStylingData, `${elementId}.${previewFormat}`, elementStyling);

              const toUpdate = {
                ...newStylingData, ...initStylingData, [elementId]: {
                  ...newStylingData[elementId], ...initStylingData[elementId],
                }
              };

              setNewStylingData({
                ...toUpdate
              })
            }
          }, inertia: true, modifiers: [interact.modifiers.restrictRect({
            // restriction: '.comp-template-preview .template-container',
            endOnly: true, // elementRect: {top: 0.15, left: 0.15, bottom: 0.85, right: 0.85}
          })]
        })
    }
  })

};

// export const initMoveResize = ({currentTemplateData, newStylingData, setNewStylingData, previewFormat}) => {
//   const previewScaleByFormat = {
//     "1920x1080": 0.35
//   }
//   const scale = _.get(previewScaleByFormat, previewFormat, 1);
//   const elementsToInit = getElementsToInit(_.get(currentTemplateData, "data.fields"));
//
//   elementsToInit.map((elementId) => {
//     // interact($(`.comp-template-preview #${elementId}`)).unset();
//
//     if (!$(`.comp-template-preview #${elementId}`).hasClass("init-resizable")) {
//       $(`.comp-template-preview #${elementId}`).addClass("init-resizable");
//
//       interact(`#${elementId}`).resizable({
//         // resize from all edges and corners
//         edges: {left: false, right: true, bottom: true, top: false},
//
//         listeners: {
//           end(event) {
//             const target = event.target;
//
//             const x = parseFloat(target.getAttribute('data-x')) || null;
//             const y = parseFloat(target.getAttribute('data-y')) || null;
//
//             const elementStyling = {};
//
//             if (x) {
//               _.set(elementStyling, "left", x)
//             }
//
//             if (y) {
//               _.set(elementStyling, "top", y)
//             }
//
//             if (event.rect.width) {
//               _.set(elementStyling, "width", event.rect.width)
//             }
//
//             if (event.rect.height) {
//               _.set(elementStyling, "height", event.rect.height)
//             }
//
//             setNewStylingData({
//               ...newStylingData,
//               [elementId]: elementStyling
//             })
//           },
//           move(event) {
//             // debugger;
//             const target = event.target;
//             let x = parseFloat(target.getAttribute('data-x')) || 0;
//             let y = parseFloat(target.getAttribute('data-y')) || 0;
//
//             // translate when resizing from top or left edges
//             x += event.deltaRect.left * (1 / scale);
//             y += event.deltaRect.top * (1 / scale);
//
//             target.setAttribute('data-x', x);
//             target.setAttribute('data-y', y);
//
//             Object.assign(target.style, {
//               width: (event.rect.width * (1 / scale)) + 'px',
//               height: (event.rect.height * (1 / scale)) + 'px',
//               transform: `translate(${x}px, ${y}px)`
//             })
//           }
//         },
//         inertia: true,
//         modifiers: [
//           interact.modifiers.aspectRatio({
//             // make sure the width is always double the height
//             ratio: "preserve",
//             // also restrict the size by nesting another modifier
//             modifiers: [
//               interact.modifiers.restrictSize({max: 'parent'}),
//             ],
//           }),
//         ],
//       })
//
//     }
//
//     if (!$(`.comp-template-preview #${elementId}`).hasClass("init-movable")) {
//       $(`.comp-template-preview #${elementId}`).addClass("init-movable");
//
//       interact(`#${elementId}`)
//         .draggable({
//           onend: function (event) {
//             const target = event.target;
//
//             const x = parseFloat(target.getAttribute('data-x')) || null;
//             const y = parseFloat(target.getAttribute('data-y')) || null;
//
//             const elementStyling = {};
//
//             if (x) {
//               _.set(elementStyling, "left", x)
//             }
//
//             if (y) {
//               _.set(elementStyling, "top", y)
//             }
//
//             setNewStylingData({
//               ...newStylingData,
//               [elementId]: elementStyling
//             })
//           },
//           onmove: function (event) {
//             const target = event.target;
//
//             const dataX = target.getAttribute('data-x');
//             const dataY = target.getAttribute('data-y');
//             const initialX = parseFloat(dataX) || 0;
//             const initialY = parseFloat(dataY) || 0;
//
//             const deltaX = event.dx * (1 / scale);
//             const deltaY = event.dy * (1 / scale);
//
//             const newX = initialX + deltaX;
//             const newY = initialY + deltaY;
//
//             target
//               .style
//               .transform = `translate(${newX}px, ${newY}px)`;
//
//             target.setAttribute('data-x', newX);
//             target.setAttribute('data-y', newY);
//           }
//         })
//     }
//
//
//   })
// }

import React, {useEffect} from 'react';
import {Drawer} from "antd";
import {useTranslation} from "react-i18next";
import backendConfig from "../../../config/backend/index";

export default function (props) {
  const {setState} = props;
  const {t} = useTranslation();

  useEffect(() => {

  }, []);

  const liveLink = backendConfig.clientBodyClass === 'app-opta' ? "https://fixtur.es/en/fifa-world-cup-qatar" : "https://www.penny-del.org"

  return (
    <div className={"comp-LiveWidgetDrawer"}>
      <Drawer
        title={<div>{t("Match center")} (<a href={liveLink} target="_black">Data source</a>)</div>}
        placement={"top"}
        closable={true}
        onClose={props.onClose}
        open={props.visible}
        height={"100%"}
        getContainer={null}
      >

        {<div className={"widget-container"} style={{height: "100%"}}>
          {/*<iframe src="https://www.365scores.com/de/football/germany/2.-bundesliga/match/hannover-holstein-kiel/340-430-26/stats#id=2383765" width="100%" height="100%" frameBorder="0">Browser not compatible.</iframe>*/}
          <iframe src={liveLink} width="100%" height="100%" frameBorder="0">Browser not
            compatible.
          </iframe>
        </div>}
      </Drawer>
    </div>
  );
}

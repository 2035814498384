import React, {useEffect} from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from '../../../../shared/functions/prepareVideoBg';
import animationScript, {animationCleanUp} from "../../../../../animations/worldcup/lineup_tactics"
import PitchTactics from "../../_partials/PitchTactics/Index";

import assets from './assets';
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import countries from "../../../../../language/de/countries.json";
import {useTranslation} from "react-i18next";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";
import Image from "../../../../shared/components/Image"
import {toIsoDateString} from "../../../../shared/utils/functions";

require('./style/style.scss');
require('./style/animation.scss');

const translations = {
  'Group': 'Gruppe'
};

const Index = (props) => {
  const refs = {
    player: React.createRef(), team: React.createRef()
  };

  const {animation, data, images: Images, apiSetup, mode, format, isTimeline} = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const {t} = useTranslation()

  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {

      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({refs, data, animation, mode, id, format})
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);

  const templateData = _.get(data, 'data');

  const {options, fields, dynamic} = templateData;
  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);

  const headline = getContentById('headline', fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  // const nextGame = _.get(dynamic, "nextGame");
  const matchInfo = _.get(dynamic, "matchInfo");
  const lineups = _.get(dynamic, "lineup");
  // const teamDataStyles = _.get(teamData, "styles", {});

  const thisGameDate = new Date(toIsoDateString(matchInfo?.date, matchInfo?.time));

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = _.find(matchInfo?.contestant, {position: 'home'})
  const awayTeam = _.find(matchInfo?.contestant, {position: 'away'});

  const teamSide = getContentById('selectedSide', fields);

  const teamAlpha2 = teamSide === 'home' ? getOptaCountryCode(homeTeam?.name, homeTeam?.shortName, homeTeam?.code) : getOptaCountryCode(awayTeam?.name, awayTeam?.shortName, awayTeam?.code);
  const teamId = teamSide === 'home' ? homeTeam?.id : awayTeam?.id;
  const lineupData = teamSide === 'home' ? _.find(lineups, {contestantId: homeTeam.id}) : _.find(lineups, {contestantId: awayTeam.id});
  const formationUsed = lineupData?.formationUsed;
  const coach = lineupData?.teamOfficial?.firstName ?
    <div>{lineupData?.teamOfficial?.shortFirstName}<br/>{lineupData?.teamOfficial?.shortLastName}</div> : '-';

  return (<div id={id}
               className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''} side-${_.get(dynamic, "teamSide")}`}>

    <TemplateBlock>
      <Backgrounds bg1={backgroundImage} loop={true}/>

      {/*<div className={"main-header"}>*/}
      {/*  Deine <br/><span className={"green-glow"}>Insight</span><br/> <span className={"green-glow"}>Zone</span>*/}

      {/*</div>*/}

      <div className={'info-block'}>

        {/*<div className={"gameInfo"}>*/}
        {/*  <div className={"date-container"}>*/}
        {/*    <div*/}
        {/*      className={"date"}>{dateFormat(thisGameDate, "eeeeee", {locale: de})}, {dateFormat(thisGameDate, "dd.MM.yy", {locale: de})},*/}
        {/*    </div>*/}
        {/*    <div className={"separator"}></div>*/}
        {/*    <div*/}
        {/*      className={"time"}>{String(thisGameDate.getHours()).padStart(2, '0')}:{String(thisGameDate.getMinutes()).padStart(2, '0')} UHR*/}
        {/*    </div>*/}

        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className={"venue-container"}>*/}
        {/*  {matchInfo?.venue?.longName}*/}
        {/*</div>*/}
      </div>

      <div className={"wc-content"}>
        <div className={"wc-bg-content"}></div>

        <div className={'versus-info'}>
          <div className={'group'}>
            {matchInfo?.series?.name.replace('Group', t('Group', {keyPrefix: 'templates'})) || ''}
          </div>
          <div className={'vs-team-info'}>
            {t(homeTeam?.shortName || homeTeam?.name, {ns: 'countries'})} vs {t(awayTeam?.shortName || awayTeam?.name, {ns: 'countries'})}
          </div>
        </div>


        <div className={"team-logo-container"}>
          <Image className="team-logo" src={`/images/dynamic/soccer/team/${teamId}.png`}/>
          {/*<div className={'team-name home'}>*/}
          {/*  {teamSide === 'home' ? homeTeam?.name : awayTeam?.name}*/}
          {/*</div>*/}
        </div>

        <div className={'coach-info'}>
          <div className={'group'}>
            {t("Coach", {keyPrefix: 'templates'})}
          </div>
          <div className={'vs-team-info'}>
            {coach}
          </div>
        </div>

        <div className={'lineup'}>
          <img className={'qatar-logo'} src={assets.qatar_logo}/>
          <PitchTactics className={'lineup-pitch'} formation={formationUsed} players={lineupData?.player}
                        trikot={assets.trikot} pitch={assets.pitch}/>
        </div>
      </div>

    </TemplateBlock>

  </div>);
};

const getMainBackground = (format, side) => {
  return _.get(assets, `circle_center`);
};

export default Index;

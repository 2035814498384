import {axios} from "../axios";

const getTimelineById = ({id, lng, format}) => {
  // return axios.get(API_URL + "templates/all");

  // return axios.get(API_URL + "templates/all");
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      try {
        const testData = require(`../../testData/api/timelines/timeline_${id}`).default;
        resolve({
          data: {
            docs: testData
          }
        });
      } catch (e) {
        resolve();
      }

    })
  }

  return axios({
    url: `/timelines/${id}`,
    method: 'get',
    params: {
      lng, format
    }
  })


};

const getDynamicTimelineById = (params) => {
  const {cid, tid, mid, lng, format, ctype = 'soccer'} = params


  const call = `/timelines/dynamic/${ctype}/${cid}/${tid}`;

  if(mid) {
    call += `/${mid}`
  }

  console.log('call', call);

  return axios({
    url: call,
    method: 'get',
    params: {
      lng, format
    }
  })


};

export {
  getTimelineById,
  getDynamicTimelineById
};

import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();

  $(`#${id} .main-title, #${id} .sub-title, #${id} .table-row, #${id} .teams-block > div`).css("opacity", 0);
  $(`#${id} .facts-block > div`).css("opacity", 0);

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200
  });

  anime({
    targets: [`#${id} .main-title`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 400
  });

  anime({
    targets: [`#${id} .sub-title`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 600
  });

  const timeout1 = setTimeout(() => {

    anime({
      targets: [`#${id} .teams-block > div`],
      duration: 1500,
      easing: "easeOutQuint",
      opacity: [0, 1],
      delay: anime.stagger(100)
    });

    anime({
      targets: [$(`#${id} .facts-block > div`).toArray()],
      duration: 1200,
      easing: "easeOutQuint",
      translateY: [2000, 0],
      opacity: [1, 1],
      delay: anime.stagger(500)
    });

  }, 800);

  timeouts.push(timeout1);

  return {
    timeline: [],
    timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

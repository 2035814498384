import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();
  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200
  });

  anime({
    targets: [`#${id} .versus-info`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 400
  });

  anime({
    targets: [`#${id} .team-logo-container`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 600
  });

  anime({
    targets: [`#${id} .coach-info`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 800
  });

  anime({
    targets: [`#${id} .comp-pitch-tactics`],
    duration: 1200,
    easing: "easeOutQuint",
    // translateX: [2000, 0],
    opacity: [0, 1],
    delay: 400
  });

  const timeout1 = setTimeout(() => {
    anime({
      targets: [`#${id} .lineup-player`],
      duration: 500,
      easing: "easeOutQuint",
      // translateX: [2000, 0],
      opacity: [1, 1],
      scale: [0, 1],
      delay: anime.stagger(80)
    });
  }, 500)

  timeouts.push(timeout1);

  return {
    timeline: [],
    timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

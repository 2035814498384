import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import getContentById from "../../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";

import {engine, fadeIn, slide} from "../../../../../animations/index"
import animationScript, {animationCleanUp} from "../../../../../animations/adler/sentence"
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

import assets from './assets';
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";

require('./style/style.scss');
require('./style/animation.scss');

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef()
  };


  const {animation, data, images: Images, apiSetup, mode, format} = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({refs, data, animation, mode, id, format})
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`).get(0).addEventListener("loadedmetadata", function () {
        this.currentTime = 3;
        this.pause();
      }, false);
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);

  const templateData = _.get(data, 'data');

  const {options, fields, dynamic} = templateData;
  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const customBackground = getContentById('background', fields, 'self');
  const customBackgroundStyles = _.get(customBackground, `styles.${format}`, {});

  const textBlock = getContentById('sentence', fields, 'self');
  const textBlockStyles = _.get(textBlock, `styles.${format}`, {});

  return (
    <div id={id}
         className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''}`}>

      <TemplateBlock>
        {/*<div className={'custom-background-overflow'}>*/}
        <div id={_.get(customBackground, 'id', null)} style={customBackgroundStyles}
             className={"custom-background-container"}>
          <img className={"custom-background"} src={parseMediaUrl(_.get(customBackground, 'value.url'))}/>
        </div>
        {/*</div>*/}

        <Backgrounds bg1={backgroundImage}/>

        <div className={"teams-block"}>
          <div className={"team-logo-container home"}>
            <img src={parseMediaUrl(_.get(teamHome, 'url', null))}/>
          </div>
          {/*<div className={"team-vs"}>vs</div>*/}
          <div className={"team-logo-container away"}>
            <img src={parseMediaUrl(_.get(teamAway, 'url', null))}/>
          </div>

        </div>

        <div className={"main-block"}>
          <div id={_.get(textBlock, 'id', null)} className={"sentence"}
               style={textBlockStyles}
               dangerouslySetInnerHTML={{__html: getContentById('sentence', fields)}}></div>
        </div>


      </TemplateBlock>

    </div>);
};

const getMainBackground = (format, side) => {
  if (format === "1080x1920") {
    return _.get(assets, `mainbackground_1080`);
  } else if (format === "1200x1500") {
    return _.get(assets, `mainbackground_1200`);
  }
};

export default Index;

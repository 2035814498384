import {clearTimeouts, engine as anime} from "../index";

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();
  $(`#${id} .headline`).css("opacity", 0);
  $(`#${id} .referees > div`).css("opacity", 0);
  $(`#${id} .teams-block img`).css("opacity", 0);


  anime({
    targets: [`#${id} .sentence`],
    duration: 1000,
    easing: "easeInOutQuint",
    opacity: [0, 1],
    // rotateZ: [22, 22],
    delay: 800
  });


  const timeout1 = setTimeout(() => {
    timeline.add({
      targets: [`#${id} .teams-block .home img`],
      duration: 450,
      easing: "easeOutSine",
      opacity: [1, 1],
      scale: [0, 1.2],
      // rotateZ: ["22deg", "22deg"],
      delay: 0
    }).add({
      targets: [`#${id} .teams-block .home img`],
      duration: 450,
      easing: "easeInSine",
      opacity: [1, 1],
      scale: [1.2, 0],
      // rotateZ: ["22deg", "22deg"],
      delay: 0
    });
  }, 100);

  timeouts.push(timeout1);

  return {
    timeline: timeline,
    timeouts: timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

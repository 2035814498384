import {Select} from 'antd';
import {useGlobalStore} from "../../../../stores/globalBackendStore";
import _ from "lodash";

import React, {useState, useEffect} from "react";

const {Option} = Select;

export default (props) => {
  const {template, setPreviewFormat} = props;
  const apiSetup = useGlobalStore(state => state.apiSetup);
  const template_name = _.get(template, "template_name");
  const [formats, setFormats] = useState([]);

  const templateSetup = _.find(apiSetup.templates, {name: template_name});
  const availableFormats = _.get(templateSetup, "formats", []);

  function handleChange(value) {
    // console.log(`selected ${value}`);
    setPreviewFormat(value);
  }

  useEffect(() => {
    setFormats(availableFormats)
  }, []);

  return (
    <Select getPopupContainer={node => node.parentNode} defaultValue={_.get(availableFormats, 0, null)}
            style={{width: 120}} onChange={handleChange}>
      {formats.map((item) => {
        return <Option key={item} value={item}>{item}</Option>
      })}
    </Select>
  )
}

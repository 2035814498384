import React, {useState, useEffect} from "react";


import TemplatesList from "./TemplatesList";

import {useTranslation} from 'react-i18next';
import {useGlobalStore} from '../../../../stores/globalBackendStore';

import {
  getTemplatesByMatchday,
  getTimelinesByMatchday,
  getTimelinesBySeason
} from "../../../../services/backend/api.service";
import _ from "lodash";
import Loader from "../../../../components/shared/components/Loader"

import BackendConfig from "../../../../config/backend";

import "./styles/main.scss"
import {Layout} from "antd";

const {Header, Sider, Content, Footer} = Layout;

const Home = (props) => {
  const {t} = useTranslation();
  const matchday = useGlobalStore(state => state.selectedMatchday);
  const setSelectedMatchday = useGlobalStore(state => state.setSelectedMatchday);
  const [loading, setLoading] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);
  const [timelinesList, setTimelinesList] = useState([]);
  const [reloadTemplatesList, setReloadTemplatesList] = useState({
    reload: false
  });
  const [reloadTimelinesList, setReloadTimelinesList] = useState({
    reload: false
  });

  const [refreshIntervalInit, setRefreshIntervalInit] = useState(false);

  useEffect( () => {
    const asyncCall = async () => {
      if (matchday.eventId) {
        const data = await getTemplatesByMatchday(matchday?.eventId).catch((err) => {
          console.error('err', err);
        });

        const apiData = _.get(data, "data.docs", []);
        // setTemplatesList(apiData);
        setTemplatesList(prevData => {
          return [
            ...apiData
          ];
        });
      }
    };

    asyncCall()
  }, [matchday.eventId]);

  // useEffect( () => {
  //   //dashbord auto-reload every X-seconds
  //   if(BackendConfig.dashboardTemplatesRefreshInterval) {
  //     const refreshInterval = setInterval(()=> {
  //       setReloadTemplatesList({
  //         reload: true
  //       });
  //       console.log("Reloading dashboard templates..." + BackendConfig.dashboardTemplatesRefreshInterval);
  //     }, BackendConfig.dashboardTemplatesRefreshInterval)
  //
  //     return () => {
  //       clearInterval(refreshInterval);
  //     }
  //   }
  // }, []);


  useEffect( () => {
    const asyncCall = async () => {
      if (reloadTemplatesList.reload) {

        const data = await getTemplatesByMatchday(matchday?.eventId).catch((err) => {
          console.error('err', err);
        });

        // console.log("reloadTemplatesList", reloadTemplatesList.reload);

        const apiData = _.get(data, "data.docs", []);
        // setTemplatesList(apiData);
        setTemplatesList(prevData => {
          return [
            ...apiData
          ];
        });

        if (reloadTemplatesList.reload) {
          setReloadTemplatesList(prev => {
            return {
              // ...prev,
              reload: false
            }
          });
        }
      }
    };
    asyncCall()
  }, [reloadTemplatesList.reload]);


  useEffect( () => {
    const asyncCall = async () => {
      if (reloadTimelinesList.reload) {
        const data = await getTimelinesBySeason(matchday?.competitionId).catch((err) => {
          console.error('err', err);
        });

        const apiData = _.get(data, "data.docs", []);
        // setTemplatesList(apiData);
        setTimelinesList(prevData => {
          return [
            ...apiData
          ];
        });

        if (reloadTimelinesList.reload) {
          setReloadTimelinesList(prev => {
            return {
              // ...prev,
              reload: false
            }
          });
        }
      }
    }

    asyncCall()
  }, [reloadTimelinesList.reload]);


  useEffect(() => {

    //dashbord auto-reload every X-seconds
    let refreshInterval = null;
    if (!refreshIntervalInit) {
      if (BackendConfig.dashboardTemplatesRefreshInterval) {
        refreshInterval = setInterval(() => {
          setReloadTemplatesList({
            reload: true
          });
          console.log("Reloading dashboard templates..." + BackendConfig.dashboardTemplatesRefreshInterval);
        }, BackendConfig.dashboardTemplatesRefreshInterval)
      }
    }

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);


  return (
    <div className="page-container comp-Dashboard">
      {templatesList ? <TemplatesList
        templatesList={templatesList}
        timelinesList={timelinesList}
        setTimelinesList={setTimelinesList}
        reloadTemplatesList={reloadTemplatesList}
        setReloadTemplatesList={setReloadTemplatesList}
        reloadTimelinesList={reloadTimelinesList}
        setReloadTimelinesList={setReloadTimelinesList}
      /> : <Loader type={"inline"}/>}
    </div>
  );
};

export default Home;

import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();

  // $(`#${id} .qatar-logo, #${id} .main-headline, #${id} .pitch-container, #${id} .player`).css("opacity", 0);

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200
  });

  anime({
    targets: [`#${id} .main-headline`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 400
  });
  anime({
    targets: [`#${id} .stats-type`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 600
  });

  anime({
    targets: [`#${id} .main-header`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 400
  });

  anime({
    targets: [`#${id} .powered-by`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 600
  });

  anime({
    targets: [`#${id} .stat-row`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [2000, 0],
    opacity: [1, 1],
    delay: anime.stagger(120)
  });

  return {
    timeline: [],
    timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

import React, {useEffect, useRef, useState} from 'react';
import {Form, Input} from "antd";

import {ChromePicker} from 'react-color';

const InputField = (props) => {
  const {inputValue} = props;

  useEffect(() => {
    props.onChange(inputValue);
  }, [inputValue]);

  return <Input placeholder="" value={props.value}/>
};


export function InputColor(props) {
  const {item, tooltip} = props;
  const editorRef = useRef(null);
  const [currentColor, setCurrentColor] = useState({hex: item.value});
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [inputValue, setInputValue] = useState(item.value);
  const [initialValue, setInitialValue] = useState(item.value);

  const onChangeHandler = (color) => {
    setInputValue(color.hex);
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  useEffect(() => {
    // setInitialValue(inputValue);
  }, []);

  useEffect(() => {
    setInputValue(item.value)
  }, [item.value]);

  const styles = {
    color: {
      width: '100px',
      height: '15px',
      borderRadius: '2px',
      background: `${currentColor.hex}`,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
      marginLeft: '5px',
      // marginTop: "-10px",
      verticalAlign: "middle"
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
  };


  return (
    <div className={"form-input-color"}>

      <Form.Item label={item.label} tooltip={tooltip}>
        <Form.Item name={item.id} noStyle hidden>
          <InputField inputValue={inputValue}/>
        </Form.Item>

        <div className="color-picker-element" style={styles.swatch} onClick={handleClick}>
          <div style={styles.color}/>
        </div>
        {displayColorPicker ? <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose}/>
          <ChromePicker color={currentColor}
                        onChange={(color) => {
                          setCurrentColor(color);
                        }}
                        onChangeComplete={onChangeHandler}
          />
        </div> : null}

      </Form.Item>
    </div>
  );
}

import React, {useState, useEffect} from "react";
import _ from "lodash";

import {CheckOutlined, CloseOutlined, PlusCircleOutlined, VideoCameraOutlined} from "@ant-design/icons";
import CreateTemplateDrawer from "../Dashboard/CreateTemplateDrawer";
import {useTranslation} from "react-i18next";

import Config from "../../../../config/frontend"
import parseMediaUrl from "../../../shared/functions/parseMediaUrl";

import { v4 as uuidv4 } from 'uuid';

import {
  Button, Table, Tag, Popover,
} from 'antd';
import {useGlobalStore} from "../../../../stores/globalBackendStore";
import {
  getVideoProductionsByFilter,
} from "../../../../services/backend/api.service";
import {useHistory} from "react-router-dom";
import {format as dateFormat, parseISO} from "date-fns";
import {de} from "date-fns/locale";

const getTableData = (videos) => {
  if (!videos?.length) {
    return {
      data: [], columns: []
    };
  }
  const data = videos.map((item, key) => {
    const createdTime = parseISO(item.createdAt);
    const updatedTime = parseISO(item.updatedAt);
    return {
      name: item.name,
      status: item.status,
      format: item.format,
      type: item.type,
      link: item.data?.file,
      worker: item.data?.worker,
      debug: item.data?.data?._call,
      created: dateFormat(createdTime, "dd.MM.yyyy HH:mm:ss", {locale: de}),
      updated: dateFormat(updatedTime, "dd.MM.yyyy HH:mm:ss", {locale: de}),
    };
  });


  const columns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => text,
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ['ascend', 'descend'],
    width: 250
  }, {
    title: 'Format',
    dataIndex: 'format',
    key: 'format',
    render: (text) => text,
    sorter: (a, b) => a.format.localeCompare(b.format),
    sortDirections: ['ascend', 'descend'],
  }, {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    size: 50,
    width: 90,
    sorted: true,
    sorter: (a, b, sortOrder) => {
      return a === b ? 0 : a > b ? -1 : 1;
    },
    sortDirections: ['ascend', 'descend'],
    render: (_, {status}) => {
      const colorByStatus = status === "ready" ? 'green' : 'volcano';
      return (<>
        <Tag color={colorByStatus} key={status}>
          {status}
        </Tag>
      </>)
    },
  }, {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (text) => text,
    sorter: (a, b) => a.type.localeCompare(b.type),
    sortDirections: ['ascend', 'descend'],
  }, {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    sorter: (a, b) => (new Date(b.created) - new Date(a.created))
  }, {
    title: 'Updated',
    dataIndex: 'updated',
    key: 'updated',
    sorter: (a, b) => (new Date(b.updated) - new Date(a.updated))
  }, {
    title: 'Link', dataIndex: 'link', key: 'link', sorter: false, align: 'center', render: (_, {link}) => {
      if (link) {
        return <a target={"_blank"} rel="noreferrer" href={link}><Button type="primary">Video</Button></a>
      }
      return <>X</>
    },
  }, {
    title: 'Debug', dataIndex: 'debug', key: 'debug', sorter: false, align: 'center', render: (_, {debug}) => {
      return <Popover
        content={debug}
        title="Debug"
        trigger="click"
        overlayInnerStyle={{maxWidth: '600px', overflow: 'scroll', padding: '5px'}}
      >
        <Button type="primary">Debug</Button>
      </Popover>
    },
  },];

  return {columns, data}
}

const Page = (props) => {

  const {t} = useTranslation();
  const {title, type} = props;
  const apiSetup = useGlobalStore(state => state.apiSetup);
  const selectedMatchday = useGlobalStore(state => state.selectedMatchday);
  const [videos, setVideos] = useState();

  useEffect(() => {
    require("./style/main.scss");
  }, []);

  useEffect(() => {
    if (selectedMatchday?.season) {
      (async () => {
        // const filter = {
        //   season: selectedMatchday.season
        // };
        const data = await getVideoProductionsByFilter({}, []).catch((err) => {
          console.error('err', err);
        });
        setVideos(data?.data?.docs);
      })();
    }
  }, [selectedMatchday]);

  const tableData = getTableData(videos);

  const onChange = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  return (<div className="page-container videos-page">
    <header className="jumbotron main-page-header">
      <h2>{t("Videos productions")} {t(title)}</h2>
    </header>

    <div className={"videos-page-content"}>
      <Table columns={tableData.columns} dataSource={tableData.data} sticky={true} pagination={false} rowKey={(r) => r?.name + uuidv4()}
             onChange={onChange}/>
    </div>
  </div>);
};

export default Page;

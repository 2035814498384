import {useTranslation} from "react-i18next";
import {useGlobalStore} from "../../../../stores/globalBackendStore";
import React, {useEffect, useState, useRef} from "react";
import _ from "lodash";
import backendCfg from "../../../../config/backend";

import {Form, Input, Button, Radio, notification} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import {Tabs} from 'antd';
import {AppleOutlined, SettingOutlined} from '@ant-design/icons';

import {BiBookContent} from "react-icons/bi";

import splitFormValues from "../../../shared/functions/splitFormValues";

import {getTemplatesByMatchday, setTemplateById, createScreenshot} from "../../../../services/backend/api.service";

import Fields from "../../../../components/shared/components/Forms/Fields";

import i18n from "../../../../language";
import {useGlobalFrontendStore} from "../../../../stores/globalFrontendStore";
import getEventFromMatchday from "../../../shared/functions/getEventFromMatchday";

const {TabPane} = Tabs;

const Index = (props) => {
  const {newStylingData, templateData, setCurrentTemplateData, form, setEditFormResponseData, setVisible, selectedMatchday} = props;

  const fields = _.get(templateData, "data.fields", []);
  const options = _.get(templateData, "data.options", []);

  const apiSetup = useGlobalStore(state => state.apiSetup);
  const templatesSetup = _.get(apiSetup, `templates`, []);
  const templateFormats = _.get(_.find(templatesSetup, {name: templateData.template_name}), "formats", []);

  const onFinish = async (values) => {
    const parsedValues = splitFormValues(values, {fields, options}, {updatedStyles: newStylingData});

    if(selectedMatchday) {
      parsedValues.selectedMatchday = getEventFromMatchday(selectedMatchday)
    }
    const templateDataResponse = await setTemplateById({
      id: templateData.uuid,
      template_name: templateData.template_name,
      data: parsedValues
    }).catch((err) => {
      notification["error"]({
        message: t('Template saving error'),
        description: err.message,
        placement: "topRight"
      });
    });

    notification["success"]({
      message: t('Success'),
      description: t("Template data has been saved. ") + t("Creating screenshot..."),
      placement: "topRight"
    });

    setVisible(false);

    const serverTemplateUuid = _.get(templateDataResponse, "data.uuid", null);

    // console.log("SCREENSHOT QUERY", {
    //     templateId: serverTemplateUuid,
    //     url: `${backendCfg.frontendUrl}/screenshot/${templateData.template_name}/${serverTemplateUuid}`,
    //     // url: `https://google.it`,
    //     dir: templateData.template_name,
    //     format: "1920x1080",
    //     preview: true
    // });

    //create screenshot independently

    const buildActionsForFormats = [];

    _.each(templateFormats, (format, formatIndex) => {

      buildActionsForFormats.push({
        templateId: serverTemplateUuid,
        url: `${backendCfg.frontendUrl}/screenshot/${templateData.template_name}/${serverTemplateUuid}?format=${format}`,
        // url: `https://google.it`,
        dir: templateData.template_name,
        format: format,
        preview: formatIndex === 0 ? true : false
      })
    });

    const screenshotData = await createScreenshot({
      id: serverTemplateUuid,
      template_name: templateData.template_name,
      actions: buildActionsForFormats
    }).catch((err) => {
      notification["error"]({
        message: i18n.t("Screenshot error"),
        description: _.get(err.response, "data.errors.msg")
      });
    });

    notification["success"]({
      message: t('Success'),
      description: t("Template screenshot updated."),
      placement: "topRight"
    });

    setEditFormResponseData({
      status: "SCREENSHOT_READY",
      template: templateData,
      screenshot: _.get(screenshotData, "data.screenshots[0]", null)
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };


  //https://stackoverflow.com/questions/47809666/lodash-debounce-not-working-in-react
  const setCurrentTemplateDataDebounced = useRef(_.debounce((templateData) => {
    setCurrentTemplateData({...templateData});
  }, 500));

  const onValuesChange = (formData) => {
    const fields = [...templateData.data.fields];
    const options = [...templateData.data.options];

    fields.map((item, index, key) => {
      const value = _.get(formData, item.id, null);
      if (value) {
        item.value = formData[item.id];
      }

      return item;
    });


    options.map((item, index, key) => {
      const value = _.get(formData, item.id, null);
      if (value) {
        item.value = formData[item.id];
      }

      return item;
    });

    setCurrentTemplateDataDebounced.current(templateData);

    //setCurrentTemplateData({...templateData})

  };

  const {t} = useTranslation();

  useEffect(() => {
    const initialValues = {};

    if (templateData.data.fields && templateData.data.fields.length) {
      templateData.data.fields.forEach((item) => {
        initialValues[item.id] = item.value
      })
    }

    if (templateData.data.options && templateData.data.options.length) {
      templateData.data.options.forEach((item) => {
        initialValues[item.id] = item.value
      })
    }

    form.setFieldsValue({...initialValues})

  }, [templateData.data]);

  return (
    <Form
      form={form}
      layout={"vertical"}
      // initialValues={{
      //     headline: 'ble'
      // }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
    >
      <Tabs defaultActiveKey="1">
        <TabPane forceRender={true} tab={<span><BiBookContent className={"anticon"}/>{t("Content")}</span>}
                 key="1">
          <Fields fields={fields} form={form}/>
        </TabPane>
        <TabPane forceRender={true} tab={<span><SettingOutlined/>{t("Options")}</span>} key="2">
          <Fields fields={options} form={form}/>
        </TabPane>
      </Tabs>
    </Form>
  );
};

export default Index;

const assets = {
  _conditional: (data) => {
    const {format, apiData} = data;
    return [assets.mainbackground_1200_home, assets.mainbackground_1200_away, assets.mainbackground_1920_home, assets.mainbackground_1920_away];
  },
  mainbackground_1200_home: require("./videos/Background_04_STATS_Home_4-5.webm"),
  mainbackground_1200_away: require("./videos/Background_04_STATS_Away_4-5.webm"),
  mainbackground_1920_home: require("./videos/Background_10_STATS_LIVE_16-9.webm"),
  mainbackground_1920_away: require("./videos/Background_10_STATS_LIVE_16-9.webm"),
  sap_presents: require("../../../assets/logos/sap_white.png"),
  penny_del: require("../../../assets/logos/penny-del-logo.png"),
};

export default assets;

import React from "react";

import {Redirect} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import BackendConfig from "../config/backend";

// import Register from "../components/backend/pages/Register";
// import NotFoundPage from "../components/shared/pages/NotFound";

// import DashboardPage from "../components/backend/pages/Dashboard/Index";
// import SetupPage from "../components/backend/pages/Setup/Index";

const DashboardPage = React.lazy(() => import(/* webpackChunkName: "Dashboard" */ "../components/backend/pages/Dashboard/Index"));
const SetupPage = React.lazy(() => import(/* webpackChunkName: "Setup" */ "../components/backend/pages/Setup/Index"));
const VideosPage = React.lazy(() => import(/* webpackChunkName: "Videos" */ "../components/backend/pages/Videos/Index"));
const CompetitionPage = React.lazy(() => import(/* webpackChunkName: "Videos" */ "../components/backend/pages/Competitions/Index"));

//nested example
// const BACKEND_USER_ROUTES = [
//     {
//         path: "/submenu1",
//         key: "SUBMENU1",
//         component: props => {
//             if (!localStorage.getItem("user")) {
//                 alert("You need to log in to access app routes");
//                 return <Redirect to={"/login"} />;
//             }
//             return <RenderThisRoute {...props} />;
//         },
//         routes: [
//             {
//                 path: "/submenu2",
//                 key: "APP_ROOT",
//                 exact: true,
//                 component: () => <h1>App Index</h1>,
//             },
//             {
//                 path: "/submenu3",
//                 key: "APP_PAGE",
//                 exact: true,
//                 component: () => <h1>App Page</h1>
//             },
//         ],
//     },
// ];

export const BACKEND_ROUTES = [
  {
    path: [`/dashboard`], label: "Dashboard", exact: true, component: (props) => {
      return <DashboardPage {...props} />
    }
  },
  {
    path: [`/videos`], label: "Videos", menuHide: false, exact: true, component: (props) => {
      return <VideosPage {...props} />
    }
  },
  {
    path: "/setup", label: "Setup", exact: true,
    routes: [
      // { path: "/setup/general", label: "General",  exact: true, component: props => {
      //         return <SetupPage type={"general"} title={"General"}/>
      //     }},
      {
        path: "/setup/design", label: "Design", exact: true, component: props => {
          return <SetupPage type={"design"} title={"Design"}/>
        }
      },
      {
        path: "/setup/video", label: "Video", exact: true, component: props => {
          return <SetupPage type={"video"} title={"Video"}/>
        }
      },
      {
        path: "/setup/timeline", label: "Timeline", exact: true, component: props => {
          return <SetupPage type={"timeline"} title={"Timeline"}/>
        }
      },
      {
        path: "/setup/timeline-automation", label: "Timeline Automation", exact: true, component: props => {
          return <SetupPage type={"timelineAutomation"} title={"Timeline Automation"}/>
        }
      },
      {
        path: "/setup/timeline-music", label: "Timeline Music", exact: true, component: props => {
          return <SetupPage type={"timelineMusic"} title={"Timeline Music"}/>
        }
      }
    ]
  },
  {
    path: [`/competitions`], label: "Competitions", menuHide: false, exact: true, component: (props) => {
      return <CompetitionPage {...props} />
    }
  },
  // { path: [`/timeline`], label: "Timeline",  exact: true, component: (props) => {
  //        const {t} = useTranslation();
  //
  //         return <h3>{t("Timeline")}</h3>
  //     }},

  {
    path: [`/`], label: "Default", menuHide: true, exact: true, component: (props) => {
      return <Redirect to="/backend/dashboard"/>
    }
  },
  // routes: [
  //     { path: "/setup/general", label: "General",  exact: true, component: props => {
  //         return <h3>GENERAL SETUP</h3>
  //     }},
  //     { path: "/setup/videoproduction", label: "Videoproduction",  exact: true, component: props => {
  //         return <h3>VIDEOPRODUCTION</h3>
  //     }},
  //     { path: "/setup/timeline", label: "Timeline", exact: true, component: props => {
  //             return <h3>TIMELINE</h3>
  //         }}
  //     ]},
  // { path: [`/login`], key: "LOGIN", menuHide: true, exact: true, component: (props) => {
  //     return <Login {...props}/>
  //     } },
  // { path: `/register`, key: "REGISTER", exact: true, component: Register },
  // { path: "/videos", key: "VIDEOS", exact: true, component: props => {
  //         return <h3>VIDEOS</h3>
  //     } },
  // { path: `/playerfacts`, key: "PLAYERFACTS", exact: true, component: props => {
  //         return <h3>PLAYERFACTS</h3>
  //     } },
  // { path: `/playerstats`, key: "PLAYERSTATS", exact: true, component: props => {
  //         return <h3>PLAYERSTATS</h3>
  //     } },
  // { path: `/playercompare`, key: "PLAYERCOMPARE", exact: true, component: props => {
  //         return <h3>PLAYERCOMPARE</h3>
  //     } },
  // { path: `/teamcompare`, key: "TEAMCOMPARE", exact: true, component: props => {
  //         return <h3>TEAMCOMPARE</h3>
  //     } },
  // { path: `/teamfacts`, key: "TEAMFACTS", exact: true, component: props => {
  //         return <h3>TEAMFACTS</h3>
  //     } },
  // { path: `/lineup`, key: "LINEUP", exact: true, component: props => {
  //         return <h3>LINEUP</h3>
  //     } },
  // { path: `/standing`, key: "STANDING", exact: true, component: props => {
  //         return <h3>STANDING</h3>
  //     } }
];

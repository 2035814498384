import React, {useEffect, useRef, useState} from 'react';

import {Editor} from '@tinymce/tinymce-react';
import {Form, Input, Alert} from "antd";
import _ from "lodash";

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
// import tinymce from 'tinymce/tinymce';


const InputField = (props) => {
  const {inputValue} = props;

  useEffect(() => {
    props.onChange(inputValue);
  }, [inputValue]);

  return <Input placeholder="input placeholder" value={props.value}/>
};


export function InputHtml(props) {
  const {item} = props;
  const editorRef = useRef(null);
  const [inputValue, setInputValue] = useState(item.value);
  const [initialValue, setInitialValue] = useState(item.value);

  const onEditorChangeHandler = () => {
    if (editorRef.current) {
      setInputValue(editorRef.current.getContent())
      // console.log(editorRef.current.getContent());
    }
  };

  useEffect(() => {
    // setInitialValue(inputValue);
  }, []);

  useEffect(() => {
    setInputValue(item.value)
  }, [item.value]);

  return (
    <div className={"form-input-html"}>

      <Form.Item label={item.label} tooltip="Info">

        <Form.Item name={item.id} rules={[{required: true, message: 'Please input your username!'}]} noStyle hidden>
          <InputField inputValue={inputValue}/>
        </Form.Item>

        <Editor
          tinymceScriptSrc={process.env.VENDORS_PATH + 'tinymce/tinymce.min.js'}
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={initialValue}
          // value={inputValue}
          onEditorChange={onEditorChangeHandler}
          init={{
            branding: false,
            height: 200,
            width: "100%",
            menubar: false,
            preview_styles: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'paste code '
            ],
            toolbar: 'undo redo | bold italic forecolor fontsizeselect lineheight | fullscreen',
            content_style: `
            body {
              font-size:14px;
            }

            p span {
              font-size:14px !important;
            }`,
            fontsize_formats: "default=inherit 80px 90px 100px 105px 110px 115px 120px 125px 130px 135px 140px",
            lineheight_formats: "1.2 0.9 0.95 1 1.05 1.1 1.2 1.2 1.3 1.4",
            setup : function(ed) {
              ed.on('init', function(ed) {
                this.getBody().style.fontSize = 'inherit';
              });
            }
          }}
        />
      </Form.Item>
    </div>
  );
}

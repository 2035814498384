import _ from "lodash";

const assets = {
  circle_center: require("../../../assets/wc/videos/circle_center.webm"),
  qatar_logo: require("../../../assets/wc/images/sport_world_logo.png"),
  goalmouth: require("../../../assets/wc/images/goalmouth.png"),
  goalmouth_field: require("../../../assets/wc/images/goalmouth_field.png"),
};

export default assets;

import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();
  $(`#${id} .team-logo-container img`).css("opacity", 0);
  $(`#${id} .headline`).css("opacity", 0);
  $(`#${id} .player-image`).css("opacity", 0);

  const params = {};
  params.headlineTop = 380
  params.headlineScale = 1.5

  anime.timeline({
    targets: `#${id} .headline`,
    easing: 'easeInOutQuint',
    delay: 100,
  })
    .add({
      scale: [0, params.headlineScale],
      duration: 800,
      opacity: [1, 1],
    })
    .add({
      scale: [params.headlineScale, 1.0],
      top: [params.headlineTop, $(`#${id} .headline`).css('top')],
      duration: 600,
      delay: 100,
      opacity: [1, 1],
    });


  anime({
    targets: `#${id} .top-scorers-away .full-name`,
    keyframes: [
      {clipPath: 'inset(100% 0 0 0)', duration: 0},
      {clipPath: 'inset(0% 0 0 0)', duration: 800},
    ],
    easing: 'easeInOutQuart',
    opacity: [1, 1],
    delay: anime.stagger(150, {start: 1200}), // 200ms delay between each elemen
  });

  anime({
    targets: `#${id} .top-scorers-home .full-name`,
    keyframes: [
      {clipPath: 'inset(100% 0 0 0)', duration: 0},
      {clipPath: 'inset(0% 0 0 0)', duration: 800},
    ],
    easing: 'easeInOutQuart',
    opacity: [1, 1],
    delay: anime.stagger(150, {start: 1200}), // 200ms delay between each elemen
  });

  anime({
    targets: $(`#${id} .top-scorers-away .names`).toArray(),
    translateY: [200, 0],
    easing: 'easeInOutQuart',
    opacity: [1, 1],
    delay: anime.stagger(50, {start: 1300}), // 200ms delay between each elemen
  });

  anime({
    targets: $(`#${id} .top-scorers-home .names`).toArray(),
    translateY: [200, 0],
    easing: 'easeInOutQuart',
    opacity: [1, 1],
    delay: anime.stagger(50, {start: 1300}), // 200ms delay between each elemen
  });

  anime({
    targets: [`#${id} .top-scorers-away .player-image`],
    duration: 1000,
    easing: "easeOutQuint",
    opacity: [1, 1],
    // rotateZ: [-22, -22],
    scale: [0, 1],
    delay: anime.stagger(100, {start: 1100}),
  });

  anime({
    targets: [`#${id} .top-scorers-home .player-image`],
    duration: 1000,
    easing: "easeOutQuint",
    opacity: [1, 1],
    // rotateZ: [-22, -22],
    scale: [0, 1],
    delay: anime.stagger(100, {start: 1100}),
  });


  anime({
    targets: $(`#${id} .top-scorers-away .scorer-stats > div`).toArray().reverse(),
    duration: 1000,
    easing: "easeOutQuint",
    opacity: [1, 1],
    // rotateZ: [-22, -22],
    scale: [0, 1],
    delay: anime.stagger(100, {start: 1200}),
  });

  anime({
    targets:$(`#${id} .top-scorers-home .scorer-stats > div`).toArray().reverse(),
    duration: 1000,
    easing: "easeOutQuint",
    opacity: [1, 1],
    // rotateZ: [-22, -22],
    scale: [0, 1],
    delay: anime.stagger(100, {start: 1200}),
  });


  return {
    timeline: timeline,
    timeouts: timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

const main = {
  apiUrl: process.env.API_URL || "http://localhost:3000",
  frontendUrl: process.env.FRONTEND_URL || "http://localhost:3001/frontend",
  videoUrl: process.env.VIDEO_URL || "http://localhost:8100",
  client: "womansworldcup_live",
};

if(process.env.ENV_TYPE === 'localdocker') {
  main.apiUrl = "http://172.100.0.1:3000"
  main.frontendUrl = "http://172.100.0.1:3001/frontend"
  main.videoUrl = "http://172.100.0.1:8100"
}

export default {
  ...main,
  testMode: false,
  testData: {
    templateDuration: 5
  },
  appName: "Spo|Vizz",
  apiUrl: main.apiUrl,
  templateDuration: 5000,
  videoBeginColor: "rgb(0, 0, 255)",
  videoEndColor: "rgb(0, 255, 0)",
  videoBeginColorDuration: 2000,
  videoEndColorDuration: 2000,
  timeline: {
    firstSlideDelay: 500,
    fadeInDuration: 100,
    fadeOutDuration: 500,
    defaultDuration: 15, //in secs, just a backup value
    transitionType: 'video', //null
    videoTransitionBefore: 1300, //time when video covering whole screen, so we can start template switching
    videoTransitionAfter: 0, //delay until new template show up, after transition is finished
  },
  showOutroAfter: 0,
  timelineAutoLinkEnabled: true,
  timelineLinkAutomation: {
    'PRE-MATCH': {
      beforeInMinutes: 40
    },
    'LIVE-MATCH': {
      event: 'start_game'
    },
    'POST-MATCH': {
      afterGameEnd: 5
    }
  },
  reloadOnTimelineError: false
}

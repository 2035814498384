import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useGlobalStore} from "../../../../stores/globalBackendStore";
import {useHistory} from "react-router-dom";
import {setApiSetup} from "../../../../services/backend/api.service";
import {Button, Form, notification, Table, Popconfirm} from "antd";
import {InputMedia} from "../../../shared/components/Forms/Fields/types/_FormMedia";
import _ from "lodash";
import {InputColor} from "../../../shared/components/Forms/Fields/types/_FormColor";
import {formItemLayout} from "./Index";

import {MenuOutlined} from '@ant-design/icons';
import {DndContext} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

const Row = ({children, ...props}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
        position: 'relative',
        zIndex: 9999,
      }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const Setup = () => {
  const {t} = useTranslation();
  const setApiSetupState = useGlobalStore(state => state.setApiSetup);
  const history = useHistory();
  const [form] = Form.useForm();

  const apiSetup = useGlobalStore(state => state.apiSetup);
  const [count, setCount] = useState(0);

  const {timelineExtras} = apiSetup;

  const onFinish = async (values) => {
    console.log('values', values);
    console.log('dataSource', dataSource);

    let musicValues = Array.isArray(values.music) ? values.music : Object.values(values.music);
    musicValues = musicValues.filter(item => item && !_.isEmpty(item.uuid));

    console.log('musicValues', musicValues)

    if (!musicValues.length && !dataSource.length) {
      console.log('No data to save');
      return;
    }

    const setupResponse = await setApiSetup({
      id: 1,
      data: {
        data: {
          timelineExtras: { music: musicValues }
        }
      }
    }).catch((err) => {
      notification["error"]({
        message: t('Setup saving error'),
        description: err.message,
        placement: "topRight"
      });
    });

    notification["success"]({
      message: t('Success'),
      description: t("Setup has been updated."),
      placement: "topRight"
    });

    // setApiSetupState(setupResponse.data.data);
  };

  const [dataSource, setDataSource] = useState([]);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const columns = [
    {
      title: 'Sort',
      key: 'sort',
      width: '15px'
    },
    {
      title: 'Media',
      dataIndex: 'media',
      editable: true,
      render: (base, record) => {
        console.log('record', record)
        return <InputMedia item={{
          id: ['music', record?.key],
          label: null,
          allowedMediaTypes: ["audio"],
          initialActiveGroup: "",
          value: record?.media
        }}/>
      }
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      width: '50px',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    }
  ];
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource(previous => {
        const activeIndex = previous.findIndex(i => i.key === active.id);
        const overIndex = previous.findIndex(i => i.key === over?.id);
        const newOrder = arrayMove(previous, activeIndex, overIndex);

        // Update the form values to reflect the new order
        form.setFieldsValue({ music: newOrder.map(item => item.media) });

        return newOrder;
      });
    }
  };

  const handleAdd = () => {
    const newData = {
      key: count+1,
      media: {},
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  // if (!timelineExtras) {
  //   return null;
  // }

  useEffect(() => {
    if (timelineExtras && timelineExtras?.music) {
      const formattedData = timelineExtras.music.map((item, index) => ({
        key: String(index), // assuming unique index as key
        uuid: item?.uuid,
        media: item // entire item as media
      }));

      setDataSource(formattedData);
      form.setFieldsValue({ music: formattedData.map(item => item.media) });
    }
  }, [timelineExtras?.music, form]);

  console.log('dataSource', dataSource)
  console.log('design', timelineExtras)


  const initialFormValues = {
    ...timelineExtras,
    music: Array.isArray(timelineExtras?.music) ? timelineExtras.music : []
  };

  console.log('timelineExtras', timelineExtras)
  console.log('initialFormValues', initialFormValues)

  return (
    <div className={'setup-form-timeline-extras'}>


      <Form
        form={form}
        name="setup_form"
        {...formItemLayout}
        onFinish={onFinish}
        // initialValues={initialFormValues}
      >
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={dataSource.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Button onClick={handleAdd} type="primary" style={{marginBottom: 16}}>
              Add a row
            </Button>
            <Table
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey="key"
              columns={columns}
              dataSource={dataSource}
              size="small"
              pagination={false}
            />
          </SortableContext>
        </DndContext>

        <Form.Item
          wrapperCol={{
            span: 12,
            offset: 6,
          }}
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Setup;

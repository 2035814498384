import React from 'react';

import './styles.scss'

const Index = (props) => {
  return (
    <div className={"comp-template-block"}>
      {props.children}
    </div>
  )
};

export default Index;

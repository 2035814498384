import {axios} from "../axios";
import _ from "lodash";
import config from "../../config/frontend";
import backendCfg from "../../config/backend";
import frontendCfg from "../../config/frontend";

import getEventFromMatchday from "../../components/shared/functions/getEventFromMatchday";
import {getTemplatesByFilter} from "../../services/backend/api.service";


const produceVideo = ({template, format, apiSetup, apiSelectedMatchday, lng}) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      resolve(template.uuid);
    })
  }

  const resolution = format.split("x");
  const width = resolution[0];
  const height = resolution[1];

  const options = _.get(template, "data.options", []);
  const videoDuration = _.find(options, {id: "videoduration"});

  const postData = {
    url: `/videos/template`,
    method: 'post',
    data: {
      "template_uuid": template.uuid,
      "template_name": template.template_name,
      "name": `${template.name} ${(lng ? "[" + _.upperCase(lng) + "]" : '')}`,
      "format": format,
      "event": getEventFromMatchday(apiSelectedMatchday),
      "lng": lng,
      "videoquery": {
        "url": `${backendCfg.frontendUrl}/video/${template.template_name}/${template.uuid}?format=${format}&lng=${lng}`,
        "seconds": parseFloat(_.get(videoDuration, 'value', 10)) + 5,
        "webhook": `${backendCfg.apiUrl}/webhook/videoproduction`,
        "width": parseFloat(width),
        "height": parseFloat(height)
      }
    }
  };

  //sound part
  const {templateaudio, timelineaudio, addSoundToTemplate, addSoundToTimeline} = _.get(apiSetup, "video");

  const templateName = _.get(template, "template_name");
  const templateCustomAudio = _.get(apiSetup, `video.templateaudio_${templateName}`);

  if (addSoundToTemplate && templateaudio) {
    _.set(postData, "data.videoquery.sound", `${backendCfg.apiUrl}${templateaudio.url}`)
  }

  if (addSoundToTemplate && templateCustomAudio) {
    _.set(postData, "data.videoquery.sound", `${backendCfg.apiUrl}${templateCustomAudio.url}`)
  }

  return axios(postData)
};

const produceVideoTimeline = async ({timeline, format, apiSetup, apiSelectedMatchday, lng}) => {
  if (process.env.MOCK_API) {
    return new Promise((resolve) => {
      resolve(timeline.uuid);
    })
  }

  const resolution = format.split("x");
  const width = resolution[0];
  const height = resolution[1];

  const options = _.get(timeline, "data.options", []);
  const videoDuration = _.find(options, {id: "videoduration"});

  //calculate total duration BEGIN
  const filter = {
    uuid: []
  };
  _.each(timeline.templates, (item) => {
    filter.uuid.push(item.templateId);
  });

  const data = await getTemplatesByFilter(filter, ["data.options.id", "data.options.value"]).catch((err) => {
    console.error('err', err);
  });

  const apiData = _.get(data, "data.docs", []);

  let totalDuration = _.sumBy(apiData, function (templateEntry) {
    if (frontendCfg.testMode) {
      return parseFloat(frontendCfg.testData.templateDuration);
    }
    return parseFloat(_.get(_.find(templateEntry.data.options, {id: "videoduration"}), "value"));
  });

  const inDuration = frontendCfg.timeline.fadeInDuration / 1000;
  const outDuration = (frontendCfg.timeline.fadeOutDuration / 1000) * timeline.templates.length - 1;

  const totalIncludedOthers = totalDuration + inDuration + outDuration;
  //calculate total duration END

  const postData = {
    url: `/videos/timeline`,
    method: 'post',
    data: {
      "timeline_uuid": timeline.uuid,
      "name": `${timeline.name} ${(lng ? "[" + _.upperCase(lng) + "]" : '')}`,
      "format": format,
      "event": getEventFromMatchday(apiSelectedMatchday),
      "lng": lng,
      "videoquery": {
        "url": `${backendCfg.frontendUrl}/video/timeline/${timeline.uuid}?lng=${lng}`,
        "seconds": totalIncludedOthers + 5,
        "webhook": `${backendCfg.apiUrl}/webhook/videoproduction`,
        "width": parseFloat(width),
        "height": parseFloat(height)
      }
    }
  };

  //sound part
  const {timelineaudio, addSoundToTimeline} = _.get(apiSetup, "video");

  if (addSoundToTimeline && timelineaudio) {
    _.set(postData, "data.videoquery.sound", `${backendCfg.apiUrl}${timelineaudio.url}`)
  }

  // console.log("postData", postData);

  return axios(postData)
};

export {
  produceVideo,
  produceVideoTimeline
};

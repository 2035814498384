import React, {useState, useEffect} from "react";


const Backend = (props) => {
  const {children} = props;
  return (
    <div className="container">
      {children}
    </div>
  );
};

export default Backend;

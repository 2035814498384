import React, {useEffect} from "react";

import getContentById from "../../../../shared/utils/getContentById";
import {getScorersOverride} from "../../../../shared/functions/adlerScorers";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import animationScript, {animationCleanUp} from "../../../../../animations/adler/results";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

import assets from "../../../templates/adler/Results/assets";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import outroVideo16x9 from "../../../assets/outros/2023/outro_playoffs_16x9.webm";
import outroVideo4x5 from "../../../assets/outros/2023/outro_playoffs_4x5.webm";
import outroVideo9x16 from "../../../assets/outros/2023/outro_playoffs_9x16.webm";
import {usePlayoffsVideo} from "../../../../shared/hooks/usePlayoffsVideo";
import {parseGoal} from "../../../../shared/utils/goals";

require("./style/style.scss");
require("./style/animation.scss");

const renderScorers = (goals, overrides, prefix) => {
  return goals.map((goal, index) => {
    const override = overrides?.[(goals.length - 1) - index];
    const line = override ? override : parseGoal(goal, prefix === "goalhome");
    return <div key={prefix + index}>{line}</div>;
  });
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {animation, data, images: Images, apiSetup, mode, format, isTimeline} = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  // handle outro video BEGIN
  // usePlayoffsVideo({animation, mode, isTimeline, id})
  // handle outro video END

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({refs, data, animation, mode, id, format});
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`)
        .get(0)
        .addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 2.4;
            this.pause();
          },
          false
        );
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const {options, fields, dynamic} = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);
  const scorersOverride = getScorersOverride(fields);
  // console.log("dynamic", dynamic);
  // console.log("templateData", templateData);
  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const goalsHome = _.get(dynamic, "goals.home");
  const goalsAway = _.get(dynamic, "goals.visitor");
  const eventData = _.get(dynamic, "game");
  // const teamDataStyles = _.get(teamData, "styles", {});
  const playerData = getContentById("player", fields, "self");

  const teamSide = _.get(dynamic, "teamSide")

  const backgroundImage = getMainBackground(format, teamSide);
  const customBackground = getContentById("background", fields, "self");
  const customBackgroundStyles = _.get(customBackground, `styles.${format}`, {});

  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;

  console.log('dynamic', dynamic);

  return (
    <div id={id} className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${teamSide}`}>
      <TemplateBlock>
        <div
          id={_.get(customBackground, "id", null)}
          style={customBackgroundStyles}
          className={"custom-background-container"}
        >
          <img className={"custom-background"} src={parseMediaUrl(_.get(customBackground, "value.url"))}/>
        </div>

        <Backgrounds bg1={backgroundImage}/>
        <img className={"sponsors sponsor-sap"} src={assets.sap_presents}/>
        <img className={"sponsors sponsor-del"} src={assets.penny_del}/>

        {/*handle outro video BEGIN*/}
        {format === "1920x1080" && (
          <video className={"outro-optional-video"} controls={false} autoPlay={false} muted={true} loop={false}>
            <source src={outroVideo16x9}/>
          </video>
        )}

        {format === "1200x1500" && (
          <video className={"outro-optional-video"} controls={false} autoPlay={false} muted={true} loop={false}>
            <source src={outroVideo4x5}/>
          </video>
        )}

        {format === "1080x1920" && (
          <video className={"outro-optional-video"} controls={false} autoPlay={false} muted={true} loop={false}>
            <source src={outroVideo9x16}/>
          </video>
        )}
        {/*handle outro video END*/}

        <div className={"home-scorers"}>
          {renderScorers(
            React.useMemo(() => [...goalsHome].reverse(), [goalsHome]),
            React.useMemo(() => [...(scorersOverride?.homeScorers || [])], [scorersOverride?.homeScorers]),
            "goalhome"
          )}
        </div>

        <div className={"away-scorers"}>
          {renderScorers(
            React.useMemo(() => [...goalsAway].reverse(), [goalsAway]),
            React.useMemo(() => [...(scorersOverride?.awayScorers || [])], [scorersOverride?.awayScorers]),
            "goalaway"
          )}
        </div>

        <div className={"score-container"}>
          <div>
            <div className={"score-home playoffs-headline"}>
              <div className={"playoffs-headline"} data-score={_.get(eventData, "results.score.final.score_home", 0)}>
                {_.get(eventData, "results.score.final.score_home", 0)}
                {/*<div className={"stroke-shadow"}>{_.get(eventData, "results.score.final.score_home", 0)}</div>*/}
              </div>
            </div>
            <div className={"score-separator"}></div>
            <div className={"score-away"}>
              <div className={"playoffs-headline"} data-score={_.get(eventData, "results.score.final.score_guest", 0)}>
                {_.get(eventData, "results.score.final.score_guest", 0)}
                {/*<div className={"stroke-shadow"}>{_.get(eventData, "results.score.final.score_guest", 0)}</div>*/}
              </div>
            </div>
          </div>
        </div>

        <div className={"periods-container " + actualTimeAliasClass}>
          {_.get(eventData, "results.score.first_period", null) && (
            <div className={"period-1-score"}>
              <div className={"box-bg"}>
                <div className="triangle1"></div>
                <div className="triangle2"></div>
              </div>
              <div className={"box-score"}>
                {_.get(eventData, "results.score.first_period.score_home", null)}
                <span className={"score-separator"}>:</span>
                {_.get(eventData, "results.score.first_period.score_guest", null)}
              </div>
            </div>
          )}
          {_.get(eventData, "results.score.second_period", null) && (
            <div className={"period-2-score"}>
              <div className={"box-bg"}>
                <div className="triangle1"></div>
                <div className="triangle2"></div>
              </div>
              <div className={"box-score"}>
                {_.get(eventData, "results.score.second_period.score_home", null)}
                <span className={"score-separator"}>:</span>
                {_.get(eventData, "results.score.second_period.score_guest", null)}
              </div>
            </div>
          )}
          {_.get(eventData, "results.score.third_period", null) && (
            <div className={"period-3-score"}>
              <div className={"box-bg"}>
                <div className="triangle1"></div>
                <div className="triangle2"></div>
              </div>
              <div className={"box-score"}>
                {_.get(eventData, "results.score.third_period.score_home", null)}
                <span className={"score-separator"}>:</span>
                {_.get(eventData, "results.score.third_period.score_guest", null)}
              </div>
            </div>
          )}
          {_.get(eventData, "results.score.overtime", null) && (
            <div className={"period-4-score"}>
              <div className={"box-bg"}>
                <div className="triangle1"></div>
                <div className="triangle2"></div>
              </div>
              <div className={"box-score"}>
                {_.get(eventData, "results.shooting", false) === true
                  ? _.get(eventData, "results.score.shootout.score_home", null)
                  : _.get(eventData, "results.score.overtime.score_home", null)}
                <span className={"score-separator"}>:</span>
                {_.get(eventData, "results.shooting", false) === true
                  ? _.get(eventData, "results.score.shootout.score_guest", null)
                  : _.get(eventData, "results.score.overtime.score_guest", null)}
                <span className={"extra-time-info"}>
                  {_.get(eventData, "results.shooting", false) === true ? "SO" : "OT"}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className={"teams-block"}>
          <div className={"team-logo-container home"}>
            <img src={parseMediaUrl(_.get(teamHome, "url", null))}/>
          </div>
          <div className={"team-logo-container away"}>
            <img src={parseMediaUrl(_.get(teamAway, "url", null))}/>
          </div>
        </div>

        {/*<div className={"content"}>*/}

        {/*    <div id="header" className={'header'}>*/}
        {/*        /!*<div className="team-head-block">*!/*/}
        {/*        /!*    /!*<div className={"team-name"}>{_.get(teamData, 'data.name', null)}</div>*!/*!/*/}
        {/*        /!*    <div className={"team-name"}>{headline}</div>*!/*/}
        {/*        /!*    <div id={teamData.id} ref={_.get(refs, "team")} className={"team-logo"}*!/*/}
        {/*        /!*         style={{...teamDataStyles}}><ImageOrVideo src={parseMediaUrl(teamData.value.url)}/></div>*!/*/}
        {/*        /!*</div>*!/*/}
        {/*        <div className="player-head-block">*/}
        {/*            <div className={"player-number"}>{_.get(playerData, 'value.data.shirtNumber', null)}</div>*/}
        {/*            <div className={"player-name-block"}>*/}
        {/*                <div className={"player-name"}*/}
        {/*                     style={{backgroundColor: color1}}>{_.get(playerData, 'value.data.firstname', null)} {_.get(playerData, 'value.data.lastname', null)}</div>*/}
        {/*                <div className={"player-position"}*/}
        {/*                     style={{color: color1}}>{_.get(playerData, 'value.data.position', null)}</div>*/}
        {/*            </div>*/}

        {/*        </div>*/}
        {/*    </div>*/}

        {/*    <Stats fields={fields} primaryColor={color1} backgroundColor={color2}/>*/}
        {/*</div>*/}
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  if (format === "1080x1920") {
    return _.get(assets, `mainbackground_1080_${side}`);
  }

  // console.log("format", format)
  if (format === "1200x1500") {
    return _.get(assets, `mainbackground_1200_${side}`);
  }
};

export default Index;

import _ from "lodash";

const assets = {
  circle_center: require("../../../assets/wc/videos/circle_center.webm"),
  qatar_logo: require("../../../assets/wc/images/sport_world_logo.png"),
  trikot_empty: require("../../../assets/wc/images/trikot_empty.png"),
  heatmap_pitch: require("../../../assets/wc/images/pitch_heatmap.png"),
  right_arrow: require("../../../assets/svg/arrow-right.svg")
};

export default assets;

import React, {useEffect} from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import animationScript, {animationCleanUp} from "../../../../../animations/adler/lineup"
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

import assets from './assets';
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import {usePlayoffsVideo} from "../../../../shared/hooks/usePlayoffsVideo";

import outroVideo16x9 from "../../../assets/outros/2023/outro_playoffs_16x9.webm";
import outroVideo4x5 from "../../../assets/outros/2023/outro_playoffs_4x5.webm";
import outroVideo9x16 from "../../../assets/outros/2023/outro_playoffs_9x16.webm";

require('./style/style.scss');
require('./style/animation.scss');

const renderPlayerLine = (lineArray, assets, isAdlerTeamSide) => {
  // const {json} = this.props;

  return lineArray.map((player, index) => {
    const playerId = _.get(player, "playerId", "").toString();
    const nextPlayer = _.get(lineArray, index + 1, null);
    const playerRoster = _.get(player, "roster", "").charAt(0);
    const nextPlayerRoster = _.get(nextPlayer, "roster", "").charAt(0);
    let playerPosition = null;

    if (playerRoster === "2") {
      playerPosition = "DE";
    } else if (playerRoster === "3") {
      playerPosition = "FO";
    }

    const playerPosString = playerPosition === "DE" ? "defender" : "forward";
    const predefinedPlayer = _.get(assets, `p_${playerId}`);
    let extraClass = "";

    const predefinedImage = parseMediaUrl(_.get(predefinedPlayer, "url", null));

    // if (_.get(player, "playerId", null) === 1197) {
    //   _.set(player, 'surname', 'Preto');
    // }

    if (nextPlayerRoster === '2' && playerRoster !== nextPlayerRoster) {
      extraClass = "last-of-pos";
    }


    if (index < 3 && _.toUpper(playerPosition) === 'DE' && nextPlayerRoster !== '3') {
      extraClass += ` offset-${3 - index}`
    }

    const feedFirstname = _.get(player, "name", "");
    const firstnameSplitted = feedFirstname.split(' ');
    const firstname = firstnameSplitted[0] ? firstnameSplitted[0] : feedFirstname;

    return <div key={'lineup_' + index + playerId} className={"player-data pos-" + playerPosString + " " + extraClass}>
      <div className={"player-separator "}></div>
      <div className={"player-bottom-gradient"}></div>
      <div className={"player-item-bg"}>

        <div className={"player-image-mask"}>
          <img src={predefinedImage} className={`player-image pid-${playerId}`}/>
        </div>
        <div className={"player-number"}>{_.get(predefinedPlayer, "data.shirtNumber", _.get(player, "jersey"))}</div>
      </div>
      {/*      {isAdlerTeamSide === false ?
        <div className={"player-firstname"}>{_.get(predefinedPlayer, "data.firstname", firstname)}</div> : null}
      <div className={"player-lastname"}>{_.get(predefinedPlayer, "data.lastname", _.get(player, "surname"))}</div>*/}
    </div>;
  })
};
const renderPlayerLineNames = (lineArray, assets, isAdlerTeamSide) => {
  // const {json} = this.props;

  return lineArray.map((player, index) => {
    const playerId = _.get(player, "playerId", "").toString();
    const nextPlayer = _.get(lineArray, index + 1, null);
    const playerRoster = _.get(player, "roster", "").charAt(0);
    const nextPlayerRoster = _.get(nextPlayer, "roster", "").charAt(0);
    let playerPosition = null;

    if (playerRoster === "2") {
      playerPosition = "DE";
    } else if (playerRoster === "3") {
      playerPosition = "FO";
    }

    const playerPosString = playerPosition === "DE" ? "defender" : "forward";
    const predefinedPlayer = _.get(assets, `p_${playerId}`);
    let extraClass = "";

    const predefinedImage = parseMediaUrl(_.get(predefinedPlayer, "url", null));

    // if (_.get(player, "playerId", null) === 1197) {
    //   _.set(player, 'surname', 'Preto');
    // }

    if (nextPlayerRoster === '2' && playerRoster !== nextPlayerRoster) {
      extraClass = "last-of-pos";
    }


    if (index < 3 && _.toUpper(playerPosition) === 'DE' && nextPlayerRoster !== '3') {
      extraClass += ` offset-${3 - index}`
    }

    const feedFirstname = _.get(player, "name", "");
    const feedLastname = _.get(player, "surname", "");
    const firstnameSplitted = feedFirstname.split(' ');
    const lastnameSplitted = feedLastname.split(' ');
    const firstname = firstnameSplitted[0] ? firstnameSplitted[0] : feedFirstname;
    const lastname = lastnameSplitted[0] ? lastnameSplitted[0] : feedLastname;

    return <div key={'lineup_' + index + playerId} className={"player-data pos-" + playerPosString + " " + extraClass}>
      {isAdlerTeamSide === false ?
        <div className={"player-firstname"}>{_.get(predefinedPlayer, "data.firstname", firstname)}</div> : null}
      <div className={"player-lastname"}>{_.get(predefinedPlayer, "data.lastname", lastname)}</div>
    </div>;
  })
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef()
  };


  const {animation, data, images: Images, apiSetup, mode, format, isTimeline} = props;

  const templateData = _.get(data, 'data');
  const {options, fields, dynamic} = templateData;
  const attachOutro = getContentById('attachOutro', fields);

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  // handle outro video BEGIN
  // usePlayoffsVideo({animation, mode, isTimeline, id})
  // handle outro video END

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({refs, data, animation, mode, id, format})
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`).get(0).addEventListener("loadedmetadata", function () {
        this.currentTime = 12;
        this.pause();
      }, false);
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);


  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);

  const headline = getContentById('headline', fields);
  const statsSide = getContentById('statsSide', fields);

  const teamSide = statsSide;

  const startingSixDataHome = _.get(dynamic, "lineupHome");
  const startingSixDataAway = _.get(dynamic, "lineupAway");

  let lineup = null;

  if (teamSide === "home") {
    lineup = startingSixDataHome;
  } else {
    lineup = startingSixDataAway;
  }

  const serverAssets = _.get(dynamic, "assets");
  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const eventData = _.get(dynamic, "game");
  // const teamDataStyles = _.get(teamData, "styles", {});

  const backgroundImage = getMainBackground(format, teamSide);
  // const backgroundMask = getContentById('defaultBackgroundMask', options);

  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;
  //
  // console.log("goalsHome", goalsHome);

  const playersLine1 = _.get(lineup, "line1", []);
  const playersLine2 = _.get(lineup, "line2", []);
  const playersLine3 = _.get(lineup, "line3", []);
  const playersLine4 = _.get(lineup, "line4", []);
  const playersLineGoalie = _.get(lineup, "lineGoalie", []);

  const isAdlerTeamSide = _.get(eventData, `${statsSide}.id`) == 2 ? true : false;

  return (
    <div id={id}
         className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''} side-${teamSide} ${isAdlerTeamSide ? "adler-stats" : "other-stats"}`}>

      <TemplateBlock>
        <Backgrounds bg1={backgroundImage}/>

        {/*handle outro video BEGIN*/}
        {format === "1920x1080" && (
          <video className={"outro-optional-video"} controls={false} autoPlay={false} muted={true} loop={false}>
            <source src={outroVideo16x9}/>
          </video>
        )}

        {format === "1200x1500" && (
          <video className={"outro-optional-video"} controls={false} autoPlay={false} muted={true} loop={false}>
            <source src={outroVideo4x5}/>
          </video>
        )}
        {/*handle outro video END*/}

        {/*<img className={"sponsors sponsor-sap"} src={assets.mannheimer_presents}/>*/}
        <img className={"sponsors sponsor-del"} src={assets.penny_del}/>

        <div className={"headline"}>
          <div className={"main-line"}>LINEUP</div>
          <div className={"ghost-line"}>LINEUP</div>
        </div>

        <div className={"players-block-container"}>

          {/*<div className={"line-label line1"}>1</div>*/}
          <div className={"players line1"}>
            <div className={"player-group-lines"}>
              <div className={"player-group-line"}>
                <div>S</div>
                <div>1</div>
                <div>V</div>
              </div>
              <div className={"player-group-line-2"}>
                <div>S</div>
                <div>1</div>
                <div>V</div>
              </div>
            </div>
            {renderPlayerLine(playersLine1, serverAssets, isAdlerTeamSide)}
            <div className={"names"}>
              {renderPlayerLineNames(playersLine1, serverAssets, isAdlerTeamSide)}
            </div>
          </div>

          {/*<div className={"line-label line2"}>2</div>*/}
          <div className={"players line2"}>
            {/*<div className={"line-row"}>2</div>*/}
            <div className={"player-group-lines"}>
              <div className={"player-group-line"}>
                <div>S</div>
                <div>2</div>
                <div>V</div>
              </div>
              <div className={"player-group-line-2"}>
                <div>S</div>
                <div>2</div>
                <div>V</div>
              </div>
            </div>
            {renderPlayerLine(playersLine2, serverAssets, isAdlerTeamSide)}
            <div className={"names"}>
              {renderPlayerLineNames(playersLine2, serverAssets, isAdlerTeamSide)}
            </div>
          </div>

          {/*<div className={"line-label line3"}>3</div>*/}
          <div className={"players line3"}>
            {/*<div className={"line-row"}>3</div>*/}
            <div className={"player-group-lines"}>
              <div className={"player-group-line"}>
                <div>S</div>
                <div>3</div>
                <div>V</div>
              </div>
              <div className={"player-group-line-2"}>
                <div>S</div>
                <div>3</div>
                <div>V</div>
              </div>
            </div>
            {renderPlayerLine(playersLine3, serverAssets, isAdlerTeamSide)}
            <div className={"names"}>
              {renderPlayerLineNames(playersLine3, serverAssets, isAdlerTeamSide)}
            </div>
          </div>

          {/*<div className={"line-label line4"}>4</div>*/}
          <div className={"players line4"}>
            {/*<div className={"line-row"}>4</div>*/}
            <div className={"player-group-lines"}>
              <div className={"player-group-line"}>
                <div>S</div>
                <div>4</div>
                <div>V</div>
              </div>
              <div className={"player-group-line-2"}>
                <div>S</div>
                <div>4</div>
                <div>V</div>
              </div>
            </div>
            {renderPlayerLine(playersLine4, serverAssets, isAdlerTeamSide)}
            <div className={"names"}>
              {renderPlayerLineNames(playersLine4, serverAssets, isAdlerTeamSide)}
            </div>
          </div>


          {(() => {
            let goalieClass = "";
            const goalieLine = playersLineGoalie.slice(0, 2);

            return <div className={"players lineGoalie " + goalieClass}>

              <div className={"player-group-lines"}>
                <div className={"player-group-line"}>
                  <div>T</div>
                </div>
                {/*<div className={"player-group-line-2"}>*/}
                {/*  <div>T</div>*/}
                {/*</div>*/}
              </div>

              {/*<div className={"line-row"}>T</div>*/}
              {renderPlayerLine(goalieLine, serverAssets, isAdlerTeamSide)}
              <div className={"names"}>
                {renderPlayerLineNames(goalieLine, serverAssets, isAdlerTeamSide)}
              </div>
            </div>;
          })()}

        </div>

        <div className={"teams-block"}>
          {<div className={"team-logo-container home"}>
            <img src={parseMediaUrl(_.get(teamHome, 'url', null))}/>
          </div>}

          {format === "1200x1500" && <>
            <div className={"team-vs"}>vs</div>
          </>}

          <div className={"team-logo-container away"}>
            <img src={parseMediaUrl(_.get(teamAway, 'url', null))}/>
          </div>



        </div>

      </TemplateBlock>

    </div>);
};

const getMainBackground = (format, side) => {
  if (format === "1200x1500") {
    return _.get(assets, `mainbackground_1200_${side}`);
  }

  if (format === "1920x1080") {
    return _.get(assets, `mainbackground_1920_${side}`);
  }

  return null;
};

export default Index;

import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import getContentById from "../../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";

import {engine, fadeIn, slide} from "../../../../../animations/index"
import animationScript, {animationCleanUp} from "../../../../../animations/adler/teamstats"
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

import assets from './assets';
import {Divider} from "antd";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";

require('./style/style.scss');
require('./style/animation.scss');

const renderStandingTable = (standingData, apiAssets) => {
  const rows = [];

  rows.push(<div key={"header-row-key"} className={"row header-row"}>
    <div className={"cell"}>
      Pos.
    </div>
    <div className={"cell"}>
      {/*Logo*/}
    </div>
    <div className={"cell"}>
      Team
    </div>
    <div className={"cell"}>
      Spiele
    </div>
    <div className={"cell"}>
      Tore
    </div>
    <div className={"cell"}>
      Tordiff.
    </div>
    <div className={"cell"}>
      Punkte
    </div>
    <div className={"cell"}>
      PKT/SPIEL
    </div>
  </div>);

  _.each(standingData, (entry, entryIndex) => {
    // let pageClass = entryIndex < 7 ? "page-1" : "page-2";
    // const logo = _get(json.Images, 'del-standing-id-' + entry.id, null);
    // console.log("apiAssets", apiAssets);

    const teamApiData = _.get(apiAssets, `t_${entry.id}.data`, null);
    const logo = _.get(apiAssets, `t_${entry.id}.url`, null);
    // const showExtraLine = entryIndex === 5 || entryIndex === 9 ? true : false;

    const exposeSelectedClass = _.get(entry, 'id') == 2 ? "expose-selected-row" : "";

    rows.push(<div key={"standing_" + entryIndex} className={`row ${exposeSelectedClass}`}>
      <div className={"cell"}>
        {_.get(entry, 'position')}
      </div>
      <div className={"cell"}>
        {logo && <img className={"table-team-logo team-logo-id-" + _.get(entry, 'id')} src={parseMediaUrl(logo)}/>}
      </div>
      <div className={"cell"}>
        {/*{showExtraLine && <div className={"extra-line"}></div>}*/}
        {/*{_.get(entry, 'name')}*/}
        {_.get(teamApiData, 'name')}
      </div>
      <div className={"cell"}>
        {_.get(entry, 'games')}
      </div>
      <div className={"cell"}>
        {_.get(entry, 'score1') + "-" + _.get(entry, 'score2')}
      </div>
      <div className={"cell"}>
        {parseInt(_.get(entry, 'score1')) - parseInt(_.get(entry, 'score2'))}
      </div>
      <div className={"cell"}>
        {_.get(entry, 'points')}
      </div>
      <div className={"cell"}>
        {_.get(entry, 'ppg')}
      </div>
    </div>);
  });


  return (<div className="table">
    {rows}
  </div>);
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef()
  };


  const {animation, data, images: Images, apiSetup, mode, format} = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({refs, data, animation, mode, id, format})
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`).get(0).addEventListener("loadedmetadata", function () {
        this.currentTime = 3;
        this.pause();
      }, false);
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);

  const templateData = _.get(data, 'data');
  const currrentEvent = _.get(data, 'event');

  const {options, fields, dynamic} = templateData;
  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);

  const statsSide = getContentById('statsSide', fields);
  const lastGamesCustom = getContentById('lastGamesCustom', fields);
  // console.log("statsSide", statsSide);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const goalsHome = _.get(dynamic, "goals.home");
  const goalsAway = _.get(dynamic, "goals.visitor");
  const eventData = _.get(dynamic, "game");
  const standingData = _.get(dynamic, "standing");
  const apiAssets = _.get(dynamic, "assets");

  // const teamDataStyles = _.get(teamData, "styles", {});
  const playerData = getContentById('player', fields, "self");


  // const teamSide = _.get(dynamic, "teamSide");
  const teamSide = statsSide;
  // const backgroundMask = getContentById('defaultBackgroundMask', options);

  const backgroundImage = getMainBackground(format, teamSide);
  const customBackground = getContentById('background', fields);

  const teamStatsSelected = _.get(dynamic, `teamStats.${teamSide}`, {});

  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;

  const previousMatchdays = _.get(dynamic, `previousMatchdays.${teamSide}`, []);

  const selectedTeamId = teamSide === "home" ? _.get(currrentEvent, "homeTeamId") : _.get(currrentEvent, "awayTeamId");

  const currentTableEntry = _.find(standingData, {id: parseInt(selectedTeamId)});
  const currentTablePos = _.get(currentTableEntry, "position");

  const tGames = _.get(currentTableEntry, 'games', 0);
  const tPoints = _.get(currentTableEntry, 'points', 0);
  const tPpg = _.get(currentTableEntry, 'ppg', 0);
  // const tScore1 = _.get(currentTableEntry, 'score1', 0);
  // const tScore2 = _.get(currentTableEntry, 'score2', 0);
  const uberzahlEfficiency = _.get(teamStatsSelected, 'powerPlayEfficiency', 0);
  const unterzahlEfficiency = _.get(teamStatsSelected, 'penaltyKillingEfficiency', 0);
  const goalScoredHome = _.get(teamStatsSelected, 'goalScored.home', 0);
  const goalScoredAway = _.get(teamStatsSelected, 'goalScored.away', 0);
  const tScore1 = goalScoredHome + goalScoredAway;
  const goalAgainstHome = _.get(teamStatsSelected, 'goalsAgainst.home', 0);
  const goalAgainstAway = _.get(teamStatsSelected, 'goalsAgainst.away', 0);
  const tScore2 = goalAgainstHome + goalAgainstAway;

  let scoreEndings = [];

  _.each(previousMatchdays, (matchday) => {
    if (matchday.status !== "AFTER_MATCH") {
      scoreEndings.push("-");
      return;
    }

    const matchHomeId = _.get(matchday, 'home.id');
    const checkMatchSide = matchHomeId === parseInt(selectedTeamId) ? 'home' : 'away';
    const finalScoreHome = parseInt(_.get(matchday, "results.score.final.score_home"));
    const finalScoreAway = parseInt(_.get(matchday, "results.score.final.score_guest"));
    const hasOvertime = _.get(matchday, "results.score.overtime", false);
    const overtimeWinType = _.get(matchday, "results.shooting", false) === true ? "SO" : "OT";
    let overtimeExtraString = "";

    if (hasOvertime) {
      overtimeExtraString = overtimeWinType;
    }

    if (checkMatchSide === "home") {
      if (finalScoreHome > finalScoreAway) {
        scoreEndings.push("S" + overtimeExtraString);
      } else {
        scoreEndings.push("N" + overtimeExtraString);
      }
    } else {
      if (finalScoreHome < finalScoreAway) {
        scoreEndings.push("S" + overtimeExtraString);
      } else {
        scoreEndings.push("N" + overtimeExtraString);
      }
    }
  });

  if (_.get(currrentEvent, 'eventId') === '2166' && teamSide === 'away') {
    scoreEndings = ['SOT', 'NSO', 'S', 'SOT', 'S'];
  }

  if (!_.isEmpty(lastGamesCustom)) {
    scoreEndings = lastGamesCustom.split('/');
  }

  return (
    <div id={id}
         className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''} side-${teamSide}`}>

      <TemplateBlock>
        <Backgrounds bg1={backgroundImage}/>

        <div className={"headline"}>
          {currentTablePos}. PLATZ
        </div>

        <div className={"team-logo-container home"}>
          <img src={parseMediaUrl(teamSide === "home" ? _.get(teamHome, 'url', null) : _.get(teamAway, 'url', null))}/>
        </div>


        <div className={"team-stats-block"}>
          <div className={"team-block-line"}></div>

          <div className={"team-stats games"}>
            <div className={"value"}>{tGames}</div>
            <div className={"value-label-separator"}></div>
            <div className={"label"}>SPIELE</div>
          </div>
          <div className={"team-stats points"}>
            <div className={"value"}>{tPoints}</div>
            <div className={"value-label-separator"}></div>
            <div className={"label"}>PUNKTE</div>
          </div>
          <div className={"team-stats ppg"}>
            <div className={"value"}>{_.round(tPpg, 2)}</div>
            <div className={"value-label-separator"}></div>
            <div className={"label"}>PKT/SPIEL</div>
          </div>
          <div className={"team-stats goals"}>
            <div className={"value"}>{tScore1}</div>
            <div className={"value-label-separator"}></div>
            <div className={"label"}>TORE</div>
          </div>
          <div className={"team-stats counter-goals"}>
            <div className={"value"}>{tScore2}</div>
            <div className={"value-label-separator"}></div>
            <div className={"label"}>GEGENTORE</div>
          </div>

        </div>

        <div className={"line-stats-container"}>

          <div className={"stat-line-home-container unterzahl"}>
            <div className={"box-bg"}>
              <div className="triangle1"></div>
              <div className="triangle2"></div>
            </div>
            <div className={"label"}>Unterzahl</div>
            <div className={"value"}>{unterzahlEfficiency}<span>%</span></div>
            <div className={"filler-container"}>
              <div className={"filler " + (unterzahlEfficiency == 0 ? "filler-none" : "")}
                   style={{width: unterzahlEfficiency + "%"}}>
                <div></div>
                <div></div>
              </div>

            </div>
            <div className={"filler-point-base"}></div>
            <div className={"filler-empty"}></div>
            <div className={"stats-mark-points"}>
              {_.times(11, (markIndex) => {
                return <div key={`mark-${markIndex}`} className={`mark-${markIndex}`}></div>
              })
              }
            </div>
          </div>

          <div className={"stat-line-home-container uberzahl"}>
            <div className={"box-bg"}>
              <div className="triangle1"></div>
              <div className="triangle2"></div>
            </div>
            <div className={"label"}>Überzahl</div>
            <div className={"value"}>{uberzahlEfficiency}<span>%</span></div>
            <div className={"filler-container"}>
              <div className={"filler " + (uberzahlEfficiency == 0 ? "filler-none" : "")}
                   style={{width: uberzahlEfficiency + "%"}}>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className={"filler-point-base"}></div>
            <div className={"filler-empty"}></div>
            <div className={"stats-mark-points"}>
              {_.times(11, (markIndex) => {
                return <div key={`mark-${markIndex}`} className={`mark-${markIndex}`}></div>
              })
              }
            </div>

          </div>

        </div>

        {scoreEndings.length && <div className={"last-x-games"}>
          <div className={"label"}>
            Letzte Spiele:
          </div>

          <div className={"last-games-container"}>

            {scoreEndings.map((ending, endingIndex) => <div key={`game-${endingIndex + 1}`}
                                                            className={`game-${endingIndex + 1}`}>
              {ending}
            </div>)
              .reduce((accu, elem) => {
                return accu === null ? [elem] : [...accu, '/', elem]
              }, null)}
          </div>
        </div>}

        {/*<img className={"sponsors sponsor-del"} src={assets.penny_del}/>*/}
        <img className={"sponsors sponsor-sap"} src={assets.sap_presents}/>

      </TemplateBlock>

    </div>);
};

const getMainBackground = (format, side) => {
  if (format === "1920x1080") {
    return _.get(assets, `mainbackground_1920_${side}`);
  }
};

export default Index;

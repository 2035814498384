import React, {useEffect, useRef, useState} from 'react';

import extToType from '../../../functions/extToType';

export default (props) => {
  const {url, item} = props;

  const currentType = extToType(url);

  const updatedAtPrefix = new Date(item.updatedAt).getTime();

  if (currentType === "image") {
    return <div>
      <div className={"preview-label"}>{item.label}</div>
      <img className={"media-preview-img"} src={url + `?updated_at=${updatedAtPrefix}`}/>
    </div>
  } else if (currentType === "video") {
    return <div>
      <div className={"preview-label"}>{item.label}</div>
      <video className={"media-preview-video"} controls muted>
        <source src={url + `?updated_at=${updatedAtPrefix}`}/>
      </video>
    </div>
  } else {
    return <div>
      <div className={"preview-label"}>{item.label}</div>
      <audio className={"media-preview-audio"} controls>
        <source src={url + `?updated_at=${updatedAtPrefix}`}/>
      </audio>
    </div>
  }
}

import React, {useEffect} from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from '../../../../shared/functions/prepareVideoBg';
import animationScript, {animationCleanUp} from "../../../../../animations/worldcup/opener"

import assets from './assets';
import {format as dateFormat} from "date-fns";

import {de, enUS} from "date-fns/locale";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import {utcToZonedTime} from "date-fns-tz";
import countries from "../../../../../language/de/countries.json";
import {useTranslation} from "react-i18next";
import Image from "../../../../shared/components/Image";
import {toIsoDateString} from "../../../../shared/utils/functions";
import {getDateInZone, getTimeInZone} from "../../../../shared/utils/dateHelpers";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require('./style/style.scss');
require('./style/animation.scss');

const translations = {
  'Group': 'Gruppe'
};

const Index = (props) => {
  const refs = {
    player: React.createRef(), team: React.createRef()
  };

  const {animation, data, images: Images, apiSetup, mode, format, isTimeline} = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const {t, i18n} = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {

      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({refs, data, animation, mode, id, format})
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);

  const templateData = _.get(data, 'data');

  const lng = i18n?.language;

  const {options, fields, dynamic} = templateData;
  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);

  const headline = getContentById('headline', fields);
  const openerType = getContentById('openerType', fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  // const nextGame = _.get(dynamic, "nextGame");
  const matchInfo = _.get(dynamic, "matchInfo");
  // const teamDataStyles = _.get(teamData, "styles", {});

  // const thisGameDateTmp = new Date(toIsoDateString(matchInfo?.date, matchInfo?.time)) || '';

  // const timeZone = 'Europe/Berlin';
  // const thisGameDate = utcToZonedTime(thisGameDateTmp, timeZone);

  const matchDate = new Date(toIsoDateString(matchInfo?.date, matchInfo?.time));
  const time = matchInfo?.time ? getTimeInZone(matchDate, lng, true) : '-';
  const date = matchInfo?.time ? getDateInZone(matchDate, lng) : '-';


  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  // const teamHomeAlpha2 = getOptaCountryCode(homeTeam?.name, homeTeam?.shortName, homeTeam?.code);
  // const teamAwayAlpha2 = getOptaCountryCode(awayTeam?.name, awayTeam?.shortName, awayTeam?.code);

  const teamHomeAlpha2 = homeTeam?.id;
  const teamAwayAlpha2 = awayTeam?.id;

  return (<div id={id}
               className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''} side-${_.get(dynamic, "teamSide")}`}>

    <TemplateBlock>
      <Backgrounds bg1={backgroundImage} loop={true}/>

      <div className={"qatar-logo"}>
        <img src={assets.sport_logo}/>
      </div>

      <div className={"main-header"}>
        {/*{t("templates.Your")} <br/><span className={"green-glow"}>{t("templates.Insight")}</span><br/> <span*/}
        <span className={"green-glow"}>{t("templates.Insight")}</span><br/> <span
        className={"green-glow"}>{t("templates.Zone")}</span> <br/>
        <div className={"competition-name"}>{t(matchInfo?.competition?.knownName || matchInfo?.competition?.name)}</div>
      </div>

      {/*<img className={"powered-by"} src={assets.poweredBy}/>*/}

      {openerType === 'global' ? (

        <div className={'info-block'}>
          <div className={'global-title'}>
            <div>
              <b><span className={'wc-title'}>{t("templates.Women's World Cup")} 2023</span> {t("templates.In Numbers")}
              </b>
            </div>
          </div>
        </div>
      ) : <div className={'info-block'}>
        <div className={'competition-info'}>
          <div>
            <b>{matchInfo?.series?.name.replace('Group', t('Group', {keyPrefix: 'templates'}))}</b>
          </div>
        </div>

        <div className={"teams-block"}>
          <div className={"team-vs"}>
            <div className={"versus"}>{t("templates.vs")}.</div>
          </div>
          <div className={"team-logo-container home"}>
            <Image className="team-logo" src={`/images/dynamic/soccer/team/${homeTeam?.id}.png`}/>
            <div className={'team-name home'}>
              {t(homeTeam?.name, {ns: 'countries'})}
            </div>
          </div>
          <div className={"team-logo-container away"}>
            <Image className="team-logo" src={`/images/dynamic/soccer/team/${awayTeam?.id}.png`}/>
            <div className={'team-name away'}>
              {t(awayTeam?.name, {ns: 'countries'})}
            </div>
          </div>
        </div>

        <div className={"gameInfo"}>
          <div className={"date-container"}>
            <div
              className={"date"}>{dateFormat(matchDate, "eeeeee", {locale: lng === 'de' ? de : enUS})},&nbsp;&nbsp;{date},
            </div>&nbsp;&nbsp;
            <div
              className={"time"}>{time} {t('TIME', {keyPrefix: 'templates'})}
            </div>

          </div>
        </div>
        <div className={"venue-container"}>
          {matchInfo?.venue?.longName?.replace('Stadium', t("Stadium", {keyPrefix: "templates"}))}
        </div>
      </div>}
    </TemplateBlock>

  </div>);
};

const getMainBackground = (format, side) => {
  return _.get(assets, `circle_right`);
};

export default Index;

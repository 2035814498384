import React, {useEffect, useState} from "react";
import {getImages} from "../../../../services/media.service"
import _ from 'lodash';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import EditMedia from "./EditMedia";

import parseMediaUrl from "../../../shared/functions/parseMediaUrl";
import MediaDescriptionRender from "./MediaDescriptionRender";
import {uploadMedia, deleteMediaByUuid} from "../../../../services/backend/api.service";
import {notification} from "antd";
import {useTranslation} from "react-i18next";
import {useGlobalStore} from "../../../../stores/globalBackendStore";

// const mediaType = "images";

export default (props) => {
  const {t} = useTranslation();
  const apiSetup = useGlobalStore(state => state.apiSetup);
  const [refreshData, setRefreshData] = useState(false);
  const {group, onSelect, initialActiveGroup, visibleEdit, setVisibleEdit, editData, setEditData} = props;
  const [images, setImages] = useState([]);

  const refreshDataList = ({group}) => {
    getImages({group}).then((data) => {
      const apiItems = _.get(data, "data.docs", []);

      if (group == "_default") {
        setImages(apiItems);
      } else {
        const filtered = _.filter(apiItems, (item) => {
          if (initialActiveGroup && !group) {
            return item.group === initialActiveGroup || initialActiveGroup === "0";
            // return item.group === group || _.isEmpty(group);
          } else {
            return item.group === group;
          }
        });
        setImages(filtered);
      }

      setRefreshData(false);
    })
  };

  useEffect(() => {
    if (!visibleEdit) {
      refreshDataList({group})
    }
  }, [group, visibleEdit]);

  useEffect(() => {
    if (refreshData) {
      refreshDataList({group})
    }
  }, [refreshData]);

  const handleSelection = (e, item) => {
    props.onSelect(e, item)
  };

  const handleDelete = async (e, item) => {
    await deleteMediaByUuid({
      uuid: item.uuid,
    }).catch((err) => {
      notification["warning"]({
        message: t('Warning'),
        description: t("Media removing problem: ") + err.message,
        placement: "topRight"
      });
    });

    refreshDataList({group});

    notification["success"]({
      message: t('Success'),
      description: t("Media has been deleted."),
      placement: "topRight"
    });
  };

  const openEdit = (e, item) => {
    // props.onSelect(e, item)
    setEditData(item);
    setVisibleEdit(true);
  };

  return <>
    <div className={"media-images-list"}>
      {visibleEdit && <EditMedia visibleEdit={visibleEdit} setVisibleEdit={setVisibleEdit} editData={editData}
                                 setRefreshData={setRefreshData}
                                 accept={_.get(apiSetup, `mediaLibrary.accept.image`)}/>}
      {images.map((item, key) => {
        const updatedAtPrefix = new Date(item.updatedAt).getTime();
        return (<div key={item.uuid} className={"media-item"}>
          <div className={"media-actions"}>
            <DeleteOutlined style={{color: "red"}} onClick={(e) => {
              handleDelete(e, item);
            }}/>

            <EditOutlined onClick={(e) => {
              openEdit(e, item);
            }}/>
          </div>
          <div className={"media-item-av"} onClick={(e) => handleSelection(e, item)}>
            <img src={parseMediaUrl(item.url) + `?updated_at=${updatedAtPrefix}`}/>
          </div>
          <div className={"media-item-data"}>
            <MediaDescriptionRender item={item}/>
          </div>
        </div>)

      })}
    </div>
  </>
};

import React, {useEffect} from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from '../../../../shared/functions/prepareVideoBg';
import animationScript, {animationCleanUp} from "../../../../../animations/worldcup/seasontotals"

import assets from './assets';
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import {getPlayerStats} from "../../../../shared/utils/opta/statsHandler";

require('./style/style.scss');
require('./style/animation.scss');

import {positionTranslations, positionExtension, seasonStatsLabel} from '../../../../shared/utils/opta/player'
import {useTranslation} from "react-i18next";

const Index = (props) => {
  const refs = {
    player: React.createRef(), team: React.createRef()
  };

  const {animation, data, images: Images, apiSetup, mode, format, isTimeline} = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const {t} = useTranslation()

  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {

      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({refs, data, animation, mode, id, format})
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);

  const templateData = _.get(data, 'data');

  const {options, fields, dynamic} = templateData;
  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);



  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const statsType = getContentById('statsType', fields);
  const header = getContentById('headline', fields);
  const seasonTotals = _.get(dynamic, "seasonTotals");

  const targetStats = seasonTotals?.[statsType] || [];

  return (<div id={id}
               className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''} side-${_.get(dynamic, "teamSide")}`}>

    <TemplateBlock>
      <Backgrounds bg1={backgroundImage} loop={true}/>


      <div className={"wc-content"}>
        <div className={"wc-bg-content"}></div>


        <div className={"qatar-logo"}>
          <img src={assets.qatar_logo}/>
        </div>

        <div className={"main-header"}>
          {t("templates.Your")} <br/><span className={"green-glow"}>{t("templates.Insight")}</span><br/> <span
          className={"green-glow"}>{t("templates.Zone")}</span>
        </div>

        {/*<img className={"powered-by"} src={assets.poweredBy}/>*/}


        <div className={"mvp-block"}>
          <div className={"teams-score"}>
            <div className={"stats-type"}>
              {t(statsType, {keyPrefix: 'templates'})}
            </div>
          </div>
          <div className={"main-headline green-glow"}>{t(header ??
            'Statistics', {keyPrefix: 'templates'})}</div>
          <div className={"team-stats"}>
            {_.map(targetStats, (statRow, statKey) => {
              return (
                <div key={statKey} className={"stat-row"}>
                  <div className={"stat-label"}>{t(statKey, {keyPrefix: 'stats'})}</div>
                  <div className={"stat-home"}>{statRow || '-'}</div>
                </div>
              )
            })}
          </div>
        </div>

      </div>

    </TemplateBlock>

  </div>);
};

const getMainBackground = (format, side) => {
  return _.get(assets, `circle_center`);
};

export default Index;

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import langEn from "./language/en/general.json";
import langDe from "./language/de/general.json";
import countriesEn from "./language/en/countries.json";
import countriesDe from "./language/de/countries.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    general: langEn,
    countries: countriesEn,
    translation: {
      //general
    }
  },
  de: {
    general: langDe,
    countries: countriesDe,
    translation: {
      //general
    }
  }
};

i18n
  .use(LanguageDetector) // LanguageDetector
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    defaultNS: 'general',
    resources,
    debug: false,
    fallbackLng: 'en',
    supportedLngs: ['en', 'de'],
    keySeparator: '.',
    // keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;

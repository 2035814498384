import React from 'react';
import _get from 'lodash/get';
import _filter from "lodash/filter";
import _times from "lodash/times";
import getContentById from "../../../shared/utils/getContentById";
import _ from "lodash";
import {extractStatValue} from "../../../shared/utils/functions";

const Stats = props => {
  const {fields, primaryColor, backgroundColor} = props;


  const buildStats = (fields) => {
    const tempArr = [];

    tempArr.push({
      id: "stat1Label",
      title: getContentById('stat1Label', fields),
      value: {
        home: getContentById('stat1Home', fields),
        away: getContentById('stat1Away', fields)
      }
    });

    tempArr.push({
      id: "stat2Label",
      title: getContentById('stat2Label', fields),
      value: {
        home: getContentById('stat2Home', fields),
        away: getContentById('stat2Away', fields)
      }
    });

    tempArr.push({
      id: "stat3Label",
      title: getContentById('stat3Label', fields),
      value: {
        home: getContentById('stat3Home', fields),
        away: getContentById('stat3Away', fields)
      }
    });

    tempArr.push({
      id: "stat4Label",
      title: getContentById('stat4Label', fields),
      value: {
        home: getContentById('stat4Home', fields),
        away: getContentById('stat4Away', fields)
      }
    });

    tempArr.push({
      id: "stat5Label",
      title: getContentById('stat5Label', fields),
      value: {
        home: getContentById('stat5Home', fields),
        away: getContentById('stat5Away', fields)
      }
    });

    return tempArr;
  };

  const stats = _filter(buildStats(fields), function (o) {
    return o.id !== null;
  });

  return (
    <div className={'stats-block'}>
      {stats.map((stat, index) => renderStat(stat, index, primaryColor))}
    </div>
  );
};

const renderStat = (stat, index, primaryColor) => {
  //retrieve unit and value
  stat = extractStatValue(stat);

  if (!stat.id) {
    return null;
  }

  //retrieve unit and value
  const percentTitlePart = "(%)";
  if (stat.title.indexOf(percentTitlePart) > -1) {
    stat.unit = "%";
    // stat.title = stat.title.replace(percentTitlePart,'');
  }

  return (
    <div key={'stat' + index} className="StatRow">
      <div className="stat-name">
        <div className="stat-name-content">{stat.title}</div>
        <div className="stat-home-value"><span className={'onlyvalue'}
                                               data-value={stat.value['home']}>{stat.value['home']}</span><span
          className={'onlyunit'}>{stat.unit}</span></div>
        <div className="stat-away-value"><span className={'onlyvalue'}
                                               data-value={stat.value['away']}>{stat.value['away']}</span><span
          className={'onlyunit'}>{stat.unit}</span></div>
      </div>
      <div className="stat-home-percent"
           style={{color: primaryColor}}>{calcValue(stat.value['home'], stat.value['away']) + '%'}</div>
      <div className="stat-lines">
        <div className="stat-line-block">
          <div className="stat-line-fill-overflow"
               data-width={calcValue(stat.value['home'], stat.value['away'])}
               style={{width: calcValue(stat.value['home'], stat.value['away']) + '%'}}>
            <div className="stat-line-fill">
              {_times(10, (linePartIndex) => (
                  <div key={index + '_' + linePartIndex}
                       className={"line-part line-part-" + linePartIndex}
                       style={{backgroundColor: primaryColor}}></div>
                )
              )}
            </div>
          </div>
          <div className="stat-line-empty-overflow">
            <div className="stat-line-empty">
              {_times(10, (linePartIndex) => (
                  <div key={index + '_' + linePartIndex}
                       className={"line-part line-part-" + linePartIndex}
                       style={{backgroundColor: primaryColor}}></div>
                )
              )}
            </div>
          </div>
        </div>
        <div className="stat-line-block stat-line-block-away">
          <div className="stat-line-fill-overflow"
               data-width={calcValue(stat.value['away'], stat.value['home'])}
               style={{width: calcValue(stat.value['away'], stat.value['home']) + '%'}}>
            <div className="stat-line-fill">
              {_times(10, (linePartIndex) => (
                  <div key={index + '_' + linePartIndex}
                       className={"line-part line-part-" + linePartIndex}
                       style={{backgroundColor: primaryColor}}></div>
                )
              )}
            </div>
          </div>
          <div className="stat-line-empty-overflow">
            <div className="stat-line-empty">
              {_times(10, (linePartIndex) => (
                  <div key={index + '_' + linePartIndex}
                       className={"line-part line-part-" + linePartIndex}
                       style={{backgroundColor: primaryColor}}></div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="stat-away-percent"
           style={{color: primaryColor}}>{calcValue(stat.value['away'], stat.value['home']) + '%'}</div>
    </div>
  );
};

const calcValue = (HomeValue, AwayValue) => {
  var result = 0,
    v1 = parseFloat(HomeValue),
    v2 = parseFloat(AwayValue),
    max = v1 + v2;

  if (v1 === 0 && v2 === 0) {
    return 50;
  }

  result = (v1 / max) * 100;
  return Math.round(result);
};

export default Stats;

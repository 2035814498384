import React, {useState, useEffect, useCallback} from "react";
import _ from "lodash";

import {CheckOutlined, CloseOutlined, PlusCircleOutlined, VideoCameraOutlined, SearchOutlined} from "@ant-design/icons";
import CreateTemplateDrawer from "../Dashboard/CreateTemplateDrawer";
import {useTranslation} from "react-i18next";

import Config from "../../../../config/frontend"
import backendConfig from "../../../../config/backend"
import parseMediaUrl from "../../../shared/functions/parseMediaUrl";

import {v4 as uuidv4} from 'uuid';

import {
  Button, Table, Tag, Popover, Input, Space
} from 'antd';
import {useGlobalStore} from "../../../../stores/globalBackendStore";
import {
  getAuthorizedCompetitions, getCompetitionTeams,
} from "../../../../services/backend/api.service";
import {useHistory} from "react-router-dom";
import {format as dateFormat, parse, parseISO} from "date-fns";
import {de} from "date-fns/locale";

const getTableData = (apiData, getColumnSearchProps, loadContent, dynamicPopovers, setDynamicPopovers) => {  // State to hold the dynamic content
  if (!apiData?.data?.competition.length) {
    return {
      data: [], columns: []
    };
  }

  let index = 0;
  const data = apiData?.data?.competition.map((item, key) => {
    let fromTime = null;
    let toTime = null;

    if (item.tournamentCalendar?.[0]?.startDate) {
      fromTime = new Date(item.tournamentCalendar?.[0].startDate)
    }
    if (item.tournamentCalendar?.[0]?.endDate) {
      toTime = new Date(item.tournamentCalendar?.[0].endDate)
    }


    index++;

    return {
      index: index,
      name: item.name,
      competitionFormat: item.competitionFormat,
      type: item.competitionType,
      gender: item.type,
      country: item.country,
      countryCode: item.countryCode,
      // id: item.id,
      // isFriendly: item.isFriendly,
      links: {
        competitionId: item.id,
        tournamentCalendarId: item.tournamentCalendar?.[0]?.id
      },
      dateRange: `${dateFormat(fromTime, "dd.MM.yyyy", {locale: de})} ${dateFormat(toTime, "dd.MM.yyyy", {locale: de})}`,
    };
  });


  const columns = [{
    title: 'I',
    dataIndex: 'index',
    key: 'index',
    render: (text) => text,
    width: 70,
    align: 'center',

  }, {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => text,
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ['ascend', 'descend'],
    width: 250,
    filterSearch: true,
    ...getColumnSearchProps('name'),
  }, {
    title: 'Format',
    dataIndex: 'competitionFormat',
    key: 'competitionFormat',
    render: (text) => text,
    sorter: (a, b) => a.format.localeCompare(b.format),
    sortDirections: ['ascend', 'descend'],
  }, {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    size: 50,
    width: 120,
    sortDirections: ['ascend', 'descend'],
  }, {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
    render: (text) => text,
    sorter: (a, b) => a.type.localeCompare(b.type),
    sortDirections: ['ascend', 'descend'],
  }, {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    // sorter: (a, b) => (new Date(b.created) - new Date(a.created))
  },
    {
      title: 'ISO',
      dataIndex: 'countryCode',
      key: 'countryCode',
      width: 70,
      // sorter: (a, b) => (new Date(b.created) - new Date(a.created))
    },
    {
      title: 'Date range',
      dataIndex: 'dateRange',
      key: 'dateRange',
      // sorter: (a, b) => (new Date(b.created) - new Date(a.created))
    },
    {
      title: 'Links',
      dataIndex: 'links',
      key: 'links',
      sorter: false,
      align: 'center',
      render: (_, {links}) => {
        const popoverData = dynamicPopovers.get(links.competitionId) || {};
        return (
          <Popover
            content={popoverData.loading ? 'Loading...' : popoverData.content}
            title="Links"
            trigger="click"
            visible={popoverData.visible}
            onVisibleChange={(visible) => {
              if (visible) {
                if (!popoverData.content && !popoverData.loading) {
                  loadContent(links);
                } else {
                  // If content is already loaded, just show the Popover
                  setDynamicPopovers(prev => new Map(prev).set(links.competitionId, {...popoverData, visible: true}));
                }
              } else {
                // Hide the Popover when clicked again
                setDynamicPopovers(prev => new Map(prev).set(links.competitionId, {...popoverData, visible: false}));
              }
            }}
            overlayInnerStyle={{
              width: '600px',
              overflow: 'scroll',
              placement: 'left',
              padding: '5px',
              maxHeight: '300px',
              overflow: 'auto',
            }}
          >
            <Button type="primary">Links</Button>
          </Popover>
        );
      },
    }];

  return {columns, data}
}

const Page = (props) => {

  const {t} = useTranslation();
  const {title, type} = props;
  const apiSetup = useGlobalStore(state => state.apiSetup);
  const selectedMatchday = useGlobalStore(state => state.selectedMatchday);
  const [data, setData] = useState();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  // State to store content and visibility for each Popover
  const [dynamicPopovers, setDynamicPopovers] = useState(new Map());

  const loadContent = useCallback(async (links) => {
    // Check if data is already loaded to prevent unnecessary loading
    const existingData = dynamicPopovers.get(links.competitionId);
    if (existingData && (existingData.content || existingData.loading)) {
      return;
    }

    // Start loading and keep the Popover open
    setDynamicPopovers(prev => new Map(prev).set(links.competitionId, {visible: true, loading: true, content: null}));

    try {
      const response = await getCompetitionTeams(links.tournamentCalendarId, []).catch((err) => {
        console.error('err', err);
      });

      const teamData = response?.data?.data;

      setDynamicPopovers(prev => new Map(prev).set(links.competitionId, {
        visible: true,
        loading: false,
        content: <>
          {
            teamData.map((i) => {
              return <div><a target="_blank"
                             href={`${backendConfig.frontendUrl}/timeline/dynamic/soccer/${links.tournamentCalendarId}/${i.teamId}?format=1920x1080`}>{i.shortcut}</a>
              </div>
            })
          }
        </>
      }));
    } catch (error) {
      console.error('Error fetching dynamic content', error);
      setDynamicPopovers(prev => new Map(prev).set(links.competitionId, {
        visible: true,
        loading: false,
        content: 'Failed to load content'
      }));
    }
  }, [dynamicPopovers, setDynamicPopovers]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined/>}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined, fontSize: '18px'}}/>,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });

  useEffect(() => {
    require("./style/main.scss");
  }, []);

  useEffect(() => {
    (async () => {
      const data = await getAuthorizedCompetitions({}, []).catch((err) => {
        console.error('err', err);
      });
      setData(data?.data);
    })();
  }, []);

  const tableData = getTableData(data, getColumnSearchProps, loadContent, dynamicPopovers, setDynamicPopovers);

  const onChange = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  return (<div className="page-container videos-page">
    <header className="jumbotron main-page-header">
      <h2>{t("Competitions")} {t(title)}</h2>
    </header>

    <div className={"videos-page-content"}>
      {/*<div>*/}
      {/*  <h4>Legend</h4>*/}
      {/*  <div className={"competitions-legends-dynamic"}>*/}
      {/*    Current: | Past:*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Table columns={tableData.columns} dataSource={tableData.data} sticky={true} pagination={false}
             rowKey={(r) => r?.name + uuidv4()}
             onChange={onChange} pagination={'bottomRight'}/>
    </div>
  </div>);
};

export default Page;

// AudioManager.js
import {Howl} from 'howler';

class AudioManager {
  constructor() {
    this.tracks = {}; // Store audio tracks
    this.trackOrder = []; // Store the order of loaded tracks
    this.currentTrackIndex = -1; // Current track index, -1 indicates no track is currently selected
    this.currentTrack = null;
  }

  // Load a new track
  loadTrack(name, path, loop = false) { // Loop should be false by default
    this.tracks[name] = new Howl({
      src: [path],
      loop: loop,
      onend: () => {
        console.log(`Finished playing ${name}`);
        this.playNextTrack(); // Play next track when the current one ends
      },
    });

    this.trackOrder.push(name); // Add the track name to the track order array
  }

  playFirstTrack(volume = 1) {
    if (this.trackOrder.length === 0) {
      console.log("No tracks loaded");
      return;
    }

    // Set the current track index to 0 and play the first track
    this.currentTrackIndex = 0;
    this.playTrack(this.trackOrder[0], volume);
  }

  // Play a specific track
  playTrack(name, volume = 1) {
    if (this.currentTrack && this.tracks[this.currentTrack].playing()) {
      this.tracks[this.currentTrack].pause();
    }

    if (this.tracks[name]) {
      this.currentTrack = name;
      this.currentTrackIndex = this.trackOrder.indexOf(name); // Update the current track index

      this.tracks[name].volume(volume);
      this.tracks[name].play();
    } else {
      console.log(`Track ${name} not found`);
    }
  }

  // Play the next track in the order
  playNextTrack(volume = 1) {
    if (this.trackOrder.length === 0) {
      console.log("No tracks loaded");
      return;
    }

    // Increment and loop the index
    this.currentTrackIndex = (this.currentTrackIndex + 1) % this.trackOrder.length;

    // Play the track at the current index
    this.playTrack(this.trackOrder[this.currentTrackIndex], volume);
  }

  playRandom(volume = 1) {
    const trackNames = Object.keys(this.tracks);
    if (trackNames.length === 0) {
      console.log("No tracks loaded");
      return;
    }

    // Select a random track
    const randomIndex = Math.floor(Math.random() * trackNames.length);
    const randomTrackName = trackNames[randomIndex];

    // Play the selected track
    this.playTrack(randomTrackName, volume);
  }

  // Resume the previously paused track
  resumeTrack() {
    if (this.currentTrack && this.tracks[this.currentTrack]) {
      this.tracks[this.currentTrack].play();
    }
  }

  // Pause the current track
  pauseTrack() {
    if (this.currentTrack && this.tracks[this.currentTrack].playing()) {
      this.tracks[this.currentTrack].pause();
    }
  }

  // Stop a specific track
  stopTrack(name) {
    if (this.tracks[name]) {
      this.tracks[name].stop();
    }
  }

  // Smoothly fade out and pause a specific track
  fadeOutAndPause(duration = 500) {
    if (this.currentTrack) {
      const track = this.tracks[this.currentTrack];
      if (track && track.playing()) {
        track.fade(track.volume(), 0, duration); // Fade to volume 0
        setTimeout(() => track.pause(), duration); // Pause after fade out
      }
    }
  }

  // Smoothly fade in and play the currently selected track
  fadeInAndPlay(duration = 500) {
    if (this.currentTrack) {
      const track = this.tracks[this.currentTrack];
      if (track && !track.playing()) {
        track.volume(0); // Set volume to 0 before playing
        track.play();
        track.fade(0, 1, duration); // Fade to volume 1
      }
    }
  }
}

export const audioManager = new AudioManager();

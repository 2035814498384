import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();

  anime({
    targets: [`#${id} .powered-by`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 400
  });

  anime({
    targets: [`#${id} .slide1 .teaser-text.text-1`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 600
  });

  anime({
    targets: [`#${id} .slide1 .teaser-text.text-2`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 700
  });

  anime({
    targets: [`#${id} .slide1 .winner-circle`],
    duration: 1000,
    easing: "easeOutQuint",
    translateX: [2000, 0],
    opacity: [1, 1],
    delay: 800
  });

  const timeout1 = setTimeout(() => {
    anime({
      targets: [`#${id} .slide1`],
      duration: 1000,
      easing: "easeOutQuint",
      opacity: [1, 0],
    });

    anime({
      targets: [`#${id} .slide2 .teaser-text.text-1`],
      duration: 800,
      easing: "easeOutQuint",
      translateY: [2000, 0],
      opacity: [1, 1],
      delay: 600
    });

    anime({
      targets: [`#${id} .slide2 .teaser-text.text-2`],
      duration: 800,
      easing: "easeOutQuint",
      translateY: [2000, 0],
      opacity: [1, 1],
      delay: 700
    });

    anime({
      targets: [`#${id} .slide2 .devices-magenta`],
      duration: 1000,
      easing: "easeOutQuint",
      translateY: [-2000, 0],
      opacity: [1, 1],
      delay: 400
    });
  }, 4500)

  const timeout2 = setTimeout(() => {
    anime({
      targets: [`#${id} .slide2`],
      duration: 1000,
      easing: "easeOutQuint",
      opacity: [1, 0],
    });


    anime({
      targets: [`#${id} .slide3 .s21-mockup`],
      duration: 1000,
      easing: "easeOutQuint",
      opacity: [1, 1],
      translateY: [2000, 0],
      delay: 400
    });


    anime({
      targets: [`#${id} .slide3 .qr-code`],
      duration: 1000,
      easing: "easeOutQuint",
      scale: [0, 1],
      opacity: [1, 1],
      delay: 800
    });

    anime({
      targets: [`#${id} .slide3 .teaser-text.text-1`],
      duration: 800,
      easing: "easeOutQuint",
      translateX: [-2000, 0],
      opacity: [1, 1],
      delay: 600
    });
  }, 10000)

  timeouts.push(timeout1);
  timeouts.push(timeout2);

  return {
    timeline: [],
    timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

import React, {useEffect} from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/lastgames";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import countries from "../../../../../language/de/countries.json";
import {format as formatDate, parse} from "date-fns";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";
import Image from "../../../../shared/components/Image"
import {toIsoDateString} from "../../../../shared/utils/functions";
import {useTranslation} from "react-i18next";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const {t} = useTranslation();


  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const {options, fields, dynamic} = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);
  const selectedSide = getContentById("selectedSide", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  // const nextGame = _.get(dynamic, "nextGame");
  const matchInfo = _.get(dynamic, "matchInfo");
  const matchday = _.get(dynamic, "matchday");
  const lastGames = _.get(dynamic, "lastGames");
  // const teamDataStyles = _.get(teamData, "styles", {});

  const thisGameDate = new Date(toIsoDateString(matchInfo?.date, matchInfo?.time));

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const teamHomeAlpha2 = getOptaCountryCode(homeTeam?.name, homeTeam?.shortName, homeTeam?.code);
  const teamAwayAlpha2 = getOptaCountryCode(awayTeam?.name, awayTeam?.shortName, awayTeam?.code);

  const scores = _.get(matchday, "liveData.matchDetails.scores.total");

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${
        animation ? "animation" : ""
      } side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true}/>

        <div className={"wc-content"}>
          <div className={"wc-bg-content"}></div>

          <div className={"qatar-logo"}>
            <img src={assets.qatar_logo}/>
          </div>

          <div className={"teams-block " + selectedSide}>
            {selectedSide === "home" ? (
              <div className={"team-logo-container home"}>
                <Image className="team-logo" src={`/images/dynamic/soccer/team/${homeTeam?.id}.png`}/>
                <div className={"team-name home"}>
                  {t(homeTeam?.shortName || homeTeam?.name, {ns: 'countries'})}
                </div>
              </div>
            ) : (
              <div className={"team-logo-container away"}>
                <Image className="team-logo" src={`/images/dynamic/soccer/team/${awayTeam?.id}.png`}/>

                <div className={"team-name away"}>
                  {t(awayTeam?.shortName || awayTeam?.name, {ns: 'countries'})}
                </div>
              </div>
            )}
          </div>

          <div className={"team-stats"}>
            <div className={"score green-glow"}>{t("Last 5 Games", {keyPrefix: 'templates'})}</div>

            {/*{statsMappingHome.map((statRowHome, index) => {*/}
            {/*  const statRowAway = statsMappingAway?.[index];*/}
            {/*  return (*/}
            {/*    <div key={statRowHome.type} className={"stat-row"}>*/}
            {/*      <div className={"stat-home"}>{statRowHome?.value || '-'}</div>*/}
            {/*      <div className={"stat-label"}>{statRowHome?.title}</div>*/}
            {/*      <div className={"stat-away"}>{statRowAway?.value || '-'}</div>*/}
            {/*    </div>*/}
            {/*  )*/}
            {/*})}*/}

            <div className={"group-rows"}>
              {lastGames.map((game, index) => {
                const parsedDate = formatDate(
                  parse(game?.date.replace("Z", ""), "MM/dd/yyyy", new Date()),
                  "dd.MM.yy"
                );

                return (
                  <div key={parsedDate + index} className={"group-row"}>
                    <div className={"match-label"}>
                      <div className={"match-label-competition"}>
                        {game?.competition}
                      </div>
                      <div className={"match-label-date"}>{parsedDate}</div>
                    </div>
                    <div className={"match-row"}>
                      <div className={"team-name home"}>
                        {t(game?.homeName, {ns: 'countries'})}
                      </div>
                      <div className={"team-home"}>
                        <Image className="team-logo" src={`/images/dynamic/soccer/team/${game?.homeId}.png`}/>
                      </div>
                      <div className={"row-score"}>{`${
                        game?.scoreHome || 0
                      } : ${game?.scoreAway || 0}`}</div>
                      <div className={"team-away"}>
                        <Image className="team-logo" src={`/images/dynamic/soccer/team/${game?.awayId}.png`}/>
                      </div>
                      <div className={"team-name away"}>
                        {t(game?.awayName, {ns: 'countries'})}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

      </TemplateBlock>
    </div>
  );
};
const getMainBackground = (format, side) => {
  return _.get(assets, `circle_center`);
};

export default Index;

import {clearTimeouts, engine as anime} from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const {id, format} = props;

  const timeline = anime.timeline();
  $(`#${id} .headline`).css("opacity", 0);
  $(`#${id} .player-image, #${id} .player-info`).css("opacity", 0);

  const params = {};
  params.headlineTop = 370
  params.headlineScale = 1.6

  if(format === '1200x1500') {
    params.headlineTop = 550
    params.headlineScale = 1.4
  }

  anime.timeline({
    targets: `#${id} .headline`,
    easing: 'easeInOutQuint',
    delay: 100,
  })
    .add({
      scale: [0, params.headlineScale],
      duration: 900,
      opacity: [1, 1],
    })
    .add({
      scale: [params.headlineScale, 1.0],
      top: [params.headlineTop, $(`#${id} .headline`).css('top')],
      duration: 600,
      delay: 100,
      opacity: [1, 1],
    });

  anime({
    targets: $(`#${id} .player-image`).toArray(),
    easing: 'easeOutQuint',
    duration: 700,
    // rotateZ: [22, 22],
    opacity: [1, 1],
    translateX: [1500, 0],
    delay: anime.stagger(200, {start: 1100})
  });

  anime({
    targets: [`#${id} .player-info`],
    easing: 'easeOutQuint',
    duration: 700,
    // rotateZ: [22, 22],S
    opacity: [1, 1],
    scale: [0, 1],
    delay: anime.stagger(200, {start: 1300})
  });

  const timeout1 = setTimeout(() => {
    anime({
      targets: $(`#${id} .player-0 .player-image`).get(),
      easing: 'linear',
      duration: 15000,
      translateX: 0,
      scale: [1, 1.15],
    });
  }, 1800);

  timeouts.push(timeout1);

  return {
    timeline: timeline,
    timeouts: timeouts
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const {timeouts} = animationReturn;
  clearTimeouts(timeouts);
};

export default main;

import {create} from 'zustand'

export const useGlobalFrontendStore = create((set, get) => ({
  lang: 'en',
  apiSetup: {},
  closestMatch: {},
  reloadTimeline: false,
  setApiSetup: (apiSetup) => set({apiSetup}),
  setClosestMatch: (closestMatch) => set({closestMatch}),
  setReloadTimeline: (reloadTimeline) => set({reloadTimeline}),
}));


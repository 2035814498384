import _ from "lodash";

const assets = {
  circle_center: require("../../../assets/wc/videos/circle_center.webm"),
  winner_circle: require("./images/winner_circle.png"),
  samsung_tippspiel: require("./images/samsung_tippspiel.png"),
  world_bg: require("./images/world_bg_transparent.png"),
  line_bg_layer: require("./images/line_bg_layer.png"),
  devices_magenta: require("./images/devices_magenta.png"),
  qr_code: require("./images/qrcode.png"),
  s21_mockup: require("./images/s21_mockup.png")
};

export default assets;

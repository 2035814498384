import React from "react";
import Loader, {AlternativeLoader} from "../Loader";
import frontendCfg from "../../../../config/frontend"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error('Global error handler', error);


    if (frontendCfg.reloadOnTimelineError) {
      setTimeout(() => {
        window.location.reload();
      }, 5000)
    }

  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div className={"global-error-boundary"}>
        {/*<Loader type={"inline"}/>*/}
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'linear-gradient(90deg, #004d5c 0%, #00112c 100%)'
        }}>
          <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
            <AlternativeLoader/>
          </div>
        </div>
      </div>
        ;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

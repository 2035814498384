import React, {Component, useEffect, useState} from 'react';
import {Tabs, Divider, Button} from 'antd';
import {AudioOutlined, VideoCameraOutlined, FileImageOutlined, FileAddOutlined} from '@ant-design/icons';
import ImagesTab from './Images';
import VideosTab from './Videos';
import AudiosTab from './Audios';

import _ from "lodash";

import './styles/main.scss'

import GroupSelector from "./GroupSelector";
import EditMedia from "./EditMedia";
import {useGlobalStore} from "../../../../stores/globalBackendStore";

const {TabPane} = Tabs;

const defaultGroup = "_default";

const TabsMenu = (props) => {
  const {onSelect, initialActiveGroup, item} = props;

  const checkInitialValue = (value) => {

  };

  const [imagesGroup, setImagesGroup] = useState(initialActiveGroup || defaultGroup);
  const [videosGroup, setVideosGroup] = useState(initialActiveGroup || defaultGroup);
  const [audiosGroup, setAudiosGroup] = useState(initialActiveGroup || defaultGroup);

  const [disabledItems, setDisabledItems] = useState({
    audio: false,
    video: false,
    image: false
  });

  const apiSetup = useGlobalStore(state => state.apiSetup);

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [defaultActiveKey, setDefaultActiveKey] = useState(item.allowedMediaTypes ? item.allowedMediaTypes[0] : "image");

  const onImagesGroupChange = (value) => {
    setImagesGroup(value);
  };
  const onVideosGroupChange = (value) => {
    setVideosGroup(value);
  };
  const onAudiosGroupChange = (value) => {
    setAudiosGroup(value);
  };

  const onSelectMedia = (e, val) => {
    onSelect(e, val)
  };

  const isGroupExist = (groups, checkValue) => {
    return _.has(groups, checkValue);
  };

  useEffect(() => {
    if (item.allowedMediaTypes && item.allowedMediaTypes.length) {
      const newItems = {...disabledItems};
      _.each(disabledItems, (element, elementKey) => {
        if (item.allowedMediaTypes.indexOf(elementKey) > -1) {
          newItems[elementKey] = false;
        } else {
          newItems[elementKey] = true;
        }
      });


      const firstActive = _.find(newItems, "false");

      if (firstActive) {
        setDefaultActiveKey(firstActive);
      }

      setDisabledItems({
        ...newItems
      })
    }

  }, [item.allowedMediaTypes]);

  return <React.Fragment>
    <Tabs defaultActiveKey={defaultActiveKey}>
      <TabPane
        disabled={disabledItems.image}
        tab={<span>
                <FileImageOutlined/>
                Image
        </span>
        }
        key="image"
      >
        <GroupSelector onChange={onImagesGroupChange} initialActiveGroup={initialActiveGroup}
                       groups={_.get(apiSetup, "mediaLibrary.groups.image")}
                       size={2}
                       addNewElement={() => {
                         return <Button type="primary" icon={<FileAddOutlined/>} onClick={(e) => {
                           setEditData({
                             uuid: "0",
                             group: imagesGroup,
                             content_type: "image"
                           });
                           setVisibleEdit(true);
                         }}>
                           {"Add file"}
                         </Button>
                       }}
        />
        <ImagesTab
          // group={isInitialExist(_.get(apiSetup, "mediaLibrary.groups.image")) ? imagesGroup : defaultGroup}
          group={isGroupExist(_.get(apiSetup, "mediaLibrary.groups.image"), imagesGroup) ? imagesGroup : defaultGroup}
          onSelect={onSelectMedia}
          initialActiveGroup={initialActiveGroup}
          visibleEdit={visibleEdit}
          setVisibleEdit={setVisibleEdit}
          editData={editData}
          setEditData={setEditData}
        />
      </TabPane>
      <TabPane
        disabled={disabledItems.video}
        tab={
          <span>
          <VideoCameraOutlined/>
            Video
        </span>
        }
        key="video"
      >
        <GroupSelector
          onChange={onVideosGroupChange}
          initialActiveGroup={initialActiveGroup}
          groups={_.get(apiSetup, "mediaLibrary.groups.video")}
          addNewElement={() => {
            return <Button type="primary" icon={<FileAddOutlined/>} onClick={(e) => {
              setEditData({
                uuid: "0",
                group: videosGroup,
                content_type: "video"
              });
              setVisibleEdit(true);
            }}>
              {"Add file"}
            </Button>
          }}

        />

        <VideosTab
          // group={videosGroup}
          group={isGroupExist(_.get(apiSetup, "mediaLibrary.groups.video"), videosGroup) ? videosGroup : defaultGroup}
          onSelect={onSelectMedia}
          initialActiveGroup={initialActiveGroup}
          visibleEdit={visibleEdit}
          setVisibleEdit={setVisibleEdit}
          editData={editData}
          setEditData={setEditData}
        />
      </TabPane>
      <TabPane
        disabled={disabledItems.audio}
        tab={
          <span>
          <AudioOutlined/>
          Audio
        </span>
        }
        key="audio"
      >
        <GroupSelector
          onChange={onAudiosGroupChange}
          initialActiveGroup={initialActiveGroup}
          groups={_.get(apiSetup, "mediaLibrary.groups.audio")}
          addNewElement={() => {
            return <Button type="primary" icon={<FileAddOutlined/>} onClick={(e) => {
              setEditData({
                uuid: "0",
                group: audiosGroup,
                content_type: "audio"
              });
              setVisibleEdit(true);
            }}>
              {"Add file"}
            </Button>
          }}

        />
        <AudiosTab
          // group={isInitialExist(_.get(apiSetup, "mediaLibrary.groups.audio")) ? audiosGroup : defaultGroup}
          group={isGroupExist(_.get(apiSetup, "mediaLibrary.groups.audio"), audiosGroup) ? audiosGroup : defaultGroup}
          onSelect={onSelectMedia}
          initialActiveGroup={initialActiveGroup}
          visibleEdit={visibleEdit}
          setVisibleEdit={setVisibleEdit}
          editData={editData}
          setEditData={setEditData}
        />
      </TabPane>
    </Tabs>
  </React.Fragment>
};

const Index = (props) => {
  const {src, poster, className, onSelect, initialActiveGroup, item} = props;

  useEffect(() => {

  });

  return <>
    <TabsMenu initialActiveGroup={initialActiveGroup} onSelect={onSelect} item={item}/>
  </>;
};

export default Index;

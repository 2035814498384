import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import getContentById from "../../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";

import {engine, fadeIn, slide} from "../../../../../animations/index"
import animationScript, {animationCleanUp} from "../../../../../animations/adler/topscorer"
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

import assets from './assets';
import {Divider} from "antd";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";

require('./style/style.scss');
require('./style/animation.scss');

const renderStandingTable = (standingData, apiAssets) => {
  const rows = [];

  rows.push(<div key={"header-row-key"} className={"row header-row"}>
    <div className={"cell"}>
      Pos.
    </div>
    <div className={"cell"}>
      {/*Logo*/}
    </div>
    <div className={"cell"}>
      Team
    </div>
    <div className={"cell"}>
      Spiele
    </div>
    <div className={"cell"}>
      Tore
    </div>
    <div className={"cell"}>
      Tordiff.
    </div>
    <div className={"cell"}>
      Punkte
    </div>
    <div className={"cell"}>
      PKT/SPIEL
    </div>
  </div>);

  _.each(standingData, (entry, entryIndex) => {
    // let pageClass = entryIndex < 7 ? "page-1" : "page-2";
    // const logo = _get(json.Images, 'del-standing-id-' + entry.id, null);
    // console.log("apiAssets", apiAssets);

    const teamApiData = _.get(apiAssets, `t_${entry.id}.data`, null);
    const logo = _.get(apiAssets, `t_${entry.id}.url`, null);
    // const showExtraLine = entryIndex === 5 || entryIndex === 9 ? true : false;

    const exposeSelectedClass = _.get(entry, 'id') == 2 ? "expose-selected-row" : "";

    rows.push(<div key={"standing_" + entryIndex} className={`row ${exposeSelectedClass}`}>
      <div className={"cell"}>
        {_.get(entry, 'position')}
      </div>
      <div className={"cell"}>
        {logo && <img className={"table-team-logo team-logo-id-" + _.get(entry, 'id')} src={parseMediaUrl(logo)}/>}
      </div>
      <div className={"cell"}>
        {/*{showExtraLine && <div className={"extra-line"}></div>}*/}
        {/*{_.get(entry, 'name')}*/}
        {_.get(teamApiData, 'name')}
      </div>
      <div className={"cell"}>
        {_.get(entry, 'games')}
      </div>
      <div className={"cell"}>
        {_.get(entry, 'score1') + "-" + _.get(entry, 'score2')}
      </div>
      <div className={"cell"}>
        {parseInt(_.get(entry, 'score1')) - parseInt(_.get(entry, 'score2'))}
      </div>
      <div className={"cell"}>
        {_.get(entry, 'points')}
      </div>
      <div className={"cell"}>
        {_.get(entry, 'ppg')}
      </div>
    </div>);
  });


  return (<div className="table">
    {rows}
  </div>);
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef()
  };


  const {animation, data, images: Images, apiSetup, mode, format} = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({refs, data, animation, mode, id, format})
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`).get(0).addEventListener("loadedmetadata", function () {
        this.currentTime = 3;
        this.pause();
      }, false);
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);

  const templateData = _.get(data, 'data');
  const currrentEvent = _.get(data, 'event');

  const {options, fields, dynamic} = templateData;
  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);

  const headline = getContentById('headline', fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const goalsHome = _.get(dynamic, "goals.home");
  const goalsAway = _.get(dynamic, "goals.visitor");
  const eventData = _.get(dynamic, "game");
  const topScorersHome = _.get(dynamic, "topScorersHome");
  const topScorersAway = _.get(dynamic, "topScorersAway");
  const apiAssets = _.get(dynamic, "assets");

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));
  const customBackground = getContentById('background', fields);
  const teamSide = _.get(dynamic, "teamSide");
  // const backgroundMask = getContentById('defaultBackgroundMask', options);

  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;
  const selectedTeamId = _.get(currrentEvent, "selectedTeamId");


  const topScorerAway1 = getContentById('topScorer1', fields, "self");
  const topScorerAway2 = getContentById('topScorer2', fields, "self");
  const topScorerAway3 = getContentById('topScorer3', fields, "self");

  const topScorer1 = {
    firstname: _.get(topScorerAway1, "value.data.firstname"),
    lastname: _.get(topScorerAway1, "value.data.lastname"),
    image: parseMediaUrl(topScorerAway1.value.url),
    vorlagen: getContentById('topScorer1Vorlagen', fields),
    tore: getContentById('topScorer1Tore', fields),
    punkte: getContentById('topScorer1Punkte', fields)
  };

  const topScorer2 = {
    firstname: _.get(topScorerAway2, "value.data.firstname"),
    lastname: _.get(topScorerAway2, "value.data.lastname"),
    image: parseMediaUrl(topScorerAway2.value.url),
    vorlagen: getContentById('topScorer2Vorlagen', fields),
    tore: getContentById('topScorer2Tore', fields),
    punkte: getContentById('topScorer2Punkte', fields)
  };

  const topScorer3 = {
    firstname: _.get(topScorerAway3, "value.data.firstname"),
    lastname: _.get(topScorerAway3, "value.data.lastname"),
    image: parseMediaUrl(topScorerAway3?.value?.url),
    vorlagen: getContentById('topScorer3Vorlagen', fields),
    tore: getContentById('topScorer3Tore', fields),
    punkte: getContentById('topScorer3Punkte', fields)
  };

  const awayScorers = [];

  awayScorers.push(topScorer1);
  awayScorers.push(topScorer2);
  awayScorers.push(topScorer3);

  return (
    <div id={id}
         className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''} side-${teamSide}`}>

      <TemplateBlock>
        <Backgrounds bg1={backgroundImage}/>

        {/*<div className={"team-logo-container home"}>*/}
        {/*  <img src={parseMediaUrl(_.get(teamHome, 'url', null))}/>*/}
        {/*</div>*/}

        {/*<div className={"team-logo-container away"}>*/}
        {/*  <img src={parseMediaUrl(_.get(teamAway, 'url', null))}/>*/}
        {/*</div>*/}


        <div className={"headline"}>
          <div className={"main-line"}>TOPSCORER</div>
          <div className={"ghost-line"}>TOPSCORER</div>
        </div>

        <div className={"top-scorers-home"}>
          {_.map(topScorersHome, (scorer, scorerIndex) => {
            return <div key={`p-${scorer.id}`} className={`scorer scorer-id-${scorer.id}`}>

              <div className={"player-image"}><img src={parseMediaUrl(_.get(apiAssets, `p_${scorer.id}.url`))}/></div>

              <div className={"player-info-block"}>

                <div className={"full-name"}>

                  <div className={"names"}>
                    <div className={"firstname"}>{scorer.firstname}</div>
                    <div className={"lastname"}>{scorer.surname}</div>
                  </div>

                </div>


                <div className={"scorer-stats"}>
                  <div className={"scores"}>
                    <div className={"box-bg"}>
                      <div className="triangle1"></div>
                      <div className="triangle2"></div>
                    </div>
                    <div
                      className={"value"}>{_.get(scorer, `statistics.goals.home`, 0) + _.get(scorer, `statistics.goals.away`, 0)}</div>
                    <div className={"label"}>Tore</div>
                  </div>
                  <div className={"vorlagen"}>
                    <div className={"box-bg"}>
                      <div className="triangle1"></div>
                      <div className="triangle2"></div>
                    </div>
                    <div
                      className={"value"}>{_.get(scorer, `statistics.assists.home`, 0) + _.get(scorer, `statistics.assists.away`, 0)}</div>
                    <div className={"label"}>Vorlagen</div>
                  </div>
                  <div className={"points"}>
                    <div className={"box-bg"}>
                      <div className="triangle1"></div>
                      <div className="triangle2"></div>
                    </div>
                    <div
                      className={"value"}>{_.get(scorer, `statistics.points.home`, 0) + _.get(scorer, `statistics.points.away`, 0)}</div>
                    <div className={"label"}>Punkte</div>
                  </div>
                </div>

              </div>

            </div>
          })}
        </div>

        <div className={"top-scorers-away"}>
          {_.map(awayScorers, (scorer, scorerIndex) => {

            return <div key={`paway-${scorerIndex}`} className={`scorer`}>

              <div className={"player-image"}><img src={scorer.image}/></div>

              <div className={"player-info-block"}>

                <div className={"full-name"}>
                  <div className={"names"}>
                    <div className={"firstname"}>{scorer.firstname}</div>
                    <div className={"lastname"}>{scorer.lastname}</div>
                  </div>
                </div>

                <div className={"scorer-stats"}>
                  <div className={"scores"}>
                    <div className={"box-bg"}>
                      <div className="triangle1"></div>
                      <div className="triangle2"></div>
                    </div>
                    <div className={"value"}>{_.get(scorer, "tore")}</div>
                    <div className={"label"}>Tore</div>
                  </div>
                  <div className={"vorlagen"}>
                    <div className={"box-bg"}>
                      <div className="triangle1"></div>
                      <div className="triangle2"></div>
                    </div>
                    <div className={"value"}>{_.get(scorer, "vorlagen")}</div>
                    <div className={"label"}>Vorlagen</div>
                  </div>
                  <div className={"points"}>
                    <div className={"box-bg"}>
                      <div className="triangle1"></div>
                      <div className="triangle2"></div>
                    </div>
                    <div className={"value"}>{_.get(scorer, "punkte")}</div>
                    <div className={"label"}>Punkte</div>
                  </div>
                </div>

              </div>

            </div>
          })}
        </div>

        {/*<img className={"sponsors sponsor-del"} src={assets.penny_del}/>*/}
        <img className={"sponsors sponsor-sap"} src={assets.sap_presents}/>

      </TemplateBlock>

    </div>);
};

const getMainBackground = (format, side) => {
  if (format === "1920x1080") {
    return _.get(assets, `mainbackground_1920`);
  }
};

export default Index;

import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import getContentById from "../../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";

import {engine, fadeIn, slide} from "../../../../../animations/index"
import animationScript, {animationCleanUp} from "../../../../../animations/adler/table"
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

import assets from './assets';
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";

require('./style/style.scss');
require('./style/animation.scss');

const renderStandingTable = (standingData, apiAssets) => {
  const rows = [];

  rows.push(
    <div key={"header-row-double-lines"}>
      <div className={"double-line-top"}></div>
      <div className={"double-line-bottom"}></div>
    </div>
  )

  rows.push(<div key={"header-row-key"} className={"row header-row"}>
    <div className={"cell"}>
      Pos.
    </div>
    <div className={"cell"}>
      {/*Logo*/}
    </div>
    <div className={"cell"}>
      Team
    </div>
    <div className={"cell"}>
      Spiele
    </div>
    <div className={"cell"}>
      Tore
    </div>
    <div className={"cell"}>
      Tordiff.
    </div>
    <div className={"cell"}>
      Punkte
    </div>
    <div className={"cell"}>
      PKT/SPIEL
    </div>
  </div>);

  _.each(standingData, (entry, entryIndex) => {
    // let pageClass = entryIndex < 7 ? "page-1" : "page-2";
    // const logo = _get(json.Images, 'del-standing-id-' + entry.id, null);
    // console.log("apiAssets", apiAssets);

    const teamApiData = _.get(apiAssets, `t_${entry.id}.data`, null);
    const logo = _.get(apiAssets, `t_${entry.id}.url`, null);
    // const showExtraLine = entryIndex === 5 || entryIndex === 9 ? true : false;

    const exposeSelectedClass = _.get(entry, 'id') == 2 ? "expose-selected-row" : "";

    rows.push(<div key={"standing_" + entryIndex} className={`row ${exposeSelectedClass}`}>
      <div className={"cell"}>
        {_.get(entry, 'position')}
      </div>
      <div className={"cell"}>
        {logo && <img className={"table-team-logo team-logo-id-" + _.get(entry, 'id')} src={parseMediaUrl(logo)}/>}
      </div>
      <div className={"cell"}>
        {/*{showExtraLine && <div className={"extra-line"}></div>}*/}
        {/*{_.get(entry, 'name')}*/}
        {_.get(teamApiData, 'name')}
      </div>
      <div className={"cell"}>
        {_.get(entry, 'games')}
      </div>
      <div className={"cell"}>
        {_.get(entry, 'score1') + "-" + _.get(entry, 'score2')}
      </div>
      <div className={"cell tor-diff"}>
        <span>{parseInt(_.get(entry, 'score1')) - parseInt(_.get(entry, 'score2'))}</span>
      </div>
      <div className={"cell"}>
        {_.get(entry, 'points')}
      </div>
      <div className={"cell"}>
        {_.get(entry, 'ppg')}
      </div>
    </div>);
  });


  return (<div className="table">
    {rows}
  </div>);
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef()
  };


  const {animation, data, images: Images, apiSetup, mode, format} = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({id});
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({refs, data, animation, mode, id, format})
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`).get(0).addEventListener("loadedmetadata", function () {
        this.currentTime = 3;
        this.pause();
      }, false);
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn)
      }
    }
  }, [animation, mode]);

  const templateData = _.get(data, 'data');

  const {options, fields, dynamic} = templateData;
  const color1 = getContentById('color1', options);
  const color2 = getContentById('color2', options);

  const headline = getContentById('headline', fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const goalsHome = _.get(dynamic, "goals.home");
  const goalsAway = _.get(dynamic, "goals.visitor");
  const eventData = _.get(dynamic, "game");
  const standingData = _.get(dynamic, "standing");
  const apiAssets = _.get(dynamic, "assets");
  // const teamDataStyles = _.get(teamData, "styles", {});
  const playerData = getContentById('player', fields, "self");

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));
  const customBackground = getContentById('background', fields);
  // const backgroundMask = getContentById('defaultBackgroundMask', options);

  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;

  return (
    <div id={id}
         className={`T_${_.get(data, "template_name", "")} ${animation ? 'animation' : ''}`}>


      <TemplateBlock>
        <Backgrounds bg1={backgroundImage}/>

        <img className={"sponsors sponsor-sap"} src={assets.sap_presents}/>

        <div className={"headline"}>
            <div className={"main-line"}>TABELLE</div>
            <div className={"ghost-line"}>TABELLE</div>
        </div>

        {/*<div className={"team-logo-container home"}>*/}
        {/*  <img src={parseMediaUrl(_.get(teamHome, 'url', null))}/>*/}
        {/*</div>*/}

        <div className={"standing-table"}>
          {/*{this.renderStandingTable(standingData, json, teamData)}*/}
          {renderStandingTable(standingData, apiAssets)}

        </div>

      </TemplateBlock>

    </div>);
};

const getMainBackground = (format, side) => {
  if (format === "1920x1080") {
    return _.get(assets, `mainbackground_1920`);
  }
};

export default Index;

import React, {useEffect, useRef, useState} from 'react';
import {Form, Drawer, Card} from "antd";
import _ from "lodash";
import backendConfig from "../../../../config/backend";

import {useTranslation} from "react-i18next";
import {useGlobalStore} from "../../../../stores/globalBackendStore";

const {Meta} = Card;

export default function (props) {
  const {item, setDrawerVisible, onClick} = props;
  const {t} = useTranslation();

  const apiSetup = useGlobalStore(state => state.apiSetup);

  const onClose = () => {
    setDrawerVisible(false);
  };

  if (_.isEmpty(apiSetup)) {
    return null;
  }

  return (
    <div className={"comp-CreateTemplateDrawer"}>
      <Drawer
        rootClassName={"comp-CreateTemplateDrawer-container"}
        title={t("New template")}
        placement={"right"}
        closable={true}
        onClose={onClose}
        open={props.visible}
        width={400}
      >
        {apiSetup.templates.map((template) => {
          if (!template.active) {
            return;
          }

          const liveCreation = _.get(template, 'liveCreation', false);

          let previewSrc = `/images/preview/${template.name}.jpg`;


          if(backendConfig.clientBodyClass === 'app-opta') {
            previewSrc = `/images/preview/worldcup/preview_soccer.jpg`;
          }

          return (<Card
            onClick={(e) => {
              if (!liveCreation) {
              onClick(e, {
                id: 0,
                template_name: template.name
              });
              onClose();
              }
            }}
            key={template.name}
            hoverable={true}
            cover={<img alt={template.name} src={previewSrc}/>}
            className={liveCreation ? "live-creation" : "manual-creation"}
          >
            {liveCreation && <div className={"only-live-badge"}>Live creation based on event</div>}
            <Meta title={template.label}/>
          </Card>)
        })}

      </Drawer>
    </div>
  );
}
